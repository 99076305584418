import Service, { service } from '@ember/service';

export default class ExportService extends Service {
  @service store;

  formatDateBeginEnd(date) {
    // current date
    const currDate = new Date();
    const currMonth = `${currDate.getFullYear()}-${('0' + (currDate.getMonth() + 1)).slice(-2)}`;
    const currDay = currDate.getDate();

    //selected date
    const selectedDate = new Date(date);
    const year = selectedDate.getFullYear();
    const month = ('0' + (selectedDate.getMonth() + 1)).slice(-2);
    const selectedMonth = `${year}-${month}`;

    const begin = `${selectedMonth}-01`; // first day of the month
    let end;

    if (selectedMonth == currMonth) {
      end = `${selectedMonth}-${currDay}`; // current day of the month
    } else {
      end = `${selectedMonth}-${new Date(year, month, 0).getDate()}`; // last day of the month
    }

    return {
      begin,
      end,
    };
  }

  isExportNfsePeriodInvalid(begin, end) {
    const maxPeriod = 31;
    const dateBegin = new Date(begin);
    const dateEnd = new Date(end);
    const milliseconds = dateEnd - dateBegin;

    // math operation to transform milliseconds into days (seconds, minutes, hours, days)
    const days = milliseconds / (1000 * 60 * 60 * 24);

    return days > maxPeriod;
  }

  async createNewExportRecord(usedOn, accountId, subscriptionId) {
    const formatedDate = this.formatDateBeginEnd(usedOn);
    let options = {
      resource: 'account-usage',
      beginOn: formatedDate.begin,
      endOn: formatedDate.end,
    };
    const model = await this.store.createRecord('export');
    return await model.save({
      adapterOptions: {
        accountId,
        subscriptionId,
        options,
      },
    });
  }
}
