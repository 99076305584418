import Component from '@glimmer/component';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class CreditCardForm extends Component {
  @service store;
  @service payment;
  @service intl;

  @tracked
  _paymentMethod = null;

  constructor() {
    super(...arguments);

    this._paymentMethod = this.store.createRecord('payment-method', {
      accountId: this.args.accountId,
      type: 'CreditCard',
      card: this.store.createRecord('payment-method/card'),
    });
  }

  get isValid() {
    return (
      this._paymentMethod.card.number &&
      this._paymentMethod.card.expiration &&
      this._paymentMethod.card.securityCode &&
      this._paymentMethod.card.name &&
      this._paymentMethod.card.name.match(/^[a-zA-Z]{1,}\s[a-zA-Z]{1,}.*/)
    );
  }

  get disabled() {
    return !this.isValid || this.addCreditCardToAccount.isRunning;
  }

  @task
  *addCreditCardToAccount(model) {
    const { processPayment, paymentMethodSelected } = this.args;
    if (paymentMethodSelected) paymentMethodSelected(this._paymentMethod);
    return yield processPayment(model).then(() => {
      this._paymentMethod = null;
      this._paymentMethod = this.store.createRecord('payment-method', {
        accountId: this.args.accountId,
        type: 'CreditCard',
        card: this.store.createRecord('payment-method/card'),
      });
    });
  }

  @action
  setCard({ detail }) {
    this.parseCard(detail?.value);
  }

  parseCard(value) {
    if (!value || !value.match(/^(?<number>[0-9 ]{18,19}) D(?<expiration>[0-9\/]{5}) C(?<code>[0-9]{3,4})$/)) {
      return `${this._paymentMethod.card.number} D${this._paymentMethod.card.expiration} C${this._paymentMethod.card.securityCode}`;
    }

    let { number, expiration, code } = value.match(
      /^(?<number>[0-9 ]{18,19}) D(?<expiration>[0-9\/]{5}) C(?<code>[0-9]{3,4})$/
    )?.groups;

    expiration = expiration.replace('/', '/20');

    this._paymentMethod.card.number = number;
    this._paymentMethod.card.expiration = expiration;
    this._paymentMethod.card.securityCode = code;
    return value;
  }
}
