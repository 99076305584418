import Model, { attr } from '@ember-data/model';

export default class DetailsModel extends Model {
  @attr('string') address;
  @attr('string') email;
  @attr('string') name;
  @attr('string') telephone;

  @attr('nested-keys') details;

  serialize() {
    return {
      address: this.address,
      email: this.email,
      name: this.name,
      telephone: this.telephone,
    };
  }
}
