define("ember-svg-jar/inlined/logo-caixa-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#1C6EAA\"/><path fill=\"#FF7373\" d=\"M20 20h40v40H20z\" opacity=\".01\"/><path fill=\"#F79B00\" fill-rule=\"evenodd\" d=\"M46.26 26.667h11.53L45.777 39.288H34.243L46.26 26.667z\" clip-rule=\"evenodd\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M34.271 39.355h11.455l-6.26-12.547H27.93l6.342 12.547zm10.585 1.205l6.342 12.547H39.66L33.401 40.56h11.455z\" clip-rule=\"evenodd\"/><path fill=\"#F79B00\" fill-rule=\"evenodd\" d=\"M32.864 53.18h-11.53l12.013-12.62H44.88L32.864 53.18z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});