define("ember-cli-mdc-form/components/mdc-form", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/runloop", "@ember/template-factory"], function (_exports, _component, _component2, _object, _runloop, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <form class="mdc-form" ...attributes {{did-insert this.didInsert}}>
    {{yield (hash isValid=this.valid isInvalid=this.invalid)}}
  </form>
  
  */
  {
    "id": "r0ztcSeT",
    "block": "[[[11,\"form\"],[24,0,\"mdc-form\"],[17,1],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[12],[1,\"\\n  \"],[18,2,[[28,[37,2],null,[[\"isValid\",\"isInvalid\"],[[30,0,[\"valid\"]],[30,0,[\"invalid\"]]]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"did-insert\",\"yield\",\"hash\"]]",
    "moduleName": "ember-cli-mdc-form/components/mdc-form.hbs",
    "isStrictMode": false
  });
  function noOp() {}
  let MdcFormComponent = _exports.default = (_class = class MdcFormComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _defineProperty(this, "_formElement", null);
      _defineProperty(this, "valid", false);
      _defineProperty(this, "checkValidityEventListener_", null);
      _defineProperty(this, "submitEventListener_", null);
      _defineProperty(this, "resetEventListener_", null);
      this.checkValidityEventListener_ = this.doCheckValidity.bind(this);
      this.submitEventListener_ = this.didSubmit.bind(this);
      this.resetEventListener_ = this.didReset.bind(this);
    }
    get delay() {
      return this.args.delay || 150;
    }
    get invalid() {
      return !this.valid;
    }
    didInsert(element) {
      this._formElement = element;
      element.addEventListener('input', this.checkValidityEventListener_);
      element.addEventListener('submit', this.submitEventListener_);
      element.addEventListener('reset', this.resetEventListener_);
      this.doCheckValidity();
    }
    willDestroy() {
      super.willDestroy();
      this._formElement.removeEventListener('input', this.checkValidityEventListener_);
      this._formElement.removeEventListener('submit', this.submitEventListener_);
      this._formElement.removeEventListener('reset', this.resetEventListener_);
    }

    /**
     * The submit button was pressed. By default, we prevent the default action from
     * happening because form submission in EmberJS happens behind the scenes.
     *
     * @param ev
     */
    didSubmit(ev) {
      // Prevent the default event.
      ev.preventDefault();

      // Manually call submit.
      this.submit(ev);
    }

    /**
     * The reset button was pressed. By default, we prevent the default action from
     * happening because form submission in EmberJS happens behind the scenes.
     *
     * @param ev
     */
    didReset(ev) {
      // Prevent the default event.
      ev.preventDefault();

      // Manually call reset.
      this.reset(ev);
    }
    /**
     * Continuously report the validity.
     */
    doCheckValidity() {
      let delay = this.delay;
      (0, _runloop.debounce)(this, this._doCheckValidity, delay);
    }
    _doCheckValidity() {
      let valid = this._formElement.checkValidity();

      // Update the invalid state of the form. This will also components inside
      // the form to update its state based on the forms validity.
      this.valid = valid;

      // Notify the parent of our state. The client could listen to the change event
      // that bubbles up to the form, but that requires creating an action that can
      // extract the forms validity. This is just a shortcut approach for the client.
      this.validity(valid);
    }
    get validity() {
      return this.args.validity || noOp;
    }
    get submit() {
      return this.args.submit || noOp;
    }
    get reset() {
      return this.args.reset || noOp;
    }
  }, _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MdcFormComponent);
});