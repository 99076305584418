import Component from '@glimmer/component';
import { action } from '@ember/object';
import arg from '../../utils/decorators/arg';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NfeSideSheetsComponent extends Component {
  @service user;
  @arg title;
  @arg style;

  @tracked isFullScreen = false;

  @action
  closeAction() {
    return (this.args.closed || noOp)();
  }

  @action
  toggleFullScreen() {
    this.isFullScreen = !this.isFullScreen;
  }

  @action
  toggleOpen(element) {
    const { open } = this.args;

    if (!open) {
      element.style.transform = `translateX(${this.width})`;
    } else {
      element.style.transform = '';
    }
  }

  get styles() {
    return `width: ${this.width}`;
  }

  get width() {
    return this.args.width || 'var(--width)';
  }
}
