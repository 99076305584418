import { service } from '@ember/service';
import RESTAdapter from '@ember-data/adapter/rest';
import ENV from 'accounts-app/config/environment';

export default class ApikeySecretAdapter extends RESTAdapter {
  @service sessionData;

  host = ENV.APP.IDENTITY_API_URL;
  namespace = 'api/subjects';

  query(store, model, { adapterOptions }) {
    let url = `${this.host}/${this.namespace}/${adapterOptions.subscriptionId}/clients/${adapterOptions.clientId}/secrets`;
    return super.ajax(url, 'GET');
  }
}
