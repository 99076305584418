import { set } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action, computed } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { isEmpty, isPresent } from '@ember/utils';
import { A } from '@ember/array';
import WebhooksEnum from '../../models/enums/webhooks';
import arg from '../../utils/decorators/arg';

export default class WebhooksFormComponent extends Component {
  @service store;
  @service intl;
  @service snackbar;
  @service accessToken;
  @service router;
  @service sessionData;

  @arg model;

  @computed('model')
  @tracked
  currentProviders = A([]);

  @computed('model.filters')
  @tracked
  currentFilters = this.model.filters;

  @computed('model', 'currentProviders', 'currentFilters')
  @tracked
  filteredEvents = A([]);

  @computed('model')
  @tracked
  currentClient = this.model.client;

  @tracked
  validity = false;

  @computed('model', 'validity')
  get isValid() {
    return this.validity;
  }

  @computed('model', 'validity')
  get isInvalid() {
    return !this.validity;
  }

  @computed('model')
  @tracked
  contentTypes = A([
    {
      value: 'json',
      text: 'application/json',
    },
    {
      value: 'form-urlencoded',
      text: 'application/x-www-form-urlencoded',
    },
  ]);

  events = WebhooksEnum.events;
  types = WebhooksEnum.types;

  eventsByType(filter) {
    const eventsFilteredByProvider = this.providerFilter(this.events);

    return Object.groupBy(eventsFilteredByProvider.filter(filter), ({ type }) => type);
  }

  providerFilter(events) {
    if (isEmpty(this.currentProviders)) return events;

    if (!isEmpty(this.filteredEvents)) return this.filteredEvents;

    this.filteredEvents.clear();

    events.forEach((event) => {
      this.currentProviders.forEach((provider) => {
        this.types.forEach((type) => {
          if (provider.namespace === type.provider && event.type == type.id) {
            this.filteredEvents.pushObject(Object.assign(event, { disabled: !provider.isActive }));
          }
        });
      });
    });

    return this.filteredEvents;
  }

  @computed('currentFilters', 'currentProviders', 'filteredEvents', 'model.filters')
  get availableEventsByType() {
    let filters = [];
    if (isPresent(this.model.filters)) {
      filters = this.model.filters;
    }

    return this.eventsByType((event) => !filters.includes(event.id) && !event.disabled);
  }

  @computed('currentFilters', 'currentProviders', 'filteredEvents', 'model.filters')
  get selectedEventsByType() {
    if (!isPresent(this.model.filters)) return [];

    return this.eventsByType((event) => this.model.filters.includes(event.id));
  }

  @action
  setSecureSSL({ target }) {
    const { checked, value } = target;
    this.model.insecureSsl = !checked;
  }

  @action
  setStatus({ target }) {
    const { checked, value } = target;
    this.model.status = checked ? 'Active' : 'Inactive';
  }

  @action
  setFilter({ add, event }) {
    if (!isPresent(this.model.filters)) {
      set(this, 'model.filters', []);
    }

    // Add filter
    if (add) {
      this.model.filters.pushObject(event);
    } else {
      // Remove filter
      this.model.filters.removeObject(event);
    }
    this.currentFilters = this.model.filters;
  }

  @action
  setClient({ detail }) {
    this.currentClient = this.model.client = detail?.value;
  }

  getCurrentClient({ clients, clientId }) {
    if (isEmpty(clients)) return;

    return clients.find((client) => client.clientId === clientId);
  }

  @action
  setContentType({ detail }) {
    this.model.contentType = detail?.value || this.contentTypes[0].value;
  }

  @task({ drop: true })
  *mountFilterByProviders(providers) {
    if (isEmpty(providers)) return;

    yield providers.find((provider) => {
      if (provider.namespace === 'DFeTech.WebHook.Encryption' && provider.isActive && this.contentTypes.length < 3) {
        this.contentTypes.pushObject({
          value: 'json-web-encryption',
          text: 'application/jose',
        });
      }
    });

    this.currentProviders = providers;

    return;
  }

  @action
  checkValidity(value) {
    this.validity = value;
  }

  @task({ drop: true })
  *cancel() {
    const { cancel } = this.args;

    if (isPresent(cancel)) {
      yield cancel.perform(this.model);
    }
  }

  @task({ drop: true })
  *save() {
    const { save } = this.args;

    if (isPresent(save)) {
      yield save.perform(this.model);
    }
  }
}
