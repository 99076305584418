define("ember-cli-mdc-layout-grid/components/mdc-layout-grid", ["exports", "@ember/component", "@glimmer/component", "@ember/utils", "@ember/template-factory"], function (_exports, _component, _component2, _utils, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mdc-layout-grid {{if @alignment this.alignment}} {{if @fixedColumnWidth "mdc-layout-grid--fixed-column-width"}}" ...attributes>
    <MdcLayoutGridInner>
      {{yield}}
    </MdcLayoutGridInner>
  </div>
  
  */
  {
    "id": "UeT30s16",
    "block": "[[[11,0],[16,0,[29,[\"mdc-layout-grid \",[52,[30,1],[30,0,[\"alignment\"]]],\" \",[52,[30,2],\"mdc-layout-grid--fixed-column-width\"]]]],[17,3],[12],[1,\"\\n  \"],[8,[39,1],null,null,[[\"default\"],[[[[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"]],[]]]]],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@alignment\",\"@fixedColumnWidth\",\"&attrs\",\"&default\"],false,[\"if\",\"mdc-layout-grid-inner\",\"yield\"]]",
    "moduleName": "ember-cli-mdc-layout-grid/components/mdc-layout-grid.hbs",
    "isStrictMode": false
  });
  class MdcLayoutGridComponent extends _component2.default {
    get alignment() {
      const {
        alignment
      } = this.args;
      return (0, _utils.isPresent)(alignment) ? `mdc-layout-grid--align-${alignment}` : null;
    }
  }
  _exports.default = MdcLayoutGridComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MdcLayoutGridComponent);
});