import RESTSerializer from '@ember-data/serializer/rest';

export default class WebhookSerializer extends RESTSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload?.webHooks) {
      payload = { Webhooks: payload.webHooks };

      delete payload.webHooks;
    }

    if (payload?.webHook) {
      payload = { Webhook: payload.webHook };

      delete payload.webHook;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
