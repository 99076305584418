import BaseOdataSerializer from '../base-odata';

export default class PaymentMethodCardSerializer extends BaseOdataSerializer {
  serialize() {
    let json = super.serialize(...arguments);

    let expirationData = json.expiration.split('/');

    const response = {
      expirationMonth: expirationData[0].trim(),
      expirationYear: expirationData[1].trim(),
      number: json.number.replace(/\s/g, ''),
      securityCode: json.securityCode,
    };

    return response;
  }
}
