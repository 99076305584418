define("ember-svg-jar/inlined/logo-bradesco-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#D20D2A\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M36.672 25.883c7.288-2.705 14.53 2.31 14.53 2.31s-2.386-3.643-6.924-5.112c-1.816-.763-11.732-1.984-15.738 5.946l-.112.227c-3.512 1.17-5.407 2.6-5.407 2.6s2.133-.579 4.792-1.108c-2.527 7.348 2.219 14.41 4.895 15.164-.736-1.45-1.978-4.856-2.247-6.05-.744-3.297-.501-6.912 1.133-9.744 1.385-.176 2.72-.27 3.814-.21 3.338.19 6.77.466 10.332 1.947 12.243 5.09 2.76 13.665 2.76 13.665s1.205.424 5.332-2.582 3.841-12.165-9.551-14.592c-4.324-.784-8.072-.696-11.164-.217.94-.939 2.117-1.71 3.555-2.244zm-.7 18.99h2.52v12.605h-2.52V44.874zm8.738-5.377h-4.033v17.982h4.033V39.496z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});