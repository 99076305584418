import EmberObject from '@ember/object';

export default class accessKeysInfoEnum extends EmberObject.extend() {
  static accessKeysInfoAccount = [
    {
      text: 'account.accessKeys.types.data',
      id: 'Dados (open.nfe.io)',
    },
    {
      text: 'account.accessKeys.types.issueInvoices',
      id: 'Nota Fiscal (api.nfe.io)',
    },
  ];
}
