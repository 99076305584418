import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ProvidersController extends Controller {
  @service store;
  @service sessionData;
  @service provider;

  @tracked dialogOpen = false;
  @tracked dialogAction = false;
  @tracked selectedProvider = null;

  @action
  confirmProvider({ provider, subscriptionId }) {
    provider.subscriptionId = subscriptionId;
    this.selectedProvider = provider;
    this.dialogAction = provider.status == 'Active' ? 'unregister' : 'register';
    this.dialogOpen = true;
  }

  @task({ drop: true })
  *setStatus(currentProvider) {
    this.closeDialog();
    yield this.provider.setStatus(currentProvider.subscriptionId, currentProvider.id, currentProvider.isActive ? 'unregister' : 'register');
  }

  closeDialog = () => {
    this.dialogOpen = false;
    this.dialogAction = false;
  };

  @action
  refreshPage() {
    window.location.reload(true);
  }
}
