import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class MainPaymentRoute extends Route {
  @service store;

  async model({ paymentId }) {
    return await this.store.findRecord('payment', paymentId);
  }
}
