import Component from '@glimmer/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PaymentMethodCardComponent extends Component {
  menuOptions = A([
    { value: 'Primary', text: 'Principal' },
    { value: 'Backup', text: 'Secundário' },
    { value: 'None', text: 'Outros' },
  ]);

  @tracked
  type = this.args.type;

  @action
  onClickRemove(item) {
    this.args.onClickRemove(item);
  }

  @action
  onClickAdd(item) {
    this.args.onClickAdd(item);
  }

  @action
  changeType(item) {
    this.args.changeType(item);
  }
}
