define("ember-svg-jar/inlined/logo-santander-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#FF0A00\"/><path fill=\"#FF5A5A\" d=\"M20 20h40v40H20z\" opacity=\".01\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M36.535 30.23s-.987.882-1.29 1.56c-.836 1.872-.654 4.212.604 6.07 1.342 1.982 2.472 3.329 3.476 4.77 1.816 2.605 2.538 5.383.387 7.602-.156-2.61-2.375-4.895-2.375-4.895s-4.097-4.778-4.319-6.45c-2.321.355-4.665 1.516-7.34 3.19-1.511 1.123-4.317 3.26-2.317 6.916 2 3.655 9.489 5.888 13.048 5.975 1.368.033 3.467.229 5.82.082C46 54.816 50.421 53.952 53.535 52c5.058-3.173 4.782-7.448.84-10.2-1.65-1.152-3.233-1.82-4.725-2.29-.884-.279-2.665-.623-2.665-.623s.572-2.645-1.306-4.87C43.8 31.788 39.56 26.996 40.579 24c-1.112.272-3.25 3.983-.41 8.395 2.84 4.411 4.133 4.805 4.603 7.104.47 2.3-.225 4.696-1.363 5.07.444-2.31-1.045-3.685-1.662-4.897-.617-1.211-4.472-4.17-4.983-7.277-.166-.907-.229-2.164-.229-2.164z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});