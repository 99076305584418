import RESTSerializer from '@ember-data/serializer/rest';

export default class ApikeyClientSerializer extends RESTSerializer {
  primaryKey = 'clientId';

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload) {
      payload['apikey/client'] = payload;
    }
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
