import EmberObject from '@ember/object';

export default class WebhooksEnum extends EmberObject.extend() {
  static types = [
    {
      id: 'consumer_invoice',
      text: 'webhooks.type.consumer_invoice',
      provider: 'DFeTech.ConsumerInvoice',
    },
    {
      id: 'product_invoice_inbound',
      text: 'webhooks.type.product_invoice_inbound',
      provider: 'DFeTech.ProductInvoice.Inbound',
    },
    {
      id: 'product_invoice',
      text: 'webhooks.type.product_invoice',
      provider: 'DFeTech.ProductInvoice',
    },
    {
      id: 'product_tax',
      text: 'webhooks.type.product_tax',
      provider: 'DFeTech.ProductInvoice',
    },
    {
      id: 'service_invoice',
      text: 'webhooks.type.service_invoice',
      provider: 'DFeTech.ServiceInvoice',
    },
    {
      id: 'tax_payment_form',
      text: 'webhooks.type.tax_payment_form',
      provider: 'DFeTech.ProductInvoice',
    },
    {
      id: 'transportation_invoice_inbound',
      text: 'webhooks.type.transportation_invoice_inbound',
      provider: 'DFeTech.TransportationInvoice.Inbound',
    },
  ];
  static events = [
    {
      type: 'consumer_invoice',
      id: 'consumer_invoice.cancelled_error',
      text: 'webhooks.filters.consumer_invoice-cancelled_error',
    },
    {
      type: 'consumer_invoice',
      id: 'consumer_invoice.cancelled_failed',
      text: 'webhooks.filters.consumer_invoice-cancelled_failed',
    },
    {
      type: 'consumer_invoice',
      id: 'consumer_invoice.cancelled_successfully',
      text: 'webhooks.filters.consumer_invoice-cancelled_successfully',
    },
    {
      type: 'consumer_invoice',
      id: 'consumer_invoice.issued_error',
      text: 'webhooks.filters.consumer_invoice-issued_error',
    },
    {
      type: 'consumer_invoice',
      id: 'consumer_invoice.issued_failed',
      text: 'webhooks.filters.consumer_invoice-issued_failed',
    },
    {
      type: 'consumer_invoice',
      id: 'consumer_invoice.issued_successfully',
      text: 'webhooks.filters.consumer_invoice-issued_successfully',
    },
    {
      type: 'product_invoice_inbound',
      id: 'product_invoice_inbound_summary.event_raised_successfully',
      text: 'webhooks.filters.product_invoice_inbound_summary-event_raised_successfully',
    },
    {
      type: 'product_invoice_inbound',
      id: 'product_invoice_inbound_summary.issued_successfully',
      text: 'webhooks.filters.product_invoice_inbound_summary-issued_successfully',
    },
    {
      type: 'product_invoice_inbound',
      id: 'product_invoice_inbound.event_raised_successfully',
      text: 'webhooks.filters.product_invoice_inbound-event_raised_successfully',
    },
    {
      type: 'product_invoice_inbound',
      id: 'product_invoice_inbound.issued_successfully',
      text: 'webhooks.filters.product_invoice_inbound-issued_successfully',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.cancelled_error',
      text: 'webhooks.filters.product_invoice-cancelled_error',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.cancelled_failed',
      text: 'webhooks.filters.product_invoice-cancelled_failed',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.cancelled_successfully',
      text: 'webhooks.filters.product_invoice-cancelled_successfully',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.cce_error',
      text: 'webhooks.filters.product_invoice-cce_error',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.cce_failed',
      text: 'webhooks.filters.product_invoice-cce_failed',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.cce_successfully',
      text: 'webhooks.filters.product_invoice-cce_successfully',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.issued_error',
      text: 'webhooks.filters.product_invoice-issued_error',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.issued_failed',
      text: 'webhooks.filters.product_invoice-issued_failed',
    },
    {
      type: 'product_invoice',
      id: 'product_invoice.issued_successfully',
      text: 'webhooks.filters.product_invoice-issued_successfully',
    },
    {
      type: 'product_tax',
      id: 'product_tax.created_successfully',
      text: 'webhooks.filters.product_tax-created_successfully',
    },
    {
      type: 'product_tax',
      id: 'product_tax.creation_failed',
      text: 'webhooks.filters.product_tax-creation_failed',
    },
    {
      type: 'product_tax',
      id: 'product_tax.custom_rules_requested',
      text: 'webhooks.filters.product_tax-custom_rules_requested',
    },
    {
      type: 'service_invoice',
      id: 'service_invoice.cancelled_error',
      text: 'webhooks.filters.service_invoice-cancelled_error',
    },
    {
      type: 'service_invoice',
      id: 'service_invoice.cancelled_failed',
      text: 'webhooks.filters.service_invoice-cancelled_failed',
    },
    {
      type: 'service_invoice',
      id: 'service_invoice.cancelled_successfully',
      text: 'webhooks.filters.service_invoice-cancelled_successfully',
    },
    {
      type: 'service_invoice',
      id: 'service_invoice.issued_error',
      text: 'webhooks.filters.service_invoice-issued_error',
    },
    {
      type: 'service_invoice',
      id: 'service_invoice.issued_failed',
      text: 'webhooks.filters.service_invoice-issued_failed',
    },
    {
      type: 'service_invoice',
      id: 'service_invoice.issued_successfully',
      text: 'webhooks.filters.service_invoice-issued_successfully',
    },
    {
      type: 'service_invoice',
      id: 'service_invoice.pulled',
      text: 'webhooks.filters.service_invoice-pulled',
    },
    {
      type: 'tax_payment_form',
      id: 'tax_payment_form.created_successfully',
      text: 'webhooks.filters.tax_payment_form-created_successfully',
    },
    {
      type: 'tax_payment_form',
      id: 'tax_payment_form.creation_failed',
      text: 'webhooks.filters.tax_payment_form-creation_failed',
    },
    {
      type: 'tax_payment_form',
      id: 'tax_payment_form.creation_not_needed',
      text: 'webhooks.filters.tax_payment_form-creation_not_needed',
    },
    {
      type: 'transportation_invoice_inbound',
      id: 'transportation_invoice_inbound.event_raised_successfully',
      text: 'webhooks.filters.transportation_invoice_inbound-event_raised_successfully',
    },
    {
      type: 'transportation_invoice_inbound',
      id: 'transportation_invoice_inbound.issued_successfully',
      text: 'webhooks.filters.transportation_invoice_inbound-issued_successfully',
    },
  ];

  static get eventsByType() {
    return Object.groupBy(this.events, ({ type }) => type);
  }
}
