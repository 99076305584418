define("ember-cli-custom-properties/mixins/custom-properties", ["exports", "@ember/object/mixin", "ember-cli-custom-properties/-private/custom-property", "@ember/string"], function (_exports, _mixin, _customProperty, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const EMPTY_ARRAY = Object.freeze([]);
  var _default = _exports.default = _mixin.default.create({
    concatProperties: ['customProperties', 'customPropertyBindings'],
    _customProperties: null,
    customProperties: EMPTY_ARRAY,
    customPropertyBindings: EMPTY_ARRAY,
    init() {
      this._super(...arguments);

      // Initialize the custom properties.
      this._initCustomProperties();
    },
    _initCustomProperties() {
      let {
        customProperties,
        customPropertyBindings
      } = this;

      // Build an array of custom properties that we are observing.
      let bindings = customProperties.reduce((bindings, prop) => {
        const name = `--${(0, _string.dasherize)(prop)}`;
        bindings.push(_customProperty.default.create({
          prop,
          name,
          component: this
        }));
        return bindings;
      }, []);
      bindings = customPropertyBindings.reduce((bindings, mapping) => {
        let [prop, name] = mapping.split(':');
        bindings.push(_customProperty.default.create({
          prop,
          name,
          component: this
        }));
        return bindings;
      }, bindings);
      this._customProperties = bindings;
    },
    willDestroyElement() {
      this._super(...arguments);
      for (let i = 0, len = this._customProperties.length; i < len; ++i) {
        this._customProperties[i].destroy();
      }
    }
  });
});