define("ember-svg-jar/inlined/logo-banco-do-brasil-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#F9E900\"/><path fill=\"#2D589D\" fill-rule=\"evenodd\" d=\"M29.245 39.089l9.057-5.962-3.78-2.672 5.096-3.438 10.415 7.11-17.148 11.67 3.53 2.49 21.58-14.16-17.993-12.309-18.083 12.064 7.326 5.207zm13.92-7.303l3.721 2.472L29.624 45.73l10.665 7.222 4.759-3.3-3.525-2.512 8.912-6.086 7.414 5.142-18.077 11.986-17.838-12.16 21.231-14.236zM29.046 53.159l-3.506-2.582-3.722 2.507v4.964l7.228-4.889zm21.47-26.273l3.69 2.604 3.697-2.39v-5.246l-7.387 5.032z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});