define("ember-svg-jar/inlined/logo-elo-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1470:12593)\"><path fill=\"#000\" d=\"M0-6h36v36H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M10.517 12.761l3.76-1.613a2.121 2.121 0 00-2.495-.588c-.9.398-1.376 1.341-1.265 2.201zm-.924 1.777c-.743-1.104-.825-2.707-.033-3.962.8-1.267 2.305-1.926 3.753-1.648 1.526.294 2.6 1.485 2.879 2.775l-6.6 2.835zM16.925 7.21h1.415v.161c0 2.258.001 4.515-.002 6.772 0 .108.027.163.132.204.37.143.734.298 1.11.453l-.584 1.398-.575-.235c-.206-.085-.412-.169-.617-.256-.516-.22-.797-.614-.858-1.165a3.641 3.641 0 01-.02-.397V7.21z\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M21.957 14.188l-.987 1.119c-1.523-1.218-1.792-3.92-.01-5.49l.997 1.127c-.474.439-.728.981-.728 1.62 0 .639.25 1.182.728 1.624\" fill=\"#00A6E9\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M25.483 12.115c-.154-.638-.491-1.128-1.047-1.448-.55-.318-1.133-.368-1.742-.178l-.481-1.452c.225-.05.439-.116.657-.144.925-.118 1.786.07 2.559.607a3.644 3.644 0 011.498 2.201c.024.098 0 .124-.093.142-.409.078-.815.166-1.223.25-.038.008-.078.014-.128.022\" fill=\"#FFF400\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.28 15.998l.469-1.421c1.261.397 2.505-.372 2.79-1.628l1.445.3c-.37 2.126-2.665 3.485-4.703 2.75\" fill=\"#F44C1F\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M11.513 14.377c.428.255.88.373 1.37.318a2.156 2.156 0 001.271-.601l1.037 1.064c-1.136 1.192-3.125 1.376-4.468.477l.79-1.258\" fill=\"#fff\"/><path opacity=\".01\" fill=\"red\" d=\"M9 3h18v18H9z\"/></g><defs><clipPath id=\"clip0_1470:12593\"><path d=\"M0 2a2 2 0 012-2h32a2 2 0 012 2v20a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});