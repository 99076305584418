import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';

export default class CreditCardForm extends Component {
  @service store;
  @service payment;
  @service intl;

  _paymentMethod = null;

  constructor() {
    super(...arguments);

    this._paymentMethod = this.store.createRecord('payment-method', {
      accountId: this.args.accountId,
      type: 'CreditCard',
      card: this.store.createRecord('payment-method/card'),
    });

    this._paymentMethod.card.changeset.validate();
  }

  get model() {
    return this._paymentMethod;
  }

  @action
  async addCreditCardToAccount(model) {
    await model.card.changeset.validate();

    if (model.card.changeset.isInvalid) return;
    if (isPresent(this.args.paymentMethodSelected)) this.args.paymentMethodSelected(model);
    this.args.processPayment();
  }
}
