import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class ImpersonateRoute extends Route {
  @service sessionData;
  @service router;
  @service store;

  queryParams = ['redirect'];

  spreadTrim(str, ch) {
    const last = [...str].reverse().findIndex((char) => char !== ch);
    return str.substring(0, str.length - last);
  }

  async beforeModel(transition) {
    try {
      const account = await this.sessionData.impersonate(transition.to.params.accountId);
      if (!account) return;

      const defaultRoute = 'accounts';
      let route = defaultRoute;

      if (transition.to && transition.to.queryParams && transition.to.queryParams.redirect) {
        route = this.spreadTrim(transition.to.queryParams.redirect, '/');
        if (route.indexOf('subscriptions/') > 0 && route.indexOf('subscriptions/sub_') < 0) {
          route = route.replace('subscriptions/', 'subscriptions/sub_');
        }
      }

      let transitionTo = this.router.transitionTo(route, account.accountNumber);

      if (transitionTo.error) transitionTo = this.router.transitionTo(defaultRoute, account.accountNumber);

      return transitionTo;
    } catch (e) {
      console.error(e);
    }

    return this.router.transitionTo('index');
  }
}
