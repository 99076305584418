define("ember-cli-mdc-layout/services/layout", ["exports", "@ember/service", "@glimmer/tracking", "@ember/object/computed", "@ember/utils"], function (_exports, _service, _tracking, _computed, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const BREAKPOINT_PHONE = 0;
  const BREAKPOINT_TABLET = 480;
  const BREAKPOINT_DESKTOP = 840;
  let LayoutService = _exports.default = (_dec = (0, _computed.and)('_minWidthPhone', '_maxWidthPhone'), _dec2 = (0, _computed.and)('_minWidthTablet', '_maxWidthTablet'), _dec3 = (0, _computed.gte)('width', BREAKPOINT_DESKTOP), _dec4 = (0, _computed.gte)('width', BREAKPOINT_PHONE), _dec5 = (0, _computed.lt)('width', BREAKPOINT_TABLET), _dec6 = (0, _computed.gte)('width', BREAKPOINT_TABLET), _dec7 = (0, _computed.lt)('width', BREAKPOINT_DESKTOP), _class = class LayoutService extends _service.default {
    get isMobile() {
      return this.isPhone || this.isTablet;
    }
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "width", _descriptor, this);
      /// The layout is for a phone.
      _initializerDefineProperty(this, "isPhone", _descriptor2, this);
      /// The layout is for a tablet.
      _initializerDefineProperty(this, "isTablet", _descriptor3, this);
      /// The layout is for a desktop.
      _initializerDefineProperty(this, "isDesktop", _descriptor4, this);
      _defineProperty(this, "_resizeEventListener", null);
      _defineProperty(this, "_currentClassName", null);
      _initializerDefineProperty(this, "_minWidthPhone", _descriptor5, this);
      _initializerDefineProperty(this, "_maxWidthPhone", _descriptor6, this);
      _initializerDefineProperty(this, "_minWidthTablet", _descriptor7, this);
      _initializerDefineProperty(this, "_maxWidthTablet", _descriptor8, this);
      this._resizeEventListener = this.didResize.bind(this);
      if (window) {
        window.addEventListener('resize', this._resizeEventListener);
        this.didResize();
      }
    }
    willDestroy() {
      super.willDestroy();
      if (window) {
        window.removeEventListener('resize', this._resizeEventListener);
      }
    }
    didResize() {
      let {
        _currentClassName
      } = this;
      this.width = window.innerWidth;
      let {
        currentClassName
      } = this;

      // Replace the layout class name if the layout form factor has changed.

      if (_currentClassName !== currentClassName) {
        if ((0, _utils.isPresent)(_currentClassName)) {
          document.body.classList.remove(_currentClassName);
        }
        document.body.classList.add(currentClassName);
        this._currentClassName = currentClassName;
      }
    }
    get currentClassName() {
      return this.isPhone ? 'mdc-layout--phone' : this.isTablet ? 'mdc-layout--tablet' : 'mdc-layout--desktop';
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "width", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "isPhone", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "isTablet", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isDesktop", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "_minWidthPhone", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "_maxWidthPhone", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "_minWidthTablet", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "_maxWidthTablet", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
});