define("ember-svg-jar/inlined/icon-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.667 5.333H5.333A2.646 2.646 0 002.68 8l-.013 16a2.657 2.657 0 002.666 2.666h21.334A2.657 2.657 0 0029.333 24V8a2.657 2.657 0 00-2.666-2.667zm0 18.667H5.333v-8h21.334v8zm0-13.334H5.333V8h21.334v2.666z\" fill=\"#B8C0C3\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});