import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class SubscriptionIndexController extends Controller {
  @service router;
  @service layout;
  @service intl;
  @service sessionData;

  @tracked showContactModal = null;
  @tracked contact = null;

  get shortcuts() {
    const actions = A(['users', 'providers', 'apikeys', 'webhooks', 'usage', 'export']);
    return actions.map((act) => ({
      title: this.intl.t(`shortcuts.${act}.title`),
      description: this.intl.t(`shortcuts.${act}.description`),
      icon: this.intl.t(`shortcuts.${act}.icon`),
      action: { route: `accounts.subscriptions.${act}`, label: this.intl.t(`shortcuts.${act}.action.label`) },
    }));
  }

  get createdOn() {
    return new Date(this.sessionData.subscription.current.createdOn);
  }

  @action
  openContactModal(contact) {
    this.contact = contact;
    this.showContactModal = true;
  }

  @action
  closedContactModal() {
    this.showContactModal = null;
  }

  @action
  transitionToEditAccount() {
    this.router.transitionTo('accounts.edit', this.model.accountNumber);
  }
}
