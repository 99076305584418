import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import arg from '../../../utils/decorators/arg';

export default class LoadersProducPriceRateItemsComponent extends Component {
  @service layout;
  @service store;

  @arg priceLevelId;
  @arg productId;

  @tracked value = {};

  constructor(...args) {
    super(...args);
    this.getItems.perform();
  }

  @task({ drop: true })
  *getItems() {
    if (this.priceLevelId && this.productId) {
      let productPriceRate = null;

      try {
        productPriceRate = yield this.store.queryRecord('product-price-rate', {
          priceLevelId: this.priceLevelId,
          productId: this.productId,
        });
        productPriceRate.product = yield this.store.findRecord('product', this.productId);
      } catch (ex) {}

      this.value = productPriceRate;
    } else {
      this.value = null;
    }
  }
}
