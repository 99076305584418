define("ember-route-helpers/helpers/replace-with-external", ["exports", "@ember/application", "ember-route-helpers/helpers/transition"], function (_exports, _application, _transition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
   * Given this "external routes":
   *
   * ```js
   * // host app
   * dependencies: {
   *   externalRoutes: {
   *     posts: 'blog.posts'
   *   }
   * }
   * ```
   *
   * ```js
   * // engine
   * dependencies: {
   *   externalRoutes: [
   *     'posts'
   *   ]
   * }
   * ```
   *
   * You can then use those external routes within a template:
   *
   * ```hbs
   * {{my-component onclick=(replace-with-external 'posts' (query-params id=3 comments=true)}}
   * ```
   */
  class TransitionToHelper extends _transition.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "transitionMethod", 'replaceWith');
    }
    getRouteName(routeName) {
      const owner = (0, _application.getOwner)(this);
      return owner._getExternalRoute(routeName);
    }
  }
  _exports.default = TransitionToHelper;
});