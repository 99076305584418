import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { isBlank, isPresent } from '@ember/utils';

import arg from '../../../utils/decorators/arg';

export default class LoadersProvidersComponent extends Component {
  @service store;
  @service user;

  @arg subscriptionId;
  @arg callbackSuccess;
  @arg top;
  @arg skip;

  @tracked providers = [];
  @tracked error = false;

  @task({ drop: true })
  *getProviders() {
    if (isBlank(this.subscriptionId)) return;

    try {
      let rawProviders = yield this.store.query('provider', {
        adapterOptions: { subscriptionId: this.subscriptionId.replace('sub_', '') },
      });
      let apikeys = yield this.store.query('apikey/grant', { adapterOptions: { subscriptionId: this.subscriptionId } })
        ?.content;

      let filteredProviders = rawProviders.filter((provider) => {
        return (
          provider.namespace == 'DFeTech.ServiceInvoice' ||
          provider.namespace == 'DFeTech.ProductInvoice' ||
          provider.namespace == 'DFeTech.ConsumerInvoice' ||
          provider.namespace == 'DataTech.Batch.ProductInvoice.Pdf' ||
          provider.namespace == 'DataTech.Batch.ProductInvoice.Xml' ||
          (provider.namespace == 'DataTech.Batch.ProductInvoice.Serpro' &&
            apikeys?.findBy('description', 'Serpro Backup (serprobackup.nfe.io)')) ||
          provider.namespace == 'DataTech.Batch.ProductInvoice.Serpro.Pdf' ||
          provider.namespace == 'DataTech.Batch.ProductInvoice.Serpro.Xml' ||
          provider.namespace == 'DataTech.LegalEntity' ||
          provider.namespace == 'DataTech.NaturalPerson' ||
          provider.namespace == 'DFeTech.ServiceInvoice.Batch' ||
          provider.namespace == 'DFeTech.Company' ||
          provider.namespace == 'DFeTech.ProductInvoice.Inbound' ||
          provider.namespace == 'DFeTech.TransportationInvoice.Inbound' ||
          provider.namespace == 'DFeTech.WebHook.Encryption' ||
          (provider.namespace == 'AzHub.Billing.Order' && this.user.isAdmin)
        );
      });

      let azHubProviders = filteredProviders.filter((provider) => provider.namespace.startsWith('AzHub'));
      let dataTechProviders = filteredProviders.filter((provider) => provider.namespace.startsWith('DataTech'));
      let dfeTechProviders = filteredProviders.filter((provider) => provider.namespace.startsWith('DFeTech'));

      let providersList = azHubProviders.concat(dataTechProviders, dfeTechProviders);

      if (isPresent(this.callbackSuccess)) {
        yield this.callbackSuccess(providersList);
      }

      this.providers = providersList;

      return;
    } catch (error) {
      console.log(error);
      this.error = true;
    }
  }
}
