define("ember-svg-jar/inlined/icon-barcode", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M2.667 8H4v16H2.667V8zm2.666 0H8v16H5.333V8zm5.334 0H12v16h-1.333V8zm2.666 0h4v16h-4V8zm5.334 0H20v16h-1.333V8zm4 0H24v16h-1.333V8zm2.666 0h1.334v16h-1.334V8zM28 8h1.333v16H28V8z\" fill=\"#B8C0C3\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});