import { service } from '@ember/service';
import RESTAdapter from '@ember-data/adapter/rest';
import ENV from 'accounts-app/config/environment';

export default class ApikeyGrantAdapter extends RESTAdapter {
  @service sessionData;

  host = ENV.APP.IDENTITY_API_URL;
  namespace = 'api/subjects';

  query(store, model, { adapterOptions }) {
    let url = `${this.host}/${this.namespace}/${adapterOptions.subscriptionId}/grants`;
    return super.ajax(url, 'GET');
  }

  activate(model) {
    if (model.get('sessionId')) {
      const url = `${this.host}/api/subjects/${model.get('subjectId')}/grants/${model.get('sessionId')}/activate`;
      return this.ajax(url, 'PUT');
    }
  }

  deactivate(model) {
    if (model.get('sessionId')) {
      const url = `${this.host}/api/subjects/${model.get('subjectId')}/grants/${model.get('sessionId')}/deactivate`;
      return this.ajax(url, 'PUT');
    }
  }
}
