import ApplicationAdapter from './application';
import ENV from 'accounts-app/config/environment';

export default class LeadAdapter extends ApplicationAdapter {
  host = ENV.APP.LEADS_URL;

  sendLeads(options) {
    return this.ajax(`${this.host}`, 'POST', { data: options });
  }
}
