import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class LoginCallbackRoute extends Route {
  @service implicitGrantAuthenticator;
  @service router;
  @service session;

  activate() {
    if (window.location.hash) {
      this.session.set('data.hash', window.location.hash);
      return this.router.replaceWith('login');
    }
    return this.router.replaceWith('index');
  }

  beforeModel() {
    this.activate();
  }
}
