import BaseOdataSerializer from './base-odata';

export default class AgreementSerializer extends BaseOdataSerializer {
  primaryKey = 'id';

  serializeIntoHash(hash, type, record, options) {
    // remove modelName from payload
    return Object.assign(hash, this.serialize(record, options));
  }
}
