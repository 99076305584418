import Component from '@glimmer/component';
import { action } from '@ember/object';

function noOp() {}

export default class MdcActionCardComponent extends Component {
  @action
  buttonAction() {
    return (this.args.button.action || noOp)();
  }
}
