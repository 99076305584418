import Component from '@glimmer/component';
import { action } from '@ember/object';

import arg from '../../utils/decorators/arg';

export default class WebhookDeleteDialog extends Component {
  @arg callbackSuccess;
  @arg callbackCancel;
  @arg webhook;

  @action
  close() {
    this.callbackCancel();
  }

  @action
  accept() {
    this.callbackSuccess.perform(this.webhook);
  }
}
