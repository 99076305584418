import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';

export default class AgreementModel extends Model {
  @service intl;

  @attr('string') agreementNumber;
  @attr('string') createdOn;
  @attr('string') modifiedOn;
  @attr('string') status;
  @attr account;
  @attr salesTerm;
  @attr preferredPaymentMethod;
  @attr paymentMethodTypes;
  @attr('string') type;
  @attr('string') beginOn;
  @attr('string') expireOn;
  @attr('string') billingBeginOn;
  @attr('string') billingEndOn;
  @attr('string') billingFrequency;
  @attr('string') minimumDeductibleAmount;
  @attr('string') customTerm01;
  @attr('string') eInvoiceDescription;
  @attr('number') duration;
  @attr('number') daysInArrears;
  @attr('number') overdueInvoicesCount;
  @attr('number') daysForTerminationNotification;
  @attr('string') contractEscalationPriceIndex;
  @attr('string') contractEscalationType;

  get paymentMethodTypesIntl() {
    return this.paymentMethodTypes.map((a) => this.intl.t(`paymentMethodTypes.${a}`));
  }
}
