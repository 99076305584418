import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class LoadersOrdersComponent extends Component {
  @service store;

  @arg accountId;
  @arg top;
  @arg skip;

  @tracked orders = [];
  @tracked error = false;

  @task({ drop: true })
  *getOrders() {
    try {
      this.orders = yield this.store.query('order', {
        accountId: this.accountId,
        top: this.top,
        skip: this.skip,
        count: true,
      });
    } catch (error) {
      this.error = true;
    }

    let possibleStatuses = [
      'Draft',
      'Executing',
      'WaitingBilling',
      'WaitingApproval',
      'Refused',
      'Done',
      'Approved',
      'Declined',
      'NotBilled',
    ];
    this.orders.map((order) => {
      if (possibleStatuses.includes(order.status)) return;
      order.status = 'unknown';
    });
  }
}
