define("ember-svg-jar/inlined/logo-itau-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#EC7600\"/><path fill=\"#1D3F89\" fill-rule=\"evenodd\" d=\"M26.092 20h27.816A6.092 6.092 0 0160 26.092v27.816A6.092 6.092 0 0153.908 60H26.092A6.092 6.092 0 0120 53.908V26.092A6.092 6.092 0 0126.092 20z\" clip-rule=\"evenodd\"/><path fill=\"#F4E800\" fill-rule=\"evenodd\" d=\"M50.471 42.945h2.223L55.24 40h-2.773l-1.997 2.945zm-23.804-2.893h-3.564v13.793h3.564V40.052zm13.52 5.406c-2.365-.082-3.216.718-3.216.718l-.947-1.855s1.14-.66 1.739-.86c.598-.2 2.75-.713 4.735.036 1.275.512 2.142 1.355 2.413 3.07.039 1.88 0 7.106 0 7.106h-3.037v-.95s0 .145-1.154.695c-1.154.55-3.052.445-4-.304-.949-.749-1.78-1.7-1.249-3.56.531-1.86 2.513-2.417 3.157-2.531.643-.115 3.156-.067 3.156-.067s-.076-1.43-1.598-1.498zm1.705 3.383v1.648s-1.633 1.964-2.987 1.017c-.65-.455-.683-1.117-.415-1.654.29-.579.965-.996 1.422-1.01.879-.03 1.98 0 1.98 0zm14.946-5.286H53.51s.052 4.623 0 5.73c-.053 1.11-.326 1.937-1.848 2.076-1.513.137-1.816-1.14-1.888-1.443l-.001-.005c-.097-2.9-.067-6.358-.067-6.358h-3.125s-.115 6.093.136 7.7c.252 1.608 2.103 2.355 4.066 2.421 1.964.066 3.068-1.496 3.068-1.496v1.552h2.986V43.555zm-24.442 6.609v-4.34l1.965.023V43.48h-1.939v-3.407h-3.118v3.435h-1.53v2.316h1.515v2.736c-.227 2.771.54 4.323 1.512 4.9.972.578 3.437.055 3.437.055v-2.27c.013-.006 0-.013 0-.013v.013c-.027.015-.168.03-.788-.013-.91-.062-1.054-1.068-1.054-1.068z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});