import Controller from '@ember/controller';
import { action } from '@ember/object';

export default class AgreementPrintController extends Controller {
  @action
  print() {
    window.onfocus = window.onmousemove = function () { window.onfocus = null; window.onmousemove = null; window.history.back(); }
    window.print();
  }
}
