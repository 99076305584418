/* eslint-disable ember/routes-segments-snake-case */
import EmberRouter from '@ember/routing/router';
import config from 'accounts-app/config/environment';

export default class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;
}

Router.map(function () {
  this.route('impersonate', { path: '/impersonate/:accountId' });

  this.route('login');
  this.route('login-callback');

  this.route('create-account');

  this.route('payment', { path: '/payments/:paymentId' });
  this.route('order', { path: '/accounts/:accountId/orders/:orderId' });

  this.route('mgmt', function () {
    this.route('accounts');
    this.route('subscriptions');
  });

  this.route('accounts', function () {
    this.route('index', { path: '/:accountId' });
    this.route('edit', { path: '/:accountId/edit' });

    this.route('agreements', { path: '/:accountId/agreements' }, function () {
      this.route('index', { path: '' });
      this.route('view', { path: '/:agreementId' });
      this.route('products', { path: '/:agreementId/products' });
      this.route('checkout', { path: '/:agreementId/checkout' });
      this.route('support', { path: '/:agreementId/support' });
      this.route('print', { path: '/:agreementId/print' });
    });

    this.route('invoices', { path: '/:accountId/invoices' });
    this.route('payment-methods', { path: '/:accountId/payment-methods' });
    this.route('orders', { path: '/:accountId/orders' });
    this.route('subscriptions', { path: '/:accountId/subscriptions' }, function () {
      this.route('view', { path: '/:subscriptionId' });
      this.route('export', { path: '/:subscriptionId/exports' });
      this.route('usage', { path: '/:subscriptionId/usage' });
      this.route('apikeys', { path: '/:subscriptionId/apikeys' });
      this.route('providers', { path: '/:subscriptionId/providers' });
      this.route('users', { path: '/:subscriptionId/users' });
      this.route('webhooks', { path: '/:subscriptionId/webhooks' }, function () {
        this.route('edit', { path: '/:webhookId' });
        this.route('create');
      });
    });
  });

  this.route('not-found', { path: '/*path' });
});
