import Service, { service } from '@ember/service';

import { camelize } from '@ember/string';
import { storageFor } from 'ember-local-storage';

export default class ProviderService extends Service {
  @service store;
  @service session;
  @storageFor('session') sessionStorage;

  constructor() {
    super(...arguments);
    this._providers = this.sessionStorage.get('providers');
  }

  async setup(id) {
    this._resetProviders();
    const providers = await this.store.query('provider', {
      adapterOptions: { subscriptionId: id.replace('sub_', '') },
    });

    providers.forEach(provider => {
      const normalizedProvider = this._normalizeProvider(provider?.namespace);
      this._providers[normalizedProvider] = provider.isActive;
      this.notifyPropertyChange(normalizedProvider);
    });

    this.sessionStorage.set('providers', this._providers);
  }

  async setStatus(subscriptionId, providerId, action) {
    const providers = await this.store.query('provider', {
      adapterOptions: {
        subscriptionId: subscriptionId.replace('sub_', ''),
      },
    });

    const provider = await providers.filterBy('namespace', providerId)?.firstObject;
    await provider.setStatus.perform(action, subscriptionId);

    const normalizedProvider = this._normalizeProvider(provider?.namespace);
    this._providers[normalizedProvider] = action === 'register';
    this.sessionStorage.set('providers', this._providers);
    this.notifyPropertyChange(normalizedProvider);
  }

  isEnabled(provider) {
    return this._providerIsEnabled(provider);
  }

  _resetProviders() {
    this._providers = {};
    this.sessionStorage.set('providers', this._providers);
  }

  _providerIsEnabled(provider) {
    const normalizedFeature = this._normalizeProvider(provider);
    return this._providers[normalizedFeature] || false;
  }

  _normalizeProvider(provider) {
    return camelize(provider);
  }

  unknownProperty(key) {
    return this.isEnabled(key);
  }

  async isEmpty() {
    return Object.keys(this._providers).length === 0;
  }
}
