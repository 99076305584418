import Service, { service } from '@ember/service';

import ENV from 'accounts-app/config/environment';
import MD5 from 'crypto-js/md5';
import enUS from 'date-fns/locale/en-US';
import { isPresent } from '@ember/utils';
import { parseJSONWebToken } from 'nfeio-ember-implicit-auth/utils/jwt';
import ptBR from 'date-fns/locale/pt-BR';
import { setDefaultOptions as setDateFnsDefaultOptions } from 'date-fns';
import { storageFor } from 'ember-local-storage';

const supportedLocales = {
  pt: 'pt-br',
  en: 'en-us',
};

export default class UserService extends Service {
  @service session;
  @service store;
  @service intl;
  @service moment;
  @storageFor('settings') settings;

  _getTokenData() {
    const access_token = this.session.data.authenticated.access_token;
    if (!isPresent(access_token)) return;

    return parseJSONWebToken(access_token);
  }

  get isAdmin() {
    const tokenData = this._getTokenData();
    return tokenData?.role === 'platform.admin';
  }

  get gravatar() {
    const tokenData = this._getTokenData();

    let gravatarData = {
      url: `https://www.gravatar.com/avatar/`,
      email: null,
    };

    if (this.session.isAuthenticated && isPresent(tokenData)) {
      gravatarData.url += MD5(tokenData.email) + '?d=mp';
      gravatarData.email = tokenData.email;
    }

    return gravatarData;
  }

  async getCurrent() {
    if (this.session.isAuthenticated == false) {
      return null;
    }

    let currentUser = this.session.get('data.current_user');

    if (isPresent(currentUser)) return currentUser;

    return await this.store.queryRecord('user', { me: true }).then((data) => {
      this.session.set('data.current_user', data.serialize());
      this.session.store.persist(this.session.data);
      return data;
    });
  }

  checkSupportedLocales(locale) {
    return supportedLocales[Object.keys(supportedLocales).find((item) => locale.startsWith(item))];
  }

  get authorizationHeader() {
    if (this.session.isAuthenticated)
      return {
        Authorization: `Bearer ${this.session.data.authenticated.access_token}`,
      };

    return {};
  }

  async manageLanguage() {
    // default locale (en-us)
    let supportedLocale = ENV.intl.defaultLocale;

    // navigator locale
    const navigatorLocale =
      navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;
    if (isPresent(navigatorLocale)) {
      supportedLocale = this.checkSupportedLocales(navigatorLocale);
    }

    // browser storage locale, this data is set when user was logged
    const browserStorageLocale = this.settings.get('defaultLocale');
    if (isPresent(browserStorageLocale)) {
      supportedLocale = this.checkSupportedLocales(browserStorageLocale);
    }

    // is authenticated? get locale from user info (identity claims)
    if (this.session.isAuthenticated) {
      const userInfo = await this.getCurrent();
      if (isPresent(userInfo) && isPresent(userInfo.locale)) {
        const userLocale = userInfo.locale.toLowerCase();
        supportedLocale = this.checkSupportedLocales(userLocale);
      }
    }

    // querystring locale ?lang=pt-br
    const queryParamLocale = new URLSearchParams(window.location.search).get('lang');
    if (isPresent(queryParamLocale)) {
      supportedLocale = this.checkSupportedLocales(queryParamLocale);
    }

    // fallback condition
    if (!this.intl.locales.some((item) => item.startsWith(supportedLocale) || supportedLocale.startsWith(item))) {
      supportedLocale = ENV.intl.defaultLocale;
    }

    // is authenticated? we set the browser storage locale
    if (this.session.isAuthenticated) {
      this.settings.set('defaultLocale', supportedLocale);
    }

    this.intl.setLocale(supportedLocale);
    const dateLocale = supportedLocale == 'pt-br' ? ptBR : enUS;
    this.moment.setLocale(supportedLocale);
    setDateFnsDefaultOptions({ locale: dateLocale });
  }
}
