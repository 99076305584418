import BaseAuthenticatedRoute from '../../../base-authenticated';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';

export default class WebhooksCreateRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId, subscriptionId }) {
    accountId = accountId ?? this.paramsFor('accounts.subscriptions').accountId;
    subscriptionId = subscriptionId ?? this.paramsFor('accounts.subscriptions.webhooks').subscriptionId;

    if (isPresent(accountId) && isPresent(subscriptionId)) {
      const webhook = await this.store.createRecord('webhook');
      webhook.subscription = { id: subscriptionId };
      return webhook;
    }

    return null;
  }
}
