import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';

export default class AgreementProductModel extends Model {
  @service intl;

  @attr('string') billCycleType;
  @attr('string') createdOn;
  @attr('string') modifiedOn;
  @attr('string') state;
  @attr('string') status;

  @attr('number', { defaultValue: 0 }) cycles;
  @attr('number', { defaultValue: 0 }) discountCycles;
  @attr('number', { defaultValue: 0 }) minimumQuantity;

  @attr priceLevel;
  @attr product;
}

// {
//   "createdOn": "2021-08-23T11:49:26+00:00",
//   "cycles": null,
//   "discountCycles": null,
//   "id": "ab6c7e28-0804-ec11-b6e6-00224835d7ac",
//   "minimumQuantity": 13332,
//   "modifiedOn": "2021-08-23T11:51:35+00:00",
//   "priceLevel": {
//       "beginOn": null,
//       "description": null,
//       "endOn": null,
//       "id": "6c79ab3f-b1e1-ea11-a813-000d3ac05981",
//       "name": "Intuit Brasil"
//   },
//   "product": {
//       "billableSku": "DataTech.LegalEntity.LegalEntity.GetBasicInfo",
//       "description": "LegalEntity.LegalEntity_GetBasicInfo",
//       "id": "85163858-c6db-ea11-a813-000d3ac05981",
//       "isBillable": true,
//       "name": "Consulta Empresa - Receita Federal - Dados Básicos",
//       "productNumber": "DT-PJRF-001",
//       "provider": null,
//       "sku": null
//   }
// }
