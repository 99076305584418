import BaseAuthenticatedRoute from 'accounts-app/routes/base-authenticated';
import { action } from '@ember/object';
import { service } from '@ember/service';

export default class AccountPaymentMethodsRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId }) {
    accountId = accountId ?? this.paramsFor('accounts').accountId;
    return await this.store.findRecord('account', accountId);
  }

  @action
  refreshRoute() {
    this.refresh();
  }
}
