define("ember-svg-jar/inlined/logo-wallet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M42 36v2c0 2.2-1.8 4-4 4H10c-2.22 0-4-1.8-4-4V10c0-2.2 1.78-4 4-4h28c2.2 0 4 1.8 4 4v2H24c-2.22 0-4 1.8-4 4v16c0 2.2 1.78 4 4 4h18zm-18-4h20V16H24v16zm8-5c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z\" fill=\"#103541\"/>",
    "attrs": {
      "width": "48",
      "height": "48",
      "viewBox": "0 0 48 48",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});