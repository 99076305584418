import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MainAuthenticatedController extends Controller {
  @service router;
  @service layout;
  @service intl;
  @service sessionData;

  @tracked showContactModal = null;
  @tracked contact = null;

  @action
  openContactModal(contact) {
    this.contact = contact;
    this.showContactModal = true;
  }

  @action
  closedContactModal() {
    this.showContactModal = null;
  }

  @action
  transitionToEditAccount() {
    this.router.transitionTo('accounts.edit', this.model.accountNumber);
  }
}
