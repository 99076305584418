define("ember-svg-jar/inlined/logo-mastercard-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#F4F4F4\"/><circle cx=\"32.788\" cy=\"39.455\" r=\"11.454\" fill=\"#F12309\"/><circle cx=\"47.212\" cy=\"39.455\" r=\"11.454\" fill=\"#FB9F01\"/><path fill=\"#FF6100\" fill-rule=\"evenodd\" d=\"M40 30.555a11.432 11.432 0 014.242 8.9c0 3.592-1.654 6.799-4.242 8.899a11.432 11.432 0 01-4.242-8.9c0-3.593 1.654-6.8 4.242-8.9z\" clip-rule=\"evenodd\"/><path fill=\"red\" d=\"M20 20h40v40H20z\" opacity=\".01\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});