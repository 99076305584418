import Route from '@ember/routing/route';
import { debug } from '@ember/debug';
import { service } from '@ember/service';

export default class IndexRoute extends Route {
  @service sessionData;
  @service router;

  async beforeModel() {
    const currentAccount = await this.sessionData.getCurrentAccount();

    if (currentAccount) {
      debug(`replaceWith accounts.index ${currentAccount.accountNumber}`);
      this.router.replaceWith('accounts.index', currentAccount.accountNumber);
      return;
    }

    this.router.transitionTo('login');
  }
}
