import RESTSerializer from '@ember-data/serializer/rest';

export default class BaseOdataSerializer extends RESTSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.value) {
      payload = { [primaryModelClass.modelName]: payload.value };
    } else {
      payload = { [primaryModelClass.modelName]: payload };
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
