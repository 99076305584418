import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class RequireAuthenticationController extends Controller {
  @service session;
  @service drawer;
  @service layout;
  @service sessionData;
  @service router;
  @service user;
  // @service provider;

  @tracked
  isMenuOpen = false;

  @tracked
  selectedSubscription = undefined;

  get shouldShowTopAppBar() {
    return this.session.isAuthenticated;
  }

  get isPrint() {
    return this.router.currentURL ? this.router.currentURL.indexOf('/print') > 0 : false;
  }

  @action
  async logout() {
    await this.session.invalidate();
  }

  @action
  drawerToggle() {
    this.drawer.toggle();
  }

  @action
  change({ detail }) {
    if (detail?.option) this.sessionData.setCurrentSubscription(detail?.option);
  }
}
