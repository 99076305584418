import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

import arg from '../../../utils/decorators/arg';

export default class LoadersApikeysComponent extends Component {
  @service store;

  @arg subscriptionId;
  @arg clientId;
  @arg type;
  @arg top;
  @arg skip;

  @tracked apikeys = [];
  @tracked error = false;

  @task({ drop: true })
  *getApikeys() {
    try {
      this.apikeys = yield this.store.query(`apikey/${this.type}`, {
        adapterOptions: {
          subscriptionId: this.subscriptionId,
          clientId: this.clientId ?? '',
        },
      });
      return;
    } catch (error) {
      console.log(error);
      this.error = true;
    }
  }
}
