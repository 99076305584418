import EmberObject from '@ember/object';

export default class taxRegimesEnum extends EmberObject.extend() {
  static taxRegimesAccount = [
    {
      text: 'Microempreendedor Individual (MEI)',
      value: 'MEI',
    },
    {
      text: 'Simples Nacional',
      value: 'SimplesNacional',
    },
    {
      text: 'Lucro Presumido',
      value: 'LucroPresumido',
    },
    {
      text: 'Lucro Real',
      value: 'LucroReal',
    },
    {
      text: 'Isento',
      value: 'None',
    },
  ];
}
