import ApplicationAdapter from './application';
import ENV from 'accounts-app/config/environment';
import { service } from '@ember/service';

export default class UserAdapter extends ApplicationAdapter {
  @service user;

  implicitGrantConfigs = ENV['nfeio-ember-implicit-auth'];

  get headers() {
    return this.user.authorizationHeader;
  }

  urlForQueryRecord() {
    return `${this.implicitGrantConfigs.host}${this.implicitGrantConfigs.userinfoEndpoint}`;
  }

  queryRecord(store, type, query, adapterOptions) {
    const url = this.buildURL(type.modelName, null, null, 'queryRecord', query);
    return this.ajax(url, 'GET');
  }
}
