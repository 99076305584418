define("ember-svg-jar/inlined/logo-americanexpress-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#016FD0\" d=\"M0 2a2 2 0 012-2h20a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M6.887 10.5l-.294-.748H5.73l-.296.748H3l2.149-4.998h2.05l.374.871V5.5l2.563.01.41 1.144.403-1.152h5.937l.595.769.597-.769h2.758l-2.008 2.5 1.988 2.498h-2.8l-.559-.732-.545.732H6.887z\" clip-rule=\"evenodd\"/><path fill=\"#016FD0\" fill-rule=\"evenodd\" d=\"M13.537 6.132v3.734h3.06l.857-1.15.878 1.15h1.175l-1.485-1.867 1.498-1.867h-1.13L17.483 7.3l-.9-1.164v-.004h-3.046zm2.537.835H14.48v.639h1.586v.786H14.48v.663h1.61l.831-1.033-.847-1.055zM3.963 9.866L5.57 6.132h1.216l1.423 3.313V6.132l1.486.006.864 2.407.843-2.413h1.474v3.734h-.933V7.115l-.99 2.75h-.818l-.992-2.75v2.75H7.32l-.295-.747h-1.72l-.297.748H3.963zm2.724-1.559l-.525-1.306-.526 1.306h1.051z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 24 16"
    }
  };
});