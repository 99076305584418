import Component from '@glimmer/component';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { tracked } from '@glimmer/tracking';

export default class NfeDataTableHeaderComponent extends Component {
  @tracked inputValue;

  get isDebounceActive() {
    return this.args.searchTerm.debounce;
  }

  @action
  didInsert(element) {
    this.inputValue = this.args.searchTerm.value;
  }

  @action
  onInput(ev) {
    if (!this.isDebounceActive) return;

    debounce(
      this,
      function () {
        this.args.searchTerm.onSearch(ev);
      },
      1000
    );
  }

  @action
  onChange(ev) {
    if (this.isDebounceActive) return;

    this.args.searchTerm.onSearch(ev);
  }
}
