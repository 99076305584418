import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import arg from '../../utils/decorators/arg';

export default class MdcExpansionPanelComponent extends Component {
  /**
   * Collapsed/expanded state
   *
   * @property collapsed
   * @type boolean
   * @default true
   * @public
   */
  @arg
  collapsed = true;

  /**
   * True if this item is expanded
   *
   * @property active
   * @private
   */
  @tracked
  active = !this.collapsed;

  @tracked
  panelContentStyle = '';

  @action
  togglePanel(element) {
    if (this.active) {
      let height = element.querySelector('.mdc-expansion-panel__content').getBoundingClientRect().height;
      this.panelContentStyle = `min-height:${height}px; max-height: max-content; visibility: visible`;
    } else {
      this.panelContentStyle = 'visibility: hidden';
    }
  }
}
