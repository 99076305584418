import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class SubscriptionUsageSummaryAdapter extends BaseOdataAdapter {
  namespace = 'accounts';

  constructor(...args) {
    super(...args);
    super.host = ENV.APP.USAGE_API_URL;
  }

  query(store, type, query, recordArray, adapterOptions) {
    const buildURL = this.buildURL(type.modelName, null, null, 'query', query);
    const url = this.getODataFullUrl(buildURL, query);
    return this.ajax(url);
  }

  urlForQuery({ accountId, subscriptionId }, modelName) {
    let url = `${this.host}/accounts('${accountId}')/subscriptions('${subscriptionId}')/Api.GetUsageSummary(year=${date.year},month=${date.month})`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/subscriptions/${subscriptionId}/Api.GetUsageSummary(year=${date.year},month=${date.month})`;
    }
    return url;
  }

  urlForQueryRecord({ accountId, subscriptionId, date }, modelName) {
    let url = `${this.host}/accounts('${accountId}')/subscriptions('${subscriptionId}')/Api.GetUsageSummary(year=${date.year},month=${date.month})`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/subscriptions/${subscriptionId}/Api.GetUsageSummary(year=${date.year},month=${date.month})`;
    }
    return url;
  }
}
