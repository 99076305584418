import Component from 'ember-cli-mdc-base/component';
import { action } from '@ember/object';
import { equal } from '@ember/object/computed';
import { assert } from '@ember/debug';
import { tracked } from '@glimmer/tracking';
import { guidFor } from '@ember/object/internals';
import { isPresent, isEmpty } from '@ember/utils';

function noOp() {}

const STYLES = ['filled', 'outlined'];

import { MDCTextField } from '@material/textfield';

const VALIDATION_ERROR_TYPE = [
  'badInput',
  'patternMismatch',
  'rangeOverflow',
  'rangeUnderflow',
  'stepMismatch',
  'tooLong',
  'tooShort',
  'valueMissing',
  'typeMismatch',
  'customError',
];

export default class MdcTextfieldComponent extends Component {
  @tracked
  labelId;

  @tracked
  helperId;

  get style() {
    return this.args.style || 'filled';
  }

  get styleClassName() {
    let style = this.style;

    assert(
      'The outlined style cannot be used with a full width text field.',
      style === 'filled' || !this.args.fullWidth
    );
    assert(`The textfield component supports the following styles: ${STYLES}`, STYLES.includes(style));

    return `mdc-text-field--${style}`;
  }

  doPrepareElement(element) {
    let { value } = this.args;

    if (isPresent(value)) {
      element.classList.add('mdc-text-field--label-floating');

      let floatingLabel = element.querySelector('.mdc-floating-label');

      if (isPresent(floatingLabel)) {
        floatingLabel.classList.add('mdc-floating-label--float-above');
      }
    }

    this.labelId = guidFor(this);
    this.helperId = `${guidFor(this)}__helper-text`;
  }

  doCreateComponent(element) {
    return new MDCTextField(element);
  }

  @action
  didUpdateValue(element, [value = '']) {
    if (isEmpty(value)) return;

    const { onChange, model, property } = this.args;

    this.validateProperty(model, property);

    this.component.value = value;

    if (onChange) onChange(value);
  }

  @equal('style', 'filled')
  filled;

  @equal('style', 'outlined')
  outlined;

  @tracked
  _count = 0;

  get count() {
    return this.args.count || this._count;
  }

  get max() {
    return this.args.max || 0;
  }

  get helperLine() {
    let { characterCount = false } = this.args;
    return isPresent(this.helperText) || characterCount;
  }

  get helperText() {
    let { helperText } = this.args;
    return this.error || this.validationMessage || helperText;
  }

  get persistentHelperText() {
    let { persistentHelperText } = this.args;
    return isPresent(this.error) || isPresent(this.validationMessage) || persistentHelperText;
  }

  get error() {
    const { error, errorMessage } = this.args;

    if (isEmpty(error)) return;

    const message = error.validation instanceof Array ? error.validation[0] : error.validation;

    if (errorMessage) {
      return errorMessage;
    } else {
      return message;
    }
  }

  get leadingIconClick() {
    return this.args.leadingIconClick || noOp;
  }

  get trailingIconClick() {
    return this.args.trailingIconClick || noOp;
  }

  @tracked
  validationMessage;

  @action
  focus() {
    this.validationMessage = null;
  }

  validateProperty(model, property) {
    if (!model || !model.changeset) return;
    model.changeset.validate(property);
  }

  @action
  validate(ev) {
    let { validationMessages, errorMessage, property, model } = this.args;

    this.validateProperty(model, property);

    let { target } = ev;

    if (this.error) {
      target.setCustomValidity(this.error);
    } else {
      target.setCustomValidity('');
    }

    if (!target.validity.valid) {
      if (isPresent(validationMessages)) {
        // The user wants to display a custom validation error message instead
        // of the default validation error message.

        for (let i = 0, len = VALIDATION_ERROR_TYPE.length; i < len; ++i) {
          const reason = VALIDATION_ERROR_TYPE[i];
          const failed = target.validity[reason];

          if (failed) {
            this.validationMessage = validationMessages[reason] || target.validationMessage;
            break;
          }
        }
      } else if (isPresent(errorMessage)) {
        this.validationMessage = errorMessage;
      } else {
        // Set the default validation message.
        this.validationMessage = this.error;
      }
    }
  }
}
