import Service, { service } from '@ember/service';

import { isPresent } from '@ember/utils';
import { storageFor } from 'ember-local-storage';
import { tracked } from '@glimmer/tracking';

export default class DrawerService extends Service {
  @storageFor('settings') settings;
  @service layout;

  toggle(options = {}) {
    options.opened = isPresent(options.opened) || !this.settings.get('drawer.opened');
    this.settings.set('drawer.opened', options.opened);
    this.isOpened = options.opened;
    return this.isOpened;
  }

  @tracked
  isOpened = this.settings.get('drawer.opened');

  @tracked
  style = this.layout.isDesktop ? 'dismissible' : 'modal';
}
