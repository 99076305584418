import Valid from 'card-validator';

export default function validateCreditCard({ type, message } = {}) {
  function _validateCardholderName(value) {
    console.log(!!(value || '').match(/[a-zA-Z]{2,}\s+[a-zA-Z]{2,}/) && Valid.cardholderName(value).isValid);
    return !!(value || '').match(/[a-zA-Z]{2,}\s+[a-zA-Z]{2,}/) && Valid.cardholderName(value).isValid;
  }

  return (key, newValue, oldValue, changes, content) => {
    switch (type) {
      case 'cardholderName':
        return _validateCardholderName(newValue);
    }
  };
}
