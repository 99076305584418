import { A } from '@ember/array';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class TestMenuComponent extends Component {
  @service user;
  @service layout;
  @service sessionData;
  @service snackbar;
  @service router;

  @tracked search = '';
  @tracked top = 10;
  @tracked skip = 0;
  @tracked moreOptions = [];

  @action
  nextPage() {
    this.skip += this.top;
  }

  @action
  previousPage() {
    this.skip -= this.top;
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = value;
  }

  @tracked
  open = false;

  @action
  openDropdown() {
    this.open = true;
  }
  @action
  closeSideSheet() {
    this.open = false;
  }

  @tracked
  adminMenuTabActivated = 0;

  @action
  setAdminMenuTabActivated({ detail }) {
    this.adminMenuTabActivated = detail?.index;
  }

  @tracked
  chips = A([{ text: 'Assinaturas' }, { text: 'Contas' }]);

  @tracked
  choice = this.chips.objectAt(0);

  @action
  change(choice) {
    this.choice = choice;
  }

  @action
  async impersonate({ accountId, subscriptionId }) {
    await this.sessionData.impersonate(accountId, subscriptionId);
  }

  @task({ drop: true })
  *setStatus(model) {
    return yield model.setStatus.perform(model.status == 'Active' ? 'deactivate' : 'activate');
  }

  @task({ drop: true })
  *setEnvironment(model) {
    return yield model.setEnvironment.perform(model.environment == 'Sandbox' ? 'production' : 'development');
  }
}
