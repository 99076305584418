/* global mdc */

import Component from 'ember-cli-mdc-select/components/mdc-select';
import { isPresent } from '@ember/utils';
import { guidFor } from '@ember/object/internals';

export default class NfeSelectComponent extends Component {
  doPrepareElement(element) {
    const { value: option } = this.args;

    let guid = guidFor(this);
    this.labelId = `${guid}__label`;
    this.selectedTextId = `${guid}__selected-text`;
    this.helperId = `${guid}__helper-text`;

    if (isPresent(option)) {
      // We need to pre-select the option.
      let value = this.valueOf(option);
      let text = this.textOf(option);

      if (isPresent(value) && isPresent(text)) {
        const listItem = element.querySelector (`.mdc-deprecated-list-item[data-value="${value}"]`);

        if (isPresent(listItem)) {
          listItem.classList.add('mdc-deprecated-list-item--selected');
        }

        const textElement = element.querySelector ('.mdc-select__selected-text');
        textElement.value = text;
      }
    }
  }
}
