import BaseAuthenticatedRoute from '../base-authenticated';
import { service } from '@ember/service';

export default class AccountIndexRoute extends BaseAuthenticatedRoute {
  @service store;

  model({ accountId }) {
    if (!accountId || !accountId.startsWith('acc_')) return null;

    return this.store.findRecord('account', accountId);
  }
}
