define("ember-cli-custom-properties/initializers/custom-properties", ["exports", "@ember/component", "ember-cli-custom-properties/mixins/custom-properties"], function (_exports, _component, _customProperties) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(/* application */
  ) {
    _component.default.reopen(_customProperties.default);
  }
  var _default = _exports.default = {
    initialize
  };
});