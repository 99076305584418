import Model, { attr, hasMany } from '@ember-data/model';

import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class SubscriptionModel extends Model {
  @service store;
  @service snackbar;

  @attr('date') createdOn;
  @attr('date') modifiedOn;
  @attr('string') name;
  @attr('boolean', { defaultValue: false }) isBillable;
  @attr('string') status;
  @attr('string') environment;
  @attr('string') spendingLimit;
  @attr account;
  @attr agreement;
  @hasMany('webhook') webhooks;

  get accountId() {
    return this.account?.id;
  }

  get subscriptionId() {
    return this.id;
  }

  get platformSubscriptionId() {
    return this.id.replace(/sub_/, '');
  }

  get isActive() {
    return this.status.toLowerCase() === 'active';
  }

  get isSandbox() {
    return this.environment.toLowerCase() === 'sandbox';
  }

  @task({ drop: true })
  *setEnvironment(environment) {
    return yield this.store
      .adapterFor('user/subscription')
      .setEnvironment(this, environment)
      .then((data) => {
        this.environment = data?.accounts?.environment == 'Development' ? 'Sandbox' : data?.accounts?.environment;
        this.snackbar.show({ message: 'Ambiente da conta alterado com sucesso!' });
        return data;
      });
  }

  @task({ drop: true })
  *setStatus(newStatus) {
    return yield this.store
      .adapterFor('user/subscription')
      [newStatus](this)
      .then((data) => {
        this.status = data?.accounts?.status;
        this.snackbar.show({ message: `Conta ${this.status == 'Active' ? 'ativada' : 'desativada'} com sucesso!` });
        return data;
      });
  }
}
