define("ember-svg-jar/inlined/logo-mastercard-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1470:12591)\"><path fill=\"#F4F4F4\" d=\"M0-6h36v36H0z\"/><circle cx=\"14.755\" cy=\"11.754\" fill=\"#F12309\" r=\"5.155\"/><circle cx=\"21.245\" cy=\"11.754\" fill=\"#FB9F01\" r=\"5.155\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M18 7.75a5.144 5.144 0 011.91 4.005c0 1.616-.745 3.06-1.91 4.004a5.145 5.145 0 01-1.91-4.004c0-1.617.745-3.06 1.91-4.005z\" fill=\"#FF6100\"/><path opacity=\".01\" fill=\"red\" d=\"M9 3h18v18H9z\"/></g><defs><clipPath id=\"clip0_1470:12591\"><path d=\"M0 2a2 2 0 012-2h32a2 2 0 012 2v20a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});