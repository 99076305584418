import OAuth2ImplicitGrant from 'nfeio-ember-implicit-auth/authenticators/oauth2-implicit-grant';
import { debug } from '@ember/debug';
import { service } from '@ember/service';

export default class ApplicationAuth extends OAuth2ImplicitGrant {
  @service router;
  @service sessionData;
  @service session;
  @service user;

  async invalidate() {
    debug(`${this.authenticator}: invalidate [application]`);
    super.invalidate(...arguments);

    return window.location.reload(true);
  }
}
