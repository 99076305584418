import Service, { service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';

import { storageFor } from 'ember-local-storage';
import { task } from 'ember-concurrency';

export default class SessionDataService extends Service {
  @service session;
  @service router;
  @service store;
  @service snackbar;
  @storageFor('session') sessionStorage;
  @service provider;

  async impersonate(accountId, subscriptionId) {
    if (isEmpty(accountId)) return null;

    const account = await this.store.findRecord('account', accountId);

    if (!account) return null;

    const subscriptionsByAccount = await this.store.query('subscription', {
      accountId,
    });

    const toSubscription = isPresent(subscriptionId)
      ? subscriptionsByAccount.find((sub) => sub.id == subscriptionId)
      : subscriptionsByAccount.firstObject;

    this.sessionStorage.set('subscription.current', toSubscription.serialize({ includeId: true }));

    this.sessionStorage.set(
      'subscription.all',
      subscriptionsByAccount.map((sub) => sub.serialize({ includeId: true }))
    );

    // If the impersonated account is in the account.all list, the user is not using a client account
    const isImpersonated = !this.sessionStorage
      .get('account.all')
      .some((acc) => acc.parentId === account.accountNumber);

    this.sessionStorage.set('impersonated', isImpersonated);

    this.sessionStorage.set('account.current', account.serialize({ includeId: true }));

    await this.provider.setup(toSubscription.id);

    await this.router.transitionTo('accounts.index', account.accountNumber);

    this.snackbar.show({ message: `Utilizando como: ${subscriptionId ? toSubscription.name : account.name}!` });


    return account;
  }

  async setCurrentSubscription(subscription) {
    if (isEmpty(subscription)) return null;

    const account = await this.store.findRecord('account', subscription?.account?.id);
    const subscriptionsByAccount = await this.store.query('subscription', {
      accountId: subscription?.account?.id,
    });

    this.sessionStorage.set(
      'subscription.all',
      subscriptionsByAccount.map((sub) => sub.serialize({ includeId: true }))
    );

    this.sessionStorage.set('account.current', account.serialize({ includeId: true }));

    this.sessionStorage.set('subscription.current', subscription);

    return subscription;
  }

  get subscription() {
    if (!isPresent(this.sessionStorage.get('subscription.current'))) {
      this.router.transitionTo('login');
    }
    return this.sessionStorage.get('subscription');
  }

  get account() {
    if (!isPresent(this.sessionStorage.get('account.current'))) {
      this.router.transitionTo('login');
    }
    return this.sessionStorage.get('account');
  }

  set accounts(accounts) {
    this.sessionStorage.set('account.all', accounts.map((acc) => acc.serialize({ includeId: true })));
  }

  get isImpersonated() {
    return this.sessionStorage.get('impersonated');
  }

  async getCurrentAccount() {
    if (!this.session.isAuthenticated) return null;
    if (this.session.isAuthenticated && isPresent(this.sessionStorage.get('account.current'))) {

      let currentSubscription = this.sessionStorage.get('subscription.current');

      if (isEmpty(currentSubscription.id)) {
        currentSubscription = await this.setupSubscription(this.sessionStorage.get('account.current'));
      }

      if (this.provider.isEmpty()) {
        await this.provider.setup(currentSubscription.id);
      };

      return this.sessionStorage.get('account.current');
    }

    const subscriptionsByUser = await this.store.findAll('user/subscription');

    // Remove subscriptions without parentId
    const validSubscriptions = subscriptionsByUser.filter((sub) => isPresent(sub.parentId));

    if (isEmpty(validSubscriptions)) return null;

    this.sessionStorage.set(
      'account.all',
      validSubscriptions.map((sub) => sub.serialize({ includeId: true }))
    );

    // Check if the user has an active subscription in production
    const activeSubscription =
      validSubscriptions.find(({ status, environment }) => status.toLowerCase() === 'active' && environment.toLowerCase() === 'production') ||
      validSubscriptions.find(({ status }) => status.toLowerCase() === 'active') ||
      validSubscriptions.firstObject;

    const account = await this.store.findRecord('account', activeSubscription.parentId);

    this.sessionStorage.set('account.current', account.serialize({ includeId: true }));

    await this.setupSubscription(account);

    return account;
  }

  async setupSubscription(account) {

    const subscriptionsByAccount = await this.store.query('subscription', {
      accountId: account.accountNumber,
    });

    const currentSubscription = subscriptionsByAccount.firstObject;

    // Set default subscription as first position of array
    this.sessionStorage.set('subscription.current', currentSubscription.serialize({ includeId: true }));

    this.sessionStorage.set(
      'subscription.all',
      subscriptionsByAccount.map((sub) => sub.serialize({ includeId: true }))
    );

    await this.provider.setup(currentSubscription.id);

    return currentSubscription
  }

  @task({ drop: true })
  *handleCurrentAccount() {
    return yield this.getCurrentAccount();
  }
}
