import BaseAuthenticatedRoute from 'accounts-app/routes/base-authenticated';
import { service } from '@ember/service';

export default class AccountOrdersRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId }) {
    accountId = accountId ?? this.paramsFor('accounts.orders').accountId;
    return await this.store.findRecord('account', accountId);
  }
}
