import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class OrderAdapter extends BaseOdataAdapter {
  urlForFindRecord(orderId, modelName, snapshot) {
    const { accountId } = snapshot.adapterOptions;

    let url = `${this.host}/accounts('${accountId}')/orders(${orderId})?$expand=items`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/${accountId}/orders/${orderId}?$expand=items`;
    }

    return url;
  }

  query(store, type, query, recordArray, adapterOptions) {
    query.$orderby = 'effectiveOn desc';

    const buildURL = this.buildURL(type.modelName, null, null, 'query', query);
    const url = this.getODataFullUrl(buildURL, query);
    return this.ajax(url);
  }

  urlForQuery({ accountId }, modelName) {
    let url = `${this.host}/accounts('${accountId}')/orders`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/orders`;
    }

    return url;
  }

  approveOrder(accountId, orderId, data) {
    const url = `${this.host}/Accounts('${accountId}')/orders(${orderId})/api.approve`;

    return this.ajax(url, 'POST', {
      data: {
        details: {
          description: data.description,
          purchaseOrderNumber: data.purchaseOrderNumber,
          approver: {
            fullname: data.buyerName,
            emailAddress1: data.buyerEmailAddress,
          },
        },
      },
    });
  }

  refuseOrder(accountId, orderId, data) {
    const url = `${this.host}/Accounts('${accountId}')/orders(${orderId})/api.refuse`;

    return this.ajax(url, 'POST', {
      data: {
        details: {
          description: data.description,
          purchaseOrderNumber: data.purchaseOrderNumber,
          approver: {
            fullname: data.buyerName,
            emailAddress1: data.buyerEmailAddress,
          },
        },
      },
    });
  }

  downloadOrder(accountId, orderId) {
    const url = `${this.host}/Accounts('${accountId}')/orders(${orderId})/api.exportpdf`;
    return window.open(url);
  }
}
