define("ember-cli-mdc-tabs/components/mdc-tabs", ["exports", "@ember/component", "ember-cli-mdc-base/component", "ember-cli-mdc-base/listener", "@ember/utils", "@ember/debug", "@ember/object", "@material/tab-bar", "@ember/template-factory"], function (_exports, _component, _component2, _listener, _utils, _debug, _object, _tabBar, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _class;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mdc-tabs"
    {{did-insert this.didInsert}}
    {{did-update this.activateTab @activeTab}} ...attributes>
    {{yield}}
  </div>
  
  */
  {
    "id": "ipH8idO3",
    "block": "[[[11,0],[24,0,\"mdc-tabs\"],[17,1],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[4,[38,1],[[30,0,[\"activateTab\"]],[30,2]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"&attrs\",\"@activeTab\",\"&default\"],false,[\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "ember-cli-mdc-tabs/components/mdc-tabs.hbs",
    "isStrictMode": false
  });
  function noOp() {}
  let MdcTabsComponent = _exports.default = (_dec = (0, _listener.default)('MDCTabBar:activated'), _class = class MdcTabsComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      /// The tab bar element for the tabs.
      _defineProperty(this, "_tabPanels", null);
      _defineProperty(this, "_currentActiveTab", 0);
    }
    doPrepareElement(element) {
      let tabBarElement = element.querySelector('.mdc-tab-bar');
      (true && !((0, _utils.isPresent)(tabBarElement)) && (0, _debug.assert)('The mdc-tabs component must contain a mdc-tab-bar child element.', (0, _utils.isPresent)(tabBarElement))); // Cache the tab panels.
      this._tabPanels = element.querySelectorAll('.mdc-tab-panel');

      // Initialize the active tab.
      this._initActiveTab(element);
    }
    doCreateComponent(element) {
      return new _tabBar.MDCTabBar(element);
    }
    doInitComponent(component) {
      // Verify the number of tabs equals the number of panels.
      (true && !(component.tabList.length === this._tabPanels.length) && (0, _debug.assert)('The number of mdc-tab elements must equal the number of mdc-tab-panel elements.', component.tabList.length === this._tabPanels.length));
    }
    get activeTab() {
      return this.args.activeTab || 0;
    }
    _initActiveTab(element) {
      this._activateTab(element, this.activeTab);
    }
    activated(ev) {
      const {
        detail: {
          index
        }
      } = ev;

      // Activate the selected panel.
      this._activateTabPanel(index);
      this._currentActiveTab = index;

      // Send the appropriate notification.
      this.didActivate(ev);
      (this.args.activated || noOp)(index);
    }
    activateTab(element, [activeTab]) {
      this._activateTab(element, activeTab);
    }
    didActivate(ev) {}
    _activateTab(element, activeTab) {
      let tabs = element.querySelectorAll('.mdc-tab');
      let tab;
      if (activeTab !== this._currentActiveTab) {
        // We need to the active state from the default tab (i.e., tab 0).
        tab = tabs.item(this._currentActiveTab);
        tab.classList.remove('mdc-tab--active');
        tab.querySelector('.mdc-tab-indicator').classList.remove('mdc-tab-indicator--active');
      }

      // Add the active state to the tab.
      tab = tabs.item(activeTab);
      tab.classList.add('mdc-tab--active');

      // Add the active state to the tab indicator.
      let tabIndicator = tab.querySelector('.mdc-tab-indicator');
      tabIndicator.classList.add('mdc-tab-indicator--active');

      // Add the active state to the tab panel.
      this._activateTabPanel(activeTab);

      // Save the active tab index.
      this._currentActiveTab = activeTab;
    }
    _activateTabPanel(index) {
      if ((0, _utils.isPresent)(this._currentActiveTab)) {
        const tabPanel = this._tabPanels.item(this._currentActiveTab);
        tabPanel.dispatchEvent(new CustomEvent('MdcTabPanel:deactivate'));
      }
      const tabPanel = this._tabPanels.item(index);
      tabPanel.dispatchEvent(new CustomEvent('MdcTabPanel:activate'));
    }
  }, _applyDecoratedDescriptor(_class.prototype, "activated", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "activated"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "activateTab", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "activateTab"), _class.prototype), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MdcTabsComponent);
});