import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import arg from '../../utils/decorators/arg';

export default class PaymentCheckoutComponent extends Component {
  @service payment;
  @service('payment-method') paymentMethodService;
  @service snackbar;

  @arg paymentId = null;

  @tracked paymentMethod = null;
  @tracked chargeResponse;
  @tracked step = 'selectPaymentMethod';

  async _isCreditCardSaved() {
    if (this.paymentMethod.isNew) {
      const result = await this.paymentMethodService.save(this.paymentMethod);
      this.snackbar.show({ message: 'O novo método de pagamento foi salvo com sucesso!' });

      if (result instanceof Array) {
        this.chargeResponse = result;
        this.step = 'refused';
        return false;
      }
    }

    return true;
  }

  @action
  async processPaymentMethod() {
    if (this.paymentMethod.type === 'CreditCard') {
      this.step = 'runningCreditCard';

      const shouldCharge = await this._isCreditCardSaved();

      if (shouldCharge) this.charge.perform(this.paymentId, this.paymentMethod);

      return;
    }

    if (this.paymentMethod.type === 'BankSlip') {
      this.step = 'runningBankSlip';
      this.charge.perform(this.paymentId, this.paymentMethod);
      return;
    }

    if (this.paymentMethod.type === 'InstantPayment') {
      this.step = 'runningPix';
      this.charge.perform(this.paymentId, this.paymentMethod);
      return;
    }

    if (this.paymentMethod.type === 'WireTransfer') {
      this.step = 'WireTransfer';
      return;
    }
  }

  @task
  *charge(paymentId, paymentMethod) {
    const response = yield this.payment.pay(paymentId, paymentMethod);

    this.chargeResponse = response;

    if (response.status === 'success') {
      this.step = this.paymentMethod.type;
      if (this.paymentMethod.type === 'CreditCard') {
        this.step = 'approved';
      }
    } else {
      this.step = 'refused';
    }
    return;
  }

  @action
  paymentMethodSelected(paymentMethod) {
    this.paymentMethod = paymentMethod;
    this.isPaymentMethodSelected = false;
  }

  @action
  showDialogCreditCard() {
    this.step = 'creditCard';
  }

  @action
  cancelAddCreditCard() {
    this.step = 'selectPaymentMethod';
  }
}
