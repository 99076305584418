import BaseAuthenticatedRoute from '../base-authenticated';
import { service } from '@ember/service';

export default class MgmtSubscriptionsIndexRoute extends BaseAuthenticatedRoute {
  queryParams = {
    search: { replace: true },
    top: { replace: true },
    skip: { replace: true },
  };

  @service store;
  @service router;

  async beforeModel() {
    if (this.user.isAdmin === false) return this.router.transitionTo('index');
  }
}
