import Helper from '@ember/component/helper';
import { camelize } from '@ember/string';
import { service } from '@ember/service';

export default class ProviderHelper extends Helper {
  @service provider;

  _observedProvider = null;

  compute([provider]) {
    if (this._observedProvider) {
      this.provider.removeObserver(this._observedProvider, this, 'recompute');
    }

    this._observedProvider = camelize(provider);
    // eslint-disable-next-line ember/no-observers
    this.provider.addObserver(this._observedProvider, this, 'recompute');

    return this.provider.isEnabled(provider);
  }

  willDestroy() {
    super.willDestroy(...arguments);

    if (this._observedProvider) {
      this.provider.removeObserver(this._observedProvider, this, 'recompute');
    }
  }
}
