import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AccountsSubscriptionsListController extends Controller {
  @service router;
  @service store;
  @service user;
  @service intl;
  @service sessionData;

  queryParams = ['top', 'skip', 'filter'];

  @tracked filter = '';
  @tracked top = 10;
  @tracked skip = 0;
  @tracked moreOptions = [];

  get headers() {
    let defaultHeaders = A([
      this.intl.t('name'),
      this.intl.t('subscriptions.table.agreement'),
      this.intl.t('subscriptions.billable'),
      this.intl.t('subscriptions.spendingLimit'),
      this.intl.t('subscriptions.environment'),
      this.intl.t('status'),
      this.intl.t('subscriptions.createdOn'),
      {
        name: this.intl.t('actions'),
        size: '100px',
      },
    ]);

    return defaultHeaders;
  }

  @action
  onSearch(ev) {
    this.filter = ev.target.value;
  }

  @action
  nextPage() {
    this.skip += this.top;
  }

  @action
  previousPage() {
    this.skip -= this.top;
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = value;
  }

  @action
  viewUsage(subID) {
    let date = new Date();
    this.router.transitionTo('accounts.subscriptions.usage', subID, {
      queryParams: { date: `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, 0)}` },
    });
  }

  @action
  goToImpersonate(subID) {
    window.open('https://app.nfe.io/accounts/' + subID.replace(/sub_/, '') + '/impersonate/');
  }

  @action
  updateOptions(...args) {
    // args.forEach((el) => {
    //   // console.log(el);
    // });
  }
}
