import { Response } from 'ember-cli-mirage';

export default function () {
  this.urlPrefix = 'https://account.api.nfe.io';
  // this.namespace = '';    // make this `/api`, for example, if your API is namespaced
  // this.timing = 400;      // delay for each request, automatically set to 0 during testing

  this.get('/payments/:id', ({ payments }, request) => {
    let paymentId = request.params.id;
    return payments.find(paymentId).attrs; // return attrs because of odata
  });

  this.post('/payments/:id/Api.Charge', ({ payments }, request) => {
    let { type } = JSON.parse(request.requestBody);
    let paymentId = request.params.id;

    if (type === 'CreditCard') {
      if (paymentId === '1') {
        return new Response(
          200,
          {},
          {
            message: 'Transação negada',
            status: 'unauthorized',
            statusCode: 'FA',
          }
        );
      }

      if (paymentId === '2') {
        return new Response(
          200,
          {},
          {
            message: 'Limite máximo de transações no mesmo dia para a mesma fatura excedido',
            status: 'failed',
          }
        );
      }

      if (paymentId === '3') {
        return new Response(500);
      }

      return new Response(200, {}, { message: 'Autorizado', status: 'captured' });
    }

    if (type === 'BankSlip') {
      if (paymentId === '1') {
        return new Response(
          200,
          {},
          {
            code: '23793.38128 60009.299664 92000.050804 5 88200000000100',
            status: 'Success',
          }
        );
      } else {
        return new Response(500);
      }
    }

    if (type === 'InstantPayment') {
      if (paymentId === '1') {
        return new Response(
          200,
          {},
          {
            status: 'Success',
            url: 'https://qr.iugu.com/public/v1/qr_codes/image/EA3BE4BD7E344F22A61A8A5398B3543B',
            code: '00020101021226880014br.gov.bcb.pix2566qr.iugu.com/public/payload/v2/fcfbbcb9-ae6d-42cf-92b5-502358d437d152040000530398654041.005802BR5925GABRIEL ALEJANDRO DA SILV6009SAO PAULO62070503***6304A2D9',
          }
        );
      } else {
        return new Response(500);
      }
    }
  });

  this.get('/payments/:id/Api.PaymentMethods', () => {
    return {
      value: [
        {
          id: 'd22f9bd7-9e29-eb11-a813-000d3ac1a096',
          accountId: null,
          isGlobal: false,
          type: 'CreditCard',
          wireTransfer: null,
          bankSlip: null,
          instantPayment: null,
          details: {
            name: 'TESTE TESTE',
            email: null,
            telephone: null,
            address: null,
          },
          card: {
            number: '************9441',
            securityCode: null,
            expirationMonth: 9,
            expirationYear: 2021,
            brand: 'Mastercard',
            funding: 'Credit',
            country: null,
            thumbprint: null,
            gatewayId: null,
          },
        },
        {
          id: '26422084-3806-eb11-a813-000d3ac05981',
          accountId: null,
          isGlobal: true,
          type: 'BankSlip',
          wireTransfer: null,
          bankSlip: {
            issuerName: 'iugu',
            issuerCode: 'iugu',
          },
          instantPayment: null,
          details: null,
          card: null,
        },
        {
          id: '60866d7b-17ad-eb11-8236-00224836f8fe',
          accountId: null,
          isGlobal: true,
          type: 'InstantPayment',
          wireTransfer: null,
          bankSlip: null,
          instantPayment: {
            bankName: 'iugu',
            bankCode: '401',
          },
          details: null,
          card: null,
        },
      ],
    };
  });

  this.get('/accounts/:id', ({ accounts }, request) => {
    const id = request.params.id;
    return accounts.find(id).attrs;
  });

  this.put('/accounts/:id', function ({ accounts }, request) {
    const account = accounts.find(request.params.id);
    const payload = JSON.parse(request.requestBody);
    return account.update(payload);
  });

  this.get('/accounts/:id/invoices/:id', () => {
    return {
      id: '5eb82e42-600b-ec11-b6e5-00224835d788',
      createdOn: '2021-09-01T20:07:46Z',
      modifiedOn: '2021-09-13T22:34:49Z',
      state: '2',
      status: 'Paid',
      invoiceNumber: 'INV-210909008-P9T7P7',
      name: 'INV-210909008-P9T7P7',
      accountId: 'acc_5e5b1ba598ff49e8b817ee8d3f4fb051',
      agreementId: '8e83b816-2bf9-eb11-94ef-000d3ac194b9',
      orderId: '279a50b1-36f9-eb11-94ee-000d3ac19264',
      description: null,
      effectiveOn: '2021-08-31T23:59:59Z',
      dueOn: '2021-09-12T00:00:00Z',
      discountAmount: 0,
      discountPercentage: 0,
      totalItemsAmount: 95,
      totalItemsDiscountAmount: 0,
      totalTax: 0,
      totalDiscountAmount: 0,
      totalAmount: 95,
      items: [
        {
          id: '3145d247-600b-ec11-b6e5-0022483762dc',
          item: 0,
          number: 'DF-NFSE-001',
          name: 'Notas Fiscais de Servi\u00e7o (NFS-e) - Emiss\u00e3o',
          description: 'ServiceInvoice.Issued',
          quantity: 100,
          unitPrice: 0.95,
          discountAmount: 0,
          amount: 95,
          tax: 0,
          subscription: {
            id: '0132b31c-2bf9-eb11-94ef-000d3ac194b9',
            name: 'Assinatura PunkMetrics',
          },
        },
        {
          id: 'f044d247-600b-ec11-b6e5-0022483762dc',
          item: 0,
          number: 'DF-NFSE-003',
          name: 'Notas Fiscais de Servi\u00e7o (NFS-e) - Download',
          description: 'ServiceInvoice.Download',
          quantity: 353,
          unitPrice: 0,
          discountAmount: 0,
          amount: 0,
          tax: 0,
          subscription: {
            id: '0132b31c-2bf9-eb11-94ef-000d3ac194b9',
            name: 'Assinatura PunkMetrics',
          },
        },
      ],
    };
  });

  this.get('https://address.api.nfe.io/v2/addresses/:id', function () {
    return {
      address: {
        city: { code: '3550308', name: 'São Paulo' },
        country: 'BRA',
        postalCode: '01452-922',
        number: '123',
        street: 'Brigadeiro Faria Lima',
        streetSuffix: 'Avenida',
        additionalInformation: 'Edifício Teste',
        district: 'Jardim Teste',
        state: 'SP',
      },
    };
  });

  this.passthrough('https://opendata.blob.core.windows.net/**');
}
