import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NfeDropdownComponent extends Component {
  @tracked openMenu = false;

  constructor() {
    super(...arguments);
  }

  @action
  openUpdate() {
    this.openMenu = !!this.args.open;
  }
}
