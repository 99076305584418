import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class LoadersPaymentMethodsComponent extends Component {
  @service store;

  @arg accountId;

  @tracked groupedByPreference = {};
  @tracked paymentMethods = [];

  @tracked error = false;
  @tracked refresh = false;

  @task({ drop: true })
  *getPaymentMethods() {
    try {
      this.groupedByPreference = this.groupPaymentMethodsCardByPreference(
        yield this.store.query('payment-method', {
          accountId: this.accountId,
          $filter: `type eq 'CreditCard'`,
        })
      );
      return;
    } catch (error) {
      this.error = true;
    }
  }

  groupPaymentMethodsCardByPreference(paymentMethods) {
    this.paymentMethods = paymentMethods;
    this.groupedByPreference = [];
    paymentMethods.map((paymentMethod) => {
      let preference = paymentMethod.card.preference && paymentMethod.card.preference.toLowerCase();

      if (!isPresent(this.groupedByPreference[preference])) this.groupedByPreference[preference] = [];

      this.groupedByPreference[preference].push(paymentMethod);
    });
    return this.groupedByPreference;
  }
}
