import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { isEmpty } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class UsersController extends Controller {
  @service store;
  @service intl;
  @service snackbar;
  @service sessionData;

  @tracked dialogNewOpen = false;
  @tracked dialogDeleteOpen = false;
  @tracked newUser = null;
  @tracked deleteUser = null;
  @tracked refresh = true;

  @tracked sortDesc = A(['email:asc'])
  @tracked search = "";

  @action
  searchUsers(user) {
    if (isEmpty(user) || isEmpty(this.search)) return user;

    return user.includes(this.search);
  }

  @task({ drop: true })
  *confirmDeleteUser(model) {
    let message = '';
    try {
      yield model.deleteRecord();
      yield model.save({
        adapterOptions: {
          subscriptionId: this.model.subscriptionId.replace('sub_', ''),
        },
      });
      message = this.intl.t('users.delete.success');
    } catch (error) {
      message = this.intl.t('users.delete.error');

      if (!error || isEmpty(error.description)) message = error.message;

      if (error?.description.includes("'email' is not admin"))
        message = this.intl.t('users.new.confirmation.userIsNotAdmin');

      if (error?.description.includes("'email' does not contain account"))
        message = this.intl.t('users.new.confirmation.userWithoutAccount');
    }

    this.snackbar.show({ message });
    this.closeDeleteDialogUser();
    this.deleteUser = null;
    this.refresh = !this.refresh;
  }

  @task({ drop: true })
  *inviteUser(user) {
    try {
      yield user.save({
        adapterOptions: {
          subscriptionId: this.model.subscriptionId.replace('sub_', ''),
        },
      });
      this.snackbar.show({ message: this.intl.t('users.new.confirmation.success') });
    } catch (error) {
      this.snackbar.show({ message: this.intl.t('users.new.confirmation.error') });
    }
    this.closeNewDialogUser();
    this.newUser = null;
    this.refresh = !this.refresh;
  }

  @action
  showNewDialogUser() {
    this.newUser = this.store.createRecord('subscription/user');
    this.dialogNewOpen = true;
  }

  closeNewDialogUser = () => {
    this.dialogNewOpen = false;
    this.newUser = null;
  };

  @action
  showDeleteDialogUser({ model }) {
    this.deleteUser = model;
    this.dialogDeleteOpen = true;
  }

  closeDeleteDialogUser = () => {
    this.dialogDeleteOpen = false;
    this.deleteUser = null;
  };

  @action
  refreshPage() {
    window.location.reload(true);
  }
}
