define("ember-svg-jar/inlined/logo-dinersclub-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1470:12594)\"><path fill=\"#0078C2\" d=\"M0-6h36v36H0z\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M22.43 12.04c.026-3.285-2.646-6.09-6.068-6.106a6.089 6.089 0 00-6.143 6.1 6.092 6.092 0 006.132 6.114c3.4-.01 6.105-2.797 6.08-6.108m-2.633 6.667h-3.827c-.05-.011-.098-.03-.148-.032a6.177 6.177 0 01-1.532-.286 6.63 6.63 0 01-3.095-2.044 6.392 6.392 0 01-1.537-3.49c-.018-.147-.04-.294-.059-.442v-.616c.01-.054.024-.108.032-.162.054-.384.079-.775.165-1.151.329-1.439 1.056-2.641 2.205-3.577 1.025-.834 2.203-1.304 3.515-1.448.151-.016.302-.039.454-.059h3.827c.045.011.09.03.134.033.562.032 1.11.134 1.646.302 1.345.42 2.483 1.157 3.372 2.256a6.363 6.363 0 011.419 3.591 5.996 5.996 0 01-.122 1.821c-.355 1.542-1.194 2.777-2.424 3.75A6.961 6.961 0 0120.2 18.65c-.134.014-.268.037-.402.056\" fill=\"#fff\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M14.925 15.594c-1.155-.376-2.363-1.674-2.436-3.36-.092-2.111 1.415-3.427 2.435-3.749v7.109zM17.736 15.593v-7.11c1.219.419 2.522 1.812 2.433 3.745-.08 1.739-1.307 2.992-2.433 3.365\" fill=\"#fff\"/><path opacity=\".01\" fill=\"#FF0303\" d=\"M9 3h18v18H9z\"/></g><defs><clipPath id=\"clip0_1470:12594\"><path d=\"M0 2a2 2 0 012-2h32a2 2 0 012 2v20a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});