import ApplicationAdapter from './application';
import ENV from 'accounts-app/config/environment';
import { service } from '@ember/service';

export default class LegalEntityAdapter extends ApplicationAdapter {
  @service sessionData;
  @service session;
  @service accessToken;

  host = ENV.APP.API_NFSE_URL;
  namespace = 'LegalEntities';

  queryRecord(store, model, { queryParams }) {
    let url = `${this.host}/${this.namespace}/Basicinfo/taxNumber/${queryParams.federalTaxNumber.replace(/\D/g, '')}`;
    return super.ajax(url, 'GET');
  }
}
