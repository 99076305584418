import BaseAuthenticatedRoute from './base-authenticated';
import { inject as service } from '@ember/service';

export default class CreateAccountRoute extends BaseAuthenticatedRoute {
  @service drawer;
  @service sessionData;
  @service store;

  titleToken = 'Criar conta';

  async model() {
    const currentUser = await this.user.getCurrent();
    const currentAccount = await this.sessionData.account.current;
    if (!currentAccount) {
      this.drawer.disable();
    }

    return this.store.createRecord('account', {
      emailAddress1: currentUser.email,
      telephone1: currentUser.phone_number,
    });
  }
}
