import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class PaymentAdapter extends BaseOdataAdapter {
  urlForFindRecord(id, modelName, snapshot) {
    let url = `${this.host}/Payments(${id})?$expand=PaymentMethod`;

    if (ENV.environment === 'test') {
      url = `${this.host}/payments/${id}?$expand=PaymentMethod`;
    }

    return url;
  }

  async getPaymentMethods(paymentId) {
    let url = `${this.host}/Payments(${paymentId})/Api.PaymentMethods`;

    if (ENV.environment === 'test') {
      url = `${this.host}/payments/${paymentId}/Api.PaymentMethods`;
    }

    const response = await this.ajax(url);
    return response.value;
  }

  async getPaymentUrl(accountId, invoiceId) {
    const url = `${this.host}/Accounts('${accountId}')/invoices(${invoiceId})/Api.GetPaymentUrl`;
    const raw = await this.ajax(url);
    return window.open(raw.url);
  }

  pay(paymentId, data) {
    let url = `${this.host}/Payments(${paymentId})/Api.Charge`;

    if (ENV.environment === 'test') {
      url = `${this.host}/payments/${paymentId}/Api.Charge`;
    }

    return this.ajax(url, 'POST', { data });
  }

  handleResponse(status, headers, payload) {
    if (status === 400) {
      return payload;
    }

    return super.handleResponse(status, headers, payload);
  }
}
