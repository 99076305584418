define("ember-svg-jar/inlined/logo-dinersclub-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#F4F4F4\"/><path fill=\"#0078C2\" fill-rule=\"evenodd\" d=\"M49.846 40.087c.056-7.299-5.88-13.531-13.486-13.57-7.514-.037-13.643 5.961-13.65 13.56-.008 7.62 6.143 13.608 13.625 13.585 7.556-.022 13.568-6.216 13.51-13.575m-5.85 14.816H35.49c-.11-.025-.218-.066-.328-.072a13.717 13.717 0 01-3.403-.634 14.734 14.734 0 01-6.879-4.542c-1.92-2.238-3.073-4.82-3.416-7.755-.039-.328-.087-.655-.13-.983v-1.37c.023-.12.053-.239.07-.36.12-.853.175-1.72.367-2.557.731-3.198 2.347-5.87 4.9-7.948 2.278-1.855 4.896-2.899 7.811-3.218.337-.037.672-.087 1.008-.131h8.506c.1.025.198.066.298.072 1.247.07 2.466.299 3.657.672 2.99.935 5.518 2.571 7.493 5.014 1.882 2.326 2.922 4.992 3.154 7.98.106 1.365.035 2.714-.271 4.047-.789 3.425-2.653 6.17-5.388 8.331-2.362 1.867-5.043 3.002-8.05 3.33-.299.032-.595.082-.893.124\" clip-rule=\"evenodd\"/><path fill=\"#0078C2\" fill-rule=\"evenodd\" d=\"M33.166 47.986c-2.565-.835-5.251-3.72-5.413-7.467-.204-4.69 3.145-7.614 5.413-8.33v15.797zm6.248 0v-15.8c2.707.93 5.605 4.026 5.406 8.322-.178 3.863-2.904 6.65-5.406 7.478\" clip-rule=\"evenodd\"/><path fill=\"#FF0303\" d=\"M20 20h40v40H20z\" opacity=\".01\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});