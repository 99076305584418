import Model, { attr } from '@ember-data/model';
import Enums from '../enums/accesskeys-info';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ApikeyClientModel extends Model {
  @service store;
  @service snackbar;

  @attr('boolean') enabled;
  @attr('string') clientId;
  @attr('string') clientName;
  @attr('string') description;

  getAccessKeyInfo() {
    return Enums.accessKeysInfo.filter((item) => {
      let value = this.description;
      return value === item.id;
    })[0];
  }

  @task({ drop: true })
  *setStatus(newStatus) {
    return yield this.store
      .adapterFor('apikey/client')
      [newStatus](this)
      .then((data) => {
        this.data = data?.accounts?.status;
        this.snackbar.show({ message: `Chave de acesso ${this.enabled ? 'ativada' : 'desativada'} com sucesso!` });
        return data;
      });
  }
}
