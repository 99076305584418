import RESTSerializer from '@ember-data/serializer/rest';

export default class UserSubscriptionSerializer extends RESTSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload.accounts) {
      payload = { [primaryModelClass.modelName]: payload.accounts };
    } else {
      payload = { [primaryModelClass.modelName]: payload };
    }
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
