import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

import arg from '../../utils/decorators/arg';

export default class SubscriptionUserAddDialog extends Component {
  @arg callbackSuccess;
  @arg callbackCancel;
  @arg user;

  @tracked isValid = false;

  @action
  close() {
    this.callbackCancel();
  }
  @action
  validity(valid) {
    this.isValid = valid;
  }

  @action
  accept() {
    this.callbackSuccess.perform(this.user);
  }
}
