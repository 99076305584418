define("ember-cli-custom-properties/-private/custom-property", ["exports", "@ember/object/core", "@ember/utils", "rsvp", "@ember/object/observers"], function (_exports, _core, _utils, _rsvp, _observers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _core.default.extend({
    /// The component to apply the custom property.
    component: null,
    /// The property name in the component.
    prop: null,
    /// The target custom property name (i.e., css variable).
    name: null,
    init() {
      this._super(...arguments);

      // Observe changes to the components value.
      (0, _observers.addObserver)(this.component, this.prop, this, this.refresh);

      // Apply the initial value.
      this.refresh();
    },
    destroy() {
      (0, _observers.removeObserver)(this.component, this.prop, this, this.refresh);
    },
    refresh() {
      const value = this.component.get(this.prop);
      (0, _rsvp.resolve)(value).then(value => {
        // The property value changed. We need to update the style property to reflect
        // the changes. Make sure to save the new value for the next change.
        const {
          element
        } = this.component;
        if ((0, _utils.isPresent)(value)) {
          element.style.setProperty(this.name, value);
        } else {
          element.style.removeProperty(this.name);
        }
      });
    }
  });
});