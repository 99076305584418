import Route from '@ember/routing/route';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class OrderRoute extends Route {
  @service store;
  @service user;

  async model({ accountId, orderId }) {
    accountId = accountId ?? this.paramsFor('order').accountId;

    const account = await this.store.findRecord('account', accountId);
    const order = await this.store.findRecord('order', orderId, { adapterOptions: { accountId } });
    const orderAction = this.store.createRecord('order-action-detail');

    var userCurrent = await this.user.getCurrent();

    if (userCurrent && userCurrent.name && userCurrent.email) {
      orderAction.buyerName = userCurrent.name;
      orderAction.buyerEmailAddress = userCurrent.email;
    }

    return { order, account, orderAction };
  }

  @action
  refreshRoute() {
    this.refresh();
  }
}
