import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class ExportAdapter extends BaseOdataAdapter {
  host = ENV.APP.EXPORT_API_URL;
  namespace = 'accounts';

  async query(store, model, { adapterOptions }) {
    let url = `${this.host}/${this.namespace}('${adapterOptions.accountId}')/subscriptions('${adapterOptions.subscriptionId}')/exports`;
    return await super.ajax(url, 'GET');
  }

  async queryRecord(store, model, { adapterOptions }) {
    const url = `${this.host}/${this.namespace}('${adapterOptions.accountId}')/subscriptions('${adapterOptions.subscriptionId}')/exports('${adapterOptions.exportId}')/Api.GetFileUrl`;
    const file = await super.ajax(url, 'GET');
    return window.open(file.url);
  }

  async createRecord(store, model, { adapterOptions }) {
    const url = `${this.host}/${this.namespace}('${adapterOptions.accountId}')/subscriptions('${adapterOptions.subscriptionId}')/exports`;
    return await super.ajax(url, 'POST', { data: adapterOptions.options });
  }
}
