define("ember-svg-jar/inlined/payment-approved", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"52\" cy=\"52\" r=\"52\" fill=\"#EAF7E8\"/><path d=\"M67.949 44.55a3.152 3.152 0 000-4.456l-.745-.745a3.152 3.152 0 00-4.544.09L46.516 56.924a.788.788 0 01-1.186-.033l-4.74-5.731a3.152 3.152 0 00-4.657-.22l-.614.614a3.152 3.152 0 000 4.457l8.356 8.356a3.152 3.152 0 004.457 0L67.95 44.551z\" fill=\"#5BC34A\"/>",
    "attrs": {
      "width": "104",
      "height": "104",
      "viewBox": "0 0 104 104",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});