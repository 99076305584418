import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class AccountLoaderComponent extends Component {
  @service sessionData;
  @service store;

  @arg id;

  @tracked account;

  constructor(...args) {
    super(...args);
    this.loadAccount.perform();
  }

  @task
  *loadAccount() {
    if (!this.id) {
      this.account = yield this.sessionData.getCurrentAccount();
      return;
    }
    this.account = yield this.store.findRecord('account', this.id);
  }
}
