import { Factory } from 'ember-cli-mirage';

export default Factory.extend({
  accountNumber(i) {
    return `${i + 1}`;
  },
  accountId: 'faa9e54b-30c5-ea11-a812-000d3ac058c3',
  state: 'Active',
  customerType: 'Customer',
  businessType: 'Individual',
  name: 'TESTE Gabriel LTDA',
  subscriptionId: null,
  emailAddress1: 'teste@nfe.io',
  emailAddress2: 'teste@nfe.io',
  emailAddress3: 'teste@nfe.io',
  telephone1: '11 3675-4804',
  telephone2: '11 3675-4804',
  telephone3: '11 3675-4804',
  formalName: 'NFE.IO HUB SERVICOS DE TECNOLOGIA LTDA',
  logoUri: null,
  taxRegime: null,
  locale: null,
  federalTaxNumber: '18.792.479/0001-01',
  municipalTaxNumber: null,
  regionalTaxNumber: null,
  description: null,
  webSiteUrl: 'https://google.com',
  address: {
    country: 'BRA',
    stateOrProvince: 'SP',
    city: 'São Paulo',
    cityCode: 'Liberdade',
    districtOrCounty: 'Jardim Paulistano',
    postalCode: '01452-922',
    line1: 'Avenida Brigadeiro Faria Lima',
    line2: '2369',
    line3: null,
  },
});
