define("ember-cli-custom-properties/modifiers/custom-property", ["exports", "ember-modifier", "@ember/utils"], function (_exports, _emberModifier, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _emberModifier.modifier)(function customProperty(element, [prop, value] /*, hash*/) {
    if ((0, _utils.isPresent)(value)) {
      element.style.setProperty(prop, value);
    } else {
      element.style.removeProperty(prop, value);
    }
  });
});