import { task, timeout } from 'ember-concurrency';

import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class OrderController extends Controller {
  @service layout;
  @service router;
  @service store;

  @tracked
  isRefuseDialogOpen = false;

  @tracked
  isApproveDialogOpen = false;

  @task
  *refuseOrder(order, actionDetail) {
    try {
      yield this.store.adapterFor('order').refuseOrder(order.accountId, order.id, actionDetail);
      yield timeout(1000);
      this.isApproveDialogOpen = false;
      this.isRefuseDialogOpen = false;
      this.send('refreshRoute');
    } catch (error) {
      console.error(error);
    }
  }

  @task
  *approveOrder(order, actionDetail) {
    try {
      yield this.store.adapterFor('order').approveOrder(order.accountId, order.id, actionDetail);
      yield timeout(1000);
      this.isApproveDialogOpen = false;
      this.isRefuseDialogOpen = false;
      this.send('refreshRoute');
    } catch (error) {
      console.error(error);
    }
  }

  @action
  downloadOrder(order) {
    return this.store.adapterFor('order').downloadOrder(order.accountId, order.id);
  }
}
