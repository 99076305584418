import Model, { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';

export default class ProductPriceRateModel extends Model {
  @attr('string') priceLevelId;
  @attr('string') productId;
  @attr('number', { defaultValue: 0 }) price;
  @attr('string') chargeModel;
  @fragment('transaction-currency') transactionCurrency;
  @fragmentArray('product-price-rate-item') items;
}
