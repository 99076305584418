import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { later } from '@ember/runloop';

import arg from '../../utils/decorators/arg';

const LATER_WAIT = 3000;

export default class MdcCopyButtonComponent extends Component {
  @arg label;
  @arg labelSuccess;
  @arg disabled;
  @arg trailingIcon;
  @arg leadingIcon;
  @arg style;

  @tracked
  labelTracked = this.label;

  @tracked
  disabledTracked = this.disabled;

  @tracked
  trailingIconTracked = this.trailingIcon;

  @tracked
  leadingIconTracked = this.leadingIcon;

  @tracked
  styleTracked = this.style;

  constructor() {
    super(...arguments);
  }

  @action
  success() {
    this.labelTracked = this.labelSuccess;
    this.disabledTracked = 'disabled';

    if (this.trailingIcon) this.trailingIconTracked = 'done';

    if (this.leadingIcon) this.leadingIconTracked = 'done';

    later(
      this,
      function () {
        this.labelTracked = this.label;
        this.disabledTracked = this.disabled;
        this.trailingIconTracked = this.trailingIcon;
        this.leadingIconTracked = this.leadingIcon;
      },
      LATER_WAIT
    );
  }
}
