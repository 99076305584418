import BaseAuthenticatedRoute from '../../../base-authenticated';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';

export default class WebhooksEditRoute extends BaseAuthenticatedRoute {
  @service accessToken;
  @service store;

  async model({ accountId, subscriptionId, webhookId }) {
    accountId = accountId ?? this.paramsFor('accounts.subscriptions').accountId;
    subscriptionId = subscriptionId ?? this.paramsFor('accounts.subscriptions.webhooks').subscriptionId;
    webhookId = webhookId ?? this.paramsFor('accounts.subscriptions.webhooks.edit').webhookId;

    if (isPresent(accountId) && isPresent(subscriptionId) && isPresent(webhookId)) {
      const token = await this.accessToken.getByDescription('Nota Fiscal (api.nfe.io)', subscriptionId);
      const webhook = await this.store.queryRecord('webhook', {
        subscriptionId: subscriptionId.replace('sub_', ''),
        version: 'v2',
        token: token[0].key,
        webhookId,
      });
      webhook.subscription = { id: subscriptionId };
      return webhook;
    }

    return null;
  }
}
