import Service, { service } from '@ember/service';

export default class PaymentService extends Service {
  @service store;
  @service intl;

  async payCreditCard(paymentId, paymentMethod) {
    const data = {
      type: 'CreditCard',
      paymentMethodId: paymentMethod.id,
    };

    try {
      const response = await this.store.adapterFor('payment').pay(paymentId, data);

      if (response.status === 'captured') {
        response.status = 'success';
      }

      if (response.status === 'unauthorized') {
        response.message = this.intl.t('payment.dialog.error.creditCardData');
      }

      return response;
    } catch (error) {
      error.message = this.intl.t('payment.dialog.error.unavailable');
      return error;
    }
  }

  //bankslip or pix
  async payOthers(paymentId, type) {
    let data;
    if (type === 'BankSlip') {
      data = { type: 'BankSlip' };
    }

    if (type === 'InstantPayment') {
      data = { type: 'InstantPayment' };
    }

    try {
      const response = await this.store.adapterFor('payment').pay(paymentId, data);

      response.status = response.status.toLowerCase();

      return response;
    } catch (error) {
      if (error.errorCode === '40005') {
        error.message = this.intl.t('payment.dialog.error.statusPending');
      } else {
        error.message = this.intl.t('payment.dialog.error.unavailable');
      }
      return error;
    }
  }

  pay(paymentId, paymentMethod) {
    if (paymentMethod.type === 'CreditCard') {
      return this.payCreditCard(paymentId, paymentMethod);
    } else {
      return this.payOthers(paymentId, paymentMethod.type);
    }
  }
}
