define("ember-svg-jar/inlined/logo-caixa-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#1C6EAA\" d=\"M0 2a2 2 0 012-2h20a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\"/><path fill=\"#FF7373\" d=\"M6 2h12v12H6z\" opacity=\".01\"/><path fill=\"#F79B00\" fill-rule=\"evenodd\" d=\"M13.878 4h3.46l-3.605 3.786h-3.46L13.878 4z\" clip-rule=\"evenodd\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M10.281 7.806h3.436L11.84 4.042H8.378l1.903 3.764zm3.176.362l1.902 3.764h-3.461L10.02 8.168h3.437z\" clip-rule=\"evenodd\"/><path fill=\"#F79B00\" fill-rule=\"evenodd\" d=\"M9.86 11.954H6.4l3.604-3.786h3.46l-3.605 3.786z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 24 16"
    }
  };
});