import ApplicationAdapter from '../application';
import ENV from 'accounts-app/config/environment';
import { service } from '@ember/service';

export default class UserSubscriptionAdapter extends ApplicationAdapter {
  @service session;
  @service user;

  host = ENV.APP.ACCOUNTS_API_URL + '/v0';
  namespace = 'accounts';

  get headers() {
    return this.user.authorizationHeader;
  }

  async findAll() {
    const url = `${this.host}/${this.namespace}`;
    return await super.ajax(url, 'GET', {
      headers: this.headers,
    });
  }

  setEnvironment(model, environment) {
    const url = `${this.host}/${this.namespace}/${model.get('platformSubscriptionId')}/set-environment/${environment}`;
    return this.ajax(url, 'PUT');
  }

  activate(model) {
    const url = `${this.host}/${this.namespace}/${model.get('platformSubscriptionId')}/activate`;
    return this.ajax(url, 'PUT');
  }

  deactivate(model) {
    const url = `${this.host}/${this.namespace}/${model.get('platformSubscriptionId')}/deactivate`;
    return this.ajax(url, 'PUT');
  }
}
