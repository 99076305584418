import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task, timeout } from 'ember-concurrency';

export default class LoadersAgreementItemsComponent extends Component {
  @service user;

  @tracked userInfo;

  @task
  *loadUserInfo() {
    this.userInfo = yield this.user.getCurrent();
    yield timeout(500);
  }
}
