import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class LoadersInvoicesComponent extends Component {
  @service store;

  @arg accountId;
  @arg top;
  @arg skip;

  @tracked invoices = [];
  @tracked error = false;

  @task({ drop: true })
  *getInvoices() {
    try {
      this.invoices = yield this.store.query('invoice', {
        accountId: this.accountId,
        top: this.top,
        skip: this.skip,
        count: true,
      });
      return;
    } catch (error) {
      this.error = true;
    }
  }
}
