import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class CreateAccountController extends Controller {
  @service snackbar;
  @service sessionData;
  @service metrics;
  @service leads;
  @service user;
  @service router;

  @tracked
  currStep = 'account';

  @action
  setCurrStep(step) {
    this.currStep = step;
  }

  @action
  setPhone({ detail }) {
    this.model.telephone1 = detail?.value;
  }

  amount = A([
    { id: 1, text: '0 - 10' },
    { id: 2, text: '11 - 50' },
    { id: 3, text: '50 - 100' },
    { id: 4, text: '100 - 200' },
    { id: 5, text: '200 - 300' },
    { id: 6, text: '300 - 500' },
    { id: 7, text: '500 - 1000' },
    { id: 8, text: '1000 - 5000' },
    { id: 9, text: '5000+' },
  ]);

  sector = A([
    { id: 1, text: 'Academia', icon: 'fitness_center' },
    { id: 2, text: 'Agência e Hosting', icon: 'campaign' },
    { id: 3, text: 'Clínica e Saúde', icon: 'favorite_border' },
    { id: 4, text: 'Clube de Assinaturas', icon: 'edit' },
    { id: 5, text: 'Contabilidade', icon: 'calculate' },
    { id: 6, text: 'E-commerces e Marketplaces', icon: 'shopping_cart' },
    { id: 7, text: 'Escola e Cursos', icon: 'school' },
    { id: 8, text: 'Eventos', icon: 'co_present' },
    { id: 9, text: 'Fintech', icon: 'monetization_on' },
    { id: 10, text: 'Hotéis e Pousadas', icon: 'bedroom_parent' },
    { id: 11, text: 'Indústria', icon: 'factory' },
    { id: 12, text: 'Investimentos', icon: 'price_change' },
    { id: 13, text: 'Jurídico', icon: 'balance' },
    { id: 14, text: 'Meio de Pagamento', icon: 'credit_card' },
    { id: 15, text: 'SaaS', icon: 'cloud' },
    { id: 16, text: 'Serviços Físicos', icon: 'construction' },
    { id: 17, text: 'Software House', icon: 'laptop' },
    { id: 18, text: 'Transportadora', icon: 'local_shipping' },
    { id: 19, text: 'Turismo', icon: 'flight' },
    { id: 20, text: 'Outros', icon: 'work_outline' },
  ]);

  position = A([
    { id: 1, text: 'Fundador / Sócio-Fundador / Sócio(a) / Presidente' },
    { id: 2, text: 'Diretor(a) / C - Level' },
    { id: 3, text: 'Gerente / Gestor(a)' },
    { id: 4, text: 'Coordenador / Supervisor / Especialista /  Consultor(a)' },
    { id: 5, text: 'Operacional / Analista / Auxiliar' },
    { id: 6, text: 'Secretaria / Atendimento / Recepção' },
    { id: 7, text: 'Estagiário(a) / Estudante' },
  ]);

  expertise = A([
    { id: 1, text: 'Administrativo' },
    { id: 2, text: 'Atendimento' },
    { id: 3, text: 'Contábil / Fiscal' },
    { id: 4, text: 'Financeiro' },
    { id: 5, text: 'Logística' },
    { id: 6, text: 'Tecnologia' },
    { id: 7, text: 'Vendas / Compras' },
  ]);

  help = A([
    { id: 1, text: 'Quero Emitir Notas Fiscais de Serviço (NFS-e)', value: 'emitir_nfs' },
    { id: 2, text: 'Quero Emitir Notas Fiscais de Produto (NF-e)', value: 'emitir_nf' },
    { id: 3, text: 'Quero Emitir Notas Fiscais de Consumidor (NFC-e)', value: 'emitir_nfc' },
    { id: 4, text: 'Quero Consultar Notas Fiscais (NF-e)', value: 'consultar_nf' },
    { id: 5, text: 'Quero Consultar dados de CNPJ', value: 'consultar_cnpj' },
    { id: 6, text: 'Quero Consultar dados de CPF', value: 'consultar_cpf' },
    { id: 7, text: 'Quero Consultar Endereços', value: 'consulta_endereco' },
  ]);

  @task
  *createAccount(model) {
    try {
      model.save();

      const currentUser = yield this.user.getCurrent();
      yield this.leads.sendLeads(
        currentUser.given_name,
        currentUser.family_name,
        model.emailAddress1,
        model.telephone1,
        model.name,
        model.como_podemos_te_ajudar,
        model.faixa_quantidade_mensal,
        model.cargo_do_contato,
        model.area_de_atuacao,
        model.segmento_empresa,
        model.outros_segmentos
      );

      this.snackbar.show({ message: 'Conta criada com sucesso.' });

      return this.router.transitionTo('index');
    } catch (error) {
      let message = 'Erro ao criar conta.';

      if (error.errors.length > 0 && error.errors[0].status === '409')
        message = 'Nome da conta já está sendo utilizado.';

      return this.snackbar.show({ message: message });
    }
  }

  // @action
  // checkAccountName(model, nextStep) {
  //   let validName = model.validations.attrs.name.isValid;
  //   if (validName) return nextStep();
  // }
}
