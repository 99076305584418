import Service, { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { A } from '@ember/array';

export default class PaymentMethodService extends Service {
  @service intl;

  _parseCreditCardErrorMessage(message) {
    const lowerCaseMessage = isPresent(message) ? message.toLowerCase() : '';

    if (lowerCaseMessage.includes('year')) {
      return this.intl.t('errorMessages.creditCardForm.invalidYear');
    } else if (lowerCaseMessage.includes('month')) {
      return this.intl.t('errorMessages.creditCardForm.invalidMonth');
    } else if (lowerCaseMessage.includes('security code')) {
      return this.intl.t('errorMessages.creditCardForm.invalidNumber');
    } else if (lowerCaseMessage.includes('number')) {
      return this.intl.t('errorMessages.creditCardForm.invalidSecurityCode');
    } else if (lowerCaseMessage.includes('name')) {
      return this.intl.t('errorMessages.creditCardForm.invalidName');
    } else {
      return this.intl.t('errorMessages.creditCardForm.default');
    }
  }

  async save(model) {
    try {
      return await model.save();
    } catch (ex) {
      console.error(ex)
      if (model.type === 'CreditCard') {
        let errors = A([]);

        ex.errors?.forEach((error) => {
          errors.push(this._parseCreditCardErrorMessage(error.message));
        });

        return errors;
      }
    }
  }
}
