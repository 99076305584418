import ENV from 'accounts-app/config/environment';
import RESTAdapter from '@ember-data/adapter/rest';

export default class AddressAdapter extends RESTAdapter {
  host = ENV.APP.ADDRESS_API_URL;
  apiKey = ENV.APP.OPEN_API_APIKEY;

  getAddress(postalCode, token) {
    const url = `${this.host}/v2/addresses/${postalCode}`;
    return super.ajax(url, 'GET', { headers: { Authorization: token } });
  }
}
