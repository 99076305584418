import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency';

export default class MgmtSubscriptionsController extends Controller {
  @service layout;

  queryParams = ['search', 'top', 'skip'];

  @tracked search = '';
  @tracked top = 10;
  @tracked skip = 0;
  @tracked moreOptions = [];

  @action
  nextPage() {
    this.skip += this.top;
  }

  @action
  previousPage() {
    this.skip -= this.top;
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = value;
  }

  @task({ drop: true })
  *setStatus(model) {
    return yield model.setStatus.perform(model.status == 'Active' ? 'deactivate' : 'activate');
  }

  @task({ drop: true })
  *setEnvironment(model) {
    return yield model.setEnvironment.perform(model.environment == 'Sandbox' ? 'production' : 'development');
  }
}
