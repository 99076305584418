import BaseAuthenticatedRoute from '../../base-authenticated';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';

export default class AccountsSubscriptionApikeysIndexRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId, subscriptionId }) {
    accountId = accountId ?? this.paramsFor('accounts.subscriptions').accountId;
    subscriptionId = subscriptionId ?? this.paramsFor('accounts.subscriptions.apikeys').subscriptionId;

    return await this.store.findRecord('subscription', subscriptionId, {
      adapterOptions: {
        accountId,
      },
    });
  }
}
