import Model, { attr } from '@ember-data/model';

export default class PaymentMethodModel extends Model {
  @attr('string') accountId;
  @attr('string') createdOn;
  @attr('string') modifiedOn;
  @attr('string') type;

  @attr wireTransfer;
  @attr bankSlip;

  @attr('nested-keys') card;
  @attr('nested-keys') details;
}
