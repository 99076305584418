define("ember-composable-helpers/helpers/contains", ["exports", "@ember/component/helper", "ember-composable-helpers/helpers/includes", "@ember/debug"], function (_exports, _helper, _includes, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.contains = contains;
  _exports.default = void 0;
  function contains(needleOrNeedles, haystack) {
    (true && !(false) && (0, _debug.deprecate)('{{contains}} helper provided by ember-composable-helpers has been renamed to {{includes}}.', false, {
      id: 'ember-composable-helpers.contains-helper',
      until: '5.0.0'
    }));
    return (0, _includes.includes)(needleOrNeedles, haystack);
  }
  var _default = _exports.default = (0, _helper.helper)(function ([needle, haystack]) {
    return contains(needle, haystack);
  });
});