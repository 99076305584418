import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';

function cleanup(instance) {
  let { element, event, handler } = instance;

  if (element && event && handler) {
    element.removeEventListener(event, handler);

    instance.element = null;
    instance.event = null;
    instance.handler = null;
  }
}

export default class NfeComponent extends Modifier {
  element = null;
  event = null;
  handler = null;

  modify(element, [event, handler]) {
    this.addEventListener(element, event, handler);
    registerDestructor(this, cleanup);
  }

  addEventListener = (element, event, handler) => {
    this.element = element;
    this.event = event;
    this.handler = handler;

    element.addEventListener(event, handler);
  };
}
