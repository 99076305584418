import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AgreementSupportController extends Controller {
  @tracked
  isOpen = true;

  @tracked
  selectedPlan = null;

  supportPlans = [
    {
      id: 'basic',
      title: 'Básico',
      subtitle: 'R$ 140,00',
      description:
        'Lorem ipsum dolor sit amet; consectetur adipiscing elit; Fusce dui nulla; tempus non cursus sed; pretium sed mi. Período de carência: 30 dias',
    },
    {
      id: 'intermediary',
      title: 'Intermediário',
      subtitle: 'R$ 140,00',
      description:
        'Lorem ipsum dolor sit amet; consectetur adipiscing elit; Fusce dui nulla; tempus non cursus sed; pretium sed mi. Período de carência: 30 dias',
    },
    {
      id: 'advanced',
      title: 'Avançado',
      subtitle: 'R$ 140,00',
      description:
        'Lorem ipsum dolor sit amet; consectetur adipiscing elit; Fusce dui nulla; tempus non cursus sed; pretium sed mi. Período de carência: 30 dias',
    },
    {
      id: 'enterprise',
      title: 'Enterprise',
      subtitle: 'Sob medida',
      description:
        'Lorem ipsum dolor sit amet; consectetur adipiscing elit; Fusce dui nulla; tempus non cursus sed; pretium sed mi. Período de carência: 30 dias',
    },
  ];

  @action
  selecteSupportPlan(plan) {
    this.selectedPlan = plan.id;
  }
}
