import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class AccountsApikeysController extends Controller {
  @service router;
  @service store;
  @service user;
  @service intl;
  @service sessionData;

  queryParams = ['top', 'skip', 'filter'];

  @tracked filter = '';
  @tracked top = 10;
  @tracked skip = 0;
  @tracked moreOptions = [];
  @tracked viewingClientId = null;

  get headersGrants() {
    let defaultHeaders = A([
      this.intl.t('apikeys.table.description'),
      this.intl.t('apikeys.table.id'),
      this.intl.t('apikeys.table.createdOn'),
      this.intl.t('apikeys.table.modifiedOn'),
      this.intl.t('apikeys.table.status'),
      // this.intl.t('apikeys.table.actions'),
    ]);

    return defaultHeaders;
  }

  get headersSecrets() {
    let defaultHeaders = A([
      this.intl.t('apikeys.table.description'),
      this.intl.t('apikeys.table.type'),
      this.intl.t('apikeys.table.key'),
      this.intl.t('apikeys.table.expiration'),
    ]);

    return defaultHeaders;
  }

  @task({ drop: true })
  *setStatus(apikey) {
    return yield apikey.setStatus.perform(apikey.status == 'Active' ? 'deactivate' : 'activate');
  }

  @action
  onSearch(ev) {
    this.filter = ev.target.value;
  }

  @action
  nextPage() {
    this.skip += this.top;
  }

  @action
  previousPage() {
    this.skip -= this.top;
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = value;
  }

  @action
  changeViewingClientId({ detail: { option } }) {
    this.viewingClientId = option;
  }
}
