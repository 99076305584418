import Transform from '@ember-data/serializer/transform';

export default class NestedKeysTransform extends Transform {
  deserialize(serialized, options) {
    return serialized;
  }

  serialize(deserialized, options) {
    return deserialized.serialize();
  }
}
