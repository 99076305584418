import Model, { attr } from '@ember-data/model';

export default class InvoiceModel extends Model {
  @attr('string') accountId;
  @attr('string') agreementId;
  @attr('string') createdOn;
  @attr('string') description;
  @attr('number') discountAmount;
  @attr('number') discountPercentage;
  @attr('string') dueOn;
  @attr('string') effectiveOn;
  @attr('string') invoiceNumber;
  @attr items;
  @attr('string') modifiedOn;
  @attr('string') name;
  @attr('string') orderId;
  @attr('string') state;
  @attr('string') status;
  @attr('number') totalAmount;
  @attr('number') totalDiscountAmount;
  @attr('number') totalItemsAmount;
  @attr('number') totalItemsDiscountAmount;
  @attr('number') totalTax;

  //check if any item has subscription
  get hasSubscriptions() {
    if (!this.items) return false;
    return this.items.every((val, i, arr) => val.subscription && arr[0].subscription);
  }

  //if items has subscriptions, check if subscriptions are equal
  get isSubscriptionsEqual() {
    if (this.hasSubscriptions) {
      return this.items.every((val, i, arr) => val.subscription.id === arr[0].subscription.id);
    }
  }

  //if subscriptions are not equal, format to return subscriptions with items
  get itemsWithSubscriptions() {
    if (!this.isSubscriptionsEqual) {
      return this.formatSubscriptionsWithItems(this.items);
    }
  }

  get showItemsBySubscription() {
    return this.hasSubscriptions && !this.isSubscriptionsEqual;
  }

  formatSubscriptionsWithItems(items) {
    let subscriptions = items
      .map((item) => {
        item.subscription.items = [];
        item.subscription.totalAmount = 0;
        return item.subscription;
      })
      .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i);

    items.forEach((item) => {
      let index = subscriptions.findIndex((sub) => sub.id === item.subscription.id);

      if (item.subscription.id === subscriptions[index].id) {
        delete item.subscription;
        subscriptions[index].items.push(item);
        subscriptions[index].totalAmount += item.amount;
      }
    });

    return subscriptions;
  }
}
