import SubscriptionModel from '../subscription';
import { attr } from '@ember-data/model';

export default class UserSubscriptionModel extends SubscriptionModel {
  @attr('string') parentId;
  @attr('string') email;
  @attr('string') environment;
  @attr('string') federalTaxNumber;
  @attr('string') modifiedOn;
  @attr('string') name;
  @attr('string') status;
}
