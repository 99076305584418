import Model, { attr } from '@ember-data/model';

import { fragment } from 'ember-data-model-fragments/attributes';

export default class LegalEntity extends Model {
  @attr('number') shareCapital;
  @attr() address;
  @attr() phones;
  @attr() economicActivities;
  @attr() legalNature;
  @attr() partners;
  @attr('string') unit;
  @attr('date') issuedOn;
  @attr('date') statusOn;
  @attr('date') openedOn;
  @attr('string') email;
  @attr('string') status;
  @attr('string') size;
  @attr('string') federalTaxNumber;
  @attr('string') name;
  @attr('string') tradeName;
  @attr('boolean') blocked;
}
