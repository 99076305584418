import Model, { attr } from '@ember-data/model';

export default class PaymentModel extends Model {
  @attr('string') accountId;
  @attr('number') amount;
  @attr transactionCurrency;
  @attr('string') cancelledOn;
  @attr('string') createdOn;
  @attr('number') discountAmount;
  @attr('string') dueOn;
  @attr('string') effectiveOn;
  @attr('number') feeAmount;
  @attr invoices;
  @attr('string') modifiedOn;
  @attr('number') netAmount;
  @attr('string') number;
  @attr('number') paidAmount;
  @attr paymentMethodTypes;
  @attr paymentMethod;
  @attr('number') penaltyAmount;
  @attr('number') receivableAmount;
  @attr('number') refundAmount;
  @attr('string') status;
  @attr('number') taxWithheldAmount;

  get isPaymentPaid() {
    return this.status === 'Paid';
  }
  get isPaymentPending() {
    return this.status === 'Pending';
  }
  get isPaymentExpired() {
    return this.status === 'Expired';
  }
}
