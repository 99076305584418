import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

function noOp() {}

export default class SupportPlanCardComponent extends Component {
  @tracked
  isOutlined = true;

  @action
  buttonAction() {
    return (this.args.button.action || noOp)();
  }

  @action
  changeOutlined() {
    return (this.isOutlined = !this.isOutlined);
  }
}
