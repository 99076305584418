define("ember-cli-mdc-icon/components/mdc-icon", ["exports", "@ember/component", "ember-cli-mdc-base/component", "@ember/utils", "@ember/string", "@ember/template-factory"], function (_exports, _component, _component2, _utils, _string, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#if @icon}}
    <i class={{this.iconClass}} ...attributes>{{@icon}}</i>
  {{else if @image}}
    <img src={{@image}} class={{this.iconClass}} ...attributes />
  {{else if (has-block)}}
    <svg class={{this.iconClass}}>
      {{yield}}
    </svg>
  {{/if}}
  
  */
  {
    "id": "RAozlBsX",
    "block": "[[[41,[30,1],[[[1,\"  \"],[11,\"i\"],[16,0,[30,0,[\"iconClass\"]]],[17,2],[12],[1,[30,1]],[13],[1,\"\\n\"]],[]],[[[41,[30,3],[[[1,\"  \"],[11,\"img\"],[16,\"src\",[30,3]],[16,0,[30,0,[\"iconClass\"]]],[17,2],[12],[13],[1,\"\\n\"]],[]],[[[41,[48,[30,4]],[[[1,\"  \"],[10,\"svg\"],[15,0,[30,0,[\"iconClass\"]]],[12],[1,\"\\n    \"],[18,4,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],null]],[]]]],[]]]],[\"@icon\",\"&attrs\",\"@image\",\"&default\"],false,[\"if\",\"has-block\",\"yield\"]]",
    "moduleName": "ember-cli-mdc-icon/components/mdc-icon.hbs",
    "isStrictMode": false
  });
  class MdcIcon extends _component2.default {
    get iconClass() {
      if ((0, _utils.isPresent)(this.args.iconClass)) {
        return this.args.iconClass;
      } else if ((0, _utils.isPresent)(this.args.style)) {
        return `material-icons-${(0, _string.dasherize)(this.args.style)}`;
      } else {
        return 'material-icons';
      }
    }
  }
  _exports.default = MdcIcon;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MdcIcon);
});