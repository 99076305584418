import Component from '@glimmer/component';
import { service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class MdcListContactItemComponent extends Component {
  @service layout;
  @service intl;

  get isModelEmpty() {
    const { phone, email } = this.args.model;
    return isEmpty(phone) && isEmpty(email);
  }

  get contactType() {
    return this.intl.t(`account.contact.${this.args.model.type}`);
  }
}
