import Component from '@glimmer/component';
import { service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import arg from '../../../utils/decorators/arg';
import { isPresent } from '@ember/utils';
export default class PaymentMethodsListComponent extends Component {
  @service store;

  @tracked paymentMethods = [];
  @tracked paymentMethodsInvalid = [];
  @tracked paymentMethodSelected = null;

  @arg onSelect;

  constructor(...args) {
    super(...args);
    this.getPaymentMethods.perform(this.args.paymentId);
  }

  validatePaymentMethods(paymentMethods) {
    const paymentMethodsValid = paymentMethods.filter((paymentMethod) => {
      if (paymentMethod.type == 'CreditCard') {
        const creditCardExpirationDate = new Date(
          paymentMethod.card.expirationYear,
          paymentMethod.card.expirationMonth - 1,
          1
        );

        if (creditCardExpirationDate.getTime() < Date.now()) {
          paymentMethod.error = {
            type: 'CreditCardExpired',
          };
          this.paymentMethodsInvalid.push(paymentMethod);
          return false;
        }
      }
      return true;
    });
    return paymentMethodsValid;
  }

  @task
  *getPaymentMethods(paymentId) {
    const response = yield this.store.adapterFor('payment').getPaymentMethods(paymentId);
    this.paymentMethods = this.validatePaymentMethods(response);
  }

  @action
  selectPaymentMethod(paymentMethod) {
    this.paymentMethodSelected = paymentMethod.detail.index;

    if (isPresent(this.onSelect)) {
      this.onSelect(this.paymentMethods[this.paymentMethodSelected]);
    }
  }
}
