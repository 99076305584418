import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class WebhooksController extends Controller {
  @service store;
  @service intl;
  @service snackbar;
  @service accessToken;
  @service sessionData;

  @tracked dialogDeleteOpen = false;
  @tracked deleteWebhook = null;
  @tracked refresh = true;

  translateTypes = (types) => {
    if (!types?.length) return [];
    return types.map((type) => this.intl.t(`webhooks.type.${type}`));
  };

  @task({ drop: true })
  *confirmDelete(model) {
    const token = yield this.accessToken.getByDescription('Nota Fiscal (api.nfe.io)', model?.subscription?.id);
    let message = '';
    try {
      yield model.deleteRecord();
      yield model.save({
        adapterOptions: {
          subscriptionId: model?.subscription?.id.replace('sub_', ''),
          token: token[0].key,
        },
      });
      message = this.intl.t('webhooks.delete.success');

      this.snackbar.show({ message });
    } catch (error) {
      let errorMessage = this.intl.t('webhooks.delete.error');
      this.snackbar.show({ message: errorMessage });
    }

    this.snackbar.show({ message });
    this.closeDeleteDialog();
    this.deleteWebhook = null;
    this.refresh = !this.refresh;
  }

  @action
  showDeleteDialog(webhook) {
    this.deleteWebhook = webhook;
    this.dialogDeleteOpen = true;
  }

  closeDeleteDialog = () => {
    this.dialogDeleteOpen = false;
    this.deleteWebhook = null;
  };

  @action
  refreshPage() {
    window.location.reload(true);
  }
}
