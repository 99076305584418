import RESTSerializer from '@ember-data/serializer/rest';

export default class ApikeyGrantSerializer extends RESTSerializer {
  primaryKey = 'sessionId';

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload) {
      payload['apikey/grant'] = payload;
    }
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
