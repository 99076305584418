define("ember-master-tab/services/master-tab-factory", ["exports", "@ember/polyfills", "@ember/array", "rsvp", "@ember/runloop", "@ember/object/evented", "@ember/service", "ember-master-tab/utils", "ember-master-tab/consts"], function (_exports, _polyfills, _array, _rsvp, _runloop, _evented, _service, _utils, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Checks whether the current tab is the master tab.
   */
  function isMasterTab() {
    return localStorage[_consts.tabIdKey] === _consts.tabId;
  }

  /** The service factory. */
  var _default = _exports.default = _service.default.extend(_evented.default, {
    /** Contains current lock names that will be deleted during the 'beforeunload' window event. */
    lockNames: (0, _array.A)(),
    resolve: null,
    contestTimeout: null,
    /**
     * Sets up listeners on the 'storage' and 'beforeunload' window events.
     * Returns a promise that resolves immediately if this or another tab is the master tab and that
     * tab is currently present. In case the master tab crashed and a new tab is opened, this promise
     * will resolve after a short delay while it invalidates the master tab.
     */
    setup() {
      const storageHandler = e => {
        switch (e.key) {
          case _consts.tabIdKey:
            {
              const newTabId = e.newValue;
              if (newTabId === null) {
                (0, _utils.debug)('Master tab currently being contested.');
                localStorage[_consts.shouldInvalidateMasterTabKey] = false;
                this.registerAsMasterTab();
              } else {
                if (this.get('isMasterTab') && e.oldValue !== null && _consts.tabId !== newTabId) {
                  (0, _utils.debug)('Lost master tab status. Probably race condition related.');
                  (0, _runloop.run)(() => {
                    this.set('isMasterTab', false);
                    this.trigger('isMasterTab', false);
                  });
                }
              }
              break;
            }
          case _consts.shouldInvalidateMasterTabKey:
            {
              const shouldInvalidateMasterTab = eval(e.newValue);
              const _isMasterTab = isMasterTab();
              if (shouldInvalidateMasterTab && _isMasterTab) {
                localStorage[_consts.shouldInvalidateMasterTabKey] = false;
                (0, _utils.debug)('Invalidation of master tab avoided.');
              } else if (!shouldInvalidateMasterTab && !_isMasterTab) {
                if (this.contestTimeout !== null) {
                  clearTimeout(this.contestTimeout);
                  this.contestTimeout = null;
                  if (this.resolve !== null) {
                    this.resolve();
                    this.resolve = null;
                  }
                  (0, _utils.debug)('Invalidation of master tab aborted.');
                }
              }
              break;
            }
        }
      };
      window.addEventListener('storage', storageHandler);
      window.addEventListener('beforeunload', () => {
        window.removeEventListener('storage', storageHandler);
        this.lockNames.forEach(l => {
          delete localStorage[l];
          (0, _utils.debug)(`Deleted lock [${l}].`);
        });
        if (isMasterTab()) {
          delete localStorage[_consts.tabIdKey];
          (0, _utils.debug)('Unregistered as master tab. ');
        }
      });
      return this.contestMasterTab();
    },
    isMasterTab: false,
    /** Tries to register as the master tab if there is no current master tab registered. */
    registerAsMasterTab() {
      let success = false;
      if (isMasterTab()) {
        success = true;
      } else {
        if (typeof localStorage[_consts.tabIdKey] === 'undefined') {
          localStorage[_consts.tabIdKey] = _consts.tabId;
          localStorage[_consts.shouldInvalidateMasterTabKey] = false;
          success = true;
        }
        (0, _utils.debug)(`Trying to register as master tab... ${success ? 'SUCCESS' : 'FAILED'}.`);
      }
      (0, _runloop.run)(() => {
        this.set('isMasterTab', success);
        this.trigger('isMasterTab', success);
      });
      return success;
    },
    /**
     * Returns a promise which attempts to contest the master tab.
     */
    contestMasterTab() {
      return new _rsvp.Promise(resolve => {
        if (!this.registerAsMasterTab()) {
          (0, _utils.debug)('Trying to invalidate master tab.');
          this.resolve = resolve;
          this.contestTimeout = setTimeout(() => {
            const shouldInvalidateMasterTab = eval(localStorage[_consts.shouldInvalidateMasterTabKey]);
            if (shouldInvalidateMasterTab) {
              localStorage[_consts.shouldInvalidateMasterTabKey] = false;
              delete localStorage[_consts.tabIdKey];
              this.registerAsMasterTab();
            }
            resolve();
          }, 500);
          localStorage[_consts.shouldInvalidateMasterTabKey] = true;
        } else {
          resolve();
        }
      });
    },
    /**
     * Runs the provided function if this is the master tab. If this is not the current tab, run
     * the function provided to 'else()'.
     */
    run(func, options = {}) {
      if (typeof options !== 'object') {
        throw 'Options must be an object.';
      }
      const finalOptions = (0, _polyfills.assign)({
        force: false
      }, options);
      const _isMasterTab = isMasterTab();
      if (_isMasterTab || finalOptions.force) {
        func();
      }
      return {
        else(func) {
          if (!_isMasterTab && !finalOptions.force) {
            func();
          }
        }
      };
    },
    /**
     * Runs the provided function (which should return a Promise) if this is the master tab.
     * It creates a lock which is freed once the Promise is resolved or rejected.
     * If this is not the master tab, run the function provided to 'wait()'. If there is no
     * lock present currently, the function runs immediately. If there is, it will run once
     * the promise on the master tab resolves or rejects.
     */
    lock(lockName, func, options = {}) {
      if (typeof options !== 'object') {
        throw 'Options must be an object.';
      }
      const finalOptions = (0, _polyfills.assign)({
        force: false,
        waitNext: true,
        waitNextDelay: 1000
      }, options);
      const lockNameKey = `${_consts.namespace}lock:${lockName}`;
      const lockResultKey = `${lockNameKey}:result`;
      const lockResultTypeKey = `${lockNameKey}:result-type`;
      const isLocked = typeof localStorage[lockNameKey] !== 'undefined';
      const _isMasterTab = isMasterTab();
      if ((_isMasterTab || finalOptions.force) && !isLocked) {
        localStorage[lockNameKey] = true;
        delete localStorage[lockResultKey];
        delete localStorage[lockResultTypeKey];
        if (this.lockNames.indexOf(lockNameKey) === -1) {
          this.lockNames.push(lockNameKey);
        }
        const p = func();
        if (!p || !p.then) {
          throw 'The function argument must return a thennable object.';
        }
        const callback = (type, result) => {
          localStorage[lockResultTypeKey] = type;
          localStorage[lockResultKey] = result;
          delete localStorage[lockNameKey];
          const index = this.lockNames.indexOf(lockNameKey);
          if (index > -1) {
            this.lockNames.splice(index, 1);
          }
        };
        p.then(result => callback('success', result), result => callback('failure', result));
      }
      return {
        wait(success, failure = null) {
          if (!_isMasterTab && !finalOptions.force || isLocked) {
            const callCallback = waited => {
              const resultType = localStorage[lockResultTypeKey];
              const func = resultType === 'success' ? success : failure;
              const result = localStorage[lockResultKey];
              if (func !== null) {
                func(result, waited);
              }
            };
            if (isLocked || finalOptions.waitNext) {
              const handler = e => {
                if (e.key === lockNameKey && e.newValue === null) {
                  window.removeEventListener('storage', handler);
                  callCallback(true);
                }
              };
              window.addEventListener('storage', handler);
              if (finalOptions.waitNext) {
                setTimeout(() => {
                  window.removeEventListener('storage', handler);
                  callCallback(true);
                }, finalOptions.waitNextDelay);
              }
            } else {
              callCallback(false);
            }
          }
        }
      };
    }
  });
});