define("ember-svg-jar/inlined/logo-banco-do-brasil-square", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#F9E900\" d=\"M0 0h24v24H0z\"/><path fill=\"#2D589D\" fill-rule=\"evenodd\" d=\"M8.773 11.727l2.718-1.789-1.134-.801 1.528-1.032 3.125 2.133-5.144 3.501 1.059.747 6.474-4.248L12 6.545l-5.424 3.62 2.197 1.562zm4.177-2.191l1.116.741-5.179 3.442 3.2 2.166 1.427-.99-1.057-.753 2.674-1.826 2.224 1.543-5.424 3.595-5.35-3.647 6.369-4.271zm-4.236 6.412l-1.052-.775-1.117.752v1.49l2.169-1.467zm6.44-7.882l1.108.781 1.109-.717V6.556l-2.216 1.51z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});