import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { isBlank } from '@ember/utils';
import { A } from '@ember/array';

import arg from '../../../utils/decorators/arg';

export default class LoadersWebhooksComponent extends Component {
  @service store;
  @service user;
  @service accessToken;

  @arg subscriptionId;
  @arg top;
  @arg skip;

  @tracked webhooks = [];
  @tracked error = false;

  @task({ drop: true })
  *getWebhooks() {
    if (isBlank(this.subscriptionId)) return;

    try {
      const token = yield this.accessToken.getByDescription('Nota Fiscal (api.nfe.io)', this.subscriptionId);

      this.rawWebhooks = A([]);

      // Get webhooks v1
      yield this.store
        .query('webhook', {
          subscriptionId: this.subscriptionId.replace('sub_', ''),
          version: 'v1',
          token: token[0].key,
        })
        .then((data) => this.rawWebhooks.pushObjects(data.toArray()));

      // Get webhooks v2
      yield this.store
        .query('webhook', {
          subscriptionId: this.subscriptionId.replace('sub_', ''),
          version: 'latest',
          token: token[0].key,
        })
        .then((data) => this.rawWebhooks.pushObjects(data.toArray()));

      yield this.rawWebhooks.map((webhook) => (webhook.subscription = { id: this.subscriptionId }));

      return (this.webhooks = this.rawWebhooks);
    } catch (error) {
      console.log(error);
      this.error = true;
    }
  }
}
