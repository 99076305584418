import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AccountOrdersController extends Controller {
  queryParams = ['top', 'skip', 'filter'];

  @service store;
  @service intl;
  @service layout;
  @service sessionData;

  @tracked filter = '';
  @tracked top = 10;
  @tracked skip = 0;

  @action
  onSearch(ev) {
    this.filter = ev.target.value;
  }

  @action
  nextPage() {
    this.skip += this.top;
  }

  @action
  previousPage() {
    this.skip -= this.top;
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = value;
  }

  get locale() {
    return this.intl.get('primaryLocale') || 'pt-br';
  }

  @action
  refreshPage() {
    window.location.reload(true);
  }
}
