import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import arg from '../../utils/decorators/arg';

export default class SubscriptionUserDeleteDialog extends Component {
  @arg callbackSuccess;
  @arg callbackCancel;
  @arg model;

  @action
  close() {
    if (isPresent(this.callbackCancel)) {
      this.callbackCancel();
    }
  }

  @action
  accept() {
    this.callbackSuccess.perform(this.model);
  }
}
