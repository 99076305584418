import { A } from '@ember/array';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class AdminMenuComponent extends Component {
  @service user;
  @service layout;
  @service sessionData;
  @service snackbar;
  @service router;
  @service intl;

  @tracked search = '';
  @tracked top = 10;
  @tracked skip = 0;
  @tracked moreOptions = [];

  perPageOptions = A([
    { value: 10, text: "10" },
    { value: 20, text: "20" },
    { value: 30, text: "30" }
  ]);

  accountFilters = A([
    {
      id: '0',
      text: this.intl.t('subscription.title'),
    },
    {
      id: '1',
      text: this.intl.t('account.title'),
    },
  ]);

  @tracked
  accountFilterChoice = this.accountFilters[0];

  @action
  setSearch({ target: { value } }) {
    this.search = value;
    this.skip = 0;
    this.top = 10;
  }

  @action
  nextPage() {
    this.skip += parseInt(this.top);
  }

  @action
  previousPage() {
    this.skip -= parseInt(this.top);
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = parseInt(value);
  }

  @tracked
  open = false;

  @action
  openDropdown() {
    this.open = true;
  }
  @action
  closeSideSheet() {
    this.open = false;
  }

  @action
  setAccountTabActivated({ detail }) {
    this.accountFilterChoice = this.accountFilters[detail?.chipId ?? 0];

    console.log('setAccountTabActivated', this.accountFilterChoice)
  }

  @tracked
  chips = A([{ text: 'Assinaturas' }, { text: 'Contas' }]);

  @tracked
  choice = this.chips.objectAt(0);

  @action
  change(choice) {
    this.choice = choice;
  }

  @action
  async impersonate({ accountId, subscriptionId }) {
    await this.sessionData.impersonate(accountId, subscriptionId);
  }

  @task({ drop: true })
  *setStatus(model) {
    return yield model.setStatus.perform(model.status == 'Active' ? 'deactivate' : 'activate');
  }

  @task({ drop: true })
  *setEnvironment(model) {
    return yield model.setEnvironment.perform(model.environment == 'Sandbox' ? 'production' : 'development');
  }
}
