export default {
  base: {
    number: { style: 'currency', currency: 'BRL', minimumFractionDigits: 2 },
    date: { timeZone: 'UTC' },
  },
  time: {
    hhmmss: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
  },
  date: {
    timeZone: 'UTC',
    hhmmss: {
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    },
  },
  number: {
    compact: { notation: 'compact' },
    USD: {
      style: 'currency',
      currency: 'USD',
    },
    BRL: {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
};
