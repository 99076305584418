import StorageObject from 'ember-local-storage/local/object';

const Storage = StorageObject.extend();

Storage.reopenClass({
  initialState() {
    return {
      account: {
        all: null,
        current: null,
      },
      subscription: {
        all: null,
        current: null,
      },
      providers: {},
      impersonated: false,
    };
  },
});

export default Storage;
