import Component from '@glimmer/component';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';
import { isBlank } from '@ember/utils';

import arg from '../../../utils/decorators/arg';

export default class LoadersUsersComponent extends Component {
  @service store;
  @service user;

  @arg subscriptionId;
  @arg top;
  @arg skip;

  @tracked users = [];
  @tracked error = false;

  @task({ drop: true })
  *getUsers() {
    if (isBlank(this.subscriptionId)) return;

    try {
      this.users = yield this.store.query('subscription/user', {
        adapterOptions: {
          subscriptionId: this.subscriptionId.replace('sub_', ''),
        },
      });
      return;
    } catch (error) {
      console.log(error);
      this.error = true;
    }
  }
}
