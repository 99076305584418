define("ember-master-tab/utils", ["exports", "ember-master-tab/consts"], function (_exports, _consts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.debug = debug;
  function debug() {
    if (console.debug) {
      console.debug(_consts.namespace, ...arguments);
    } else {
      console.log(_consts.namespace, ...arguments);
    }
  }
});