define("ember-svg-jar/inlined/logo-elo-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"40\" cy=\"40\" r=\"40\" fill=\"#000\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M23.371 41.693l8.355-3.586c-1.162-1.46-3.43-2.24-5.543-1.306-2.001.884-3.058 2.981-2.812 4.892m-2.054 3.949c-1.65-2.453-1.832-6.017-.072-8.806 1.777-2.814 5.122-4.28 8.34-3.66 3.392.652 5.776 3.3 6.396 6.164l-14.664 6.301m16.294-16.286h3.145v.36c0 5.015.002 10.031-.006 15.047 0 .24.06.362.295.453.82.318 1.631.663 2.468 1.007l-1.3 3.106-1.278-.522c-.457-.188-.916-.375-1.37-.57-1.147-.49-1.773-1.363-1.908-2.586a8.096 8.096 0 01-.044-.884c-.002-5.025-.002-10.05-.002-15.074v-.337z\" clip-rule=\"evenodd\"/><path fill=\"#00A6E9\" fill-rule=\"evenodd\" d=\"M48.794 44.863l-2.193 2.485c-3.386-2.706-3.984-8.71-.021-12.199l2.214 2.504c-1.055.975-1.618 2.18-1.619 3.6 0 1.42.558 2.627 1.62 3.61\" clip-rule=\"evenodd\"/><path fill=\"#FFF400\" fill-rule=\"evenodd\" d=\"M56.63 40.255c-.344-1.417-1.092-2.505-2.327-3.217-1.223-.706-2.519-.819-3.873-.395l-1.068-3.228c.5-.111.975-.257 1.46-.318 2.056-.263 3.97.156 5.686 1.349 1.72 1.196 2.83 2.833 3.331 4.89.053.219 0 .275-.21.315-.906.175-1.81.37-2.715.557-.086.018-.174.03-.285.047\" clip-rule=\"evenodd\"/><path fill=\"#F44C1F\" fill-rule=\"evenodd\" d=\"M49.513 48.885l1.04-3.158c2.803.883 5.566-.828 6.202-3.618l3.21.666c-.823 4.726-5.923 7.745-10.452 6.11\" clip-rule=\"evenodd\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M25.584 45.283c.953.564 1.956.827 3.045.704 1.086-.122 2.02-.573 2.825-1.335l2.305 2.365c-2.525 2.648-6.946 3.059-9.93 1.06l1.755-2.794\" clip-rule=\"evenodd\"/><path fill=\"red\" d=\"M20 20h40v40H20z\" opacity=\".01\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "80",
      "height": "80",
      "fill": "none",
      "viewBox": "0 0 80 80"
    }
  };
});