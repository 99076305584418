define("ember-master-tab/initializers/master-tab", ["exports", "ember-master-tab/services/master-tab-factory"], function (_exports, _masterTabFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    if (!application.testing) {
      const masterTab = _masterTabFactory.default.create();
      application.unregister('service:master-tab-factory');
      application.register('service:master-tab', masterTab, {
        instantiate: false
      });
      application.deferReadiness();
      masterTab.setup().then(() => {
        masterTab.initialized = true;
        application.advanceReadiness();
      });
    }
  }
  var _default = _exports.default = {
    name: 'master-tab',
    initialize
  };
});