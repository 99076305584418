import RESTAdapter from '@ember-data/adapter/rest';
import ENV from 'accounts-app/config/environment';

export default class CountryAdapter extends RESTAdapter {
  host = ENV.APP.OPEN_DATA_API;

  urlForFindAll() {
    return `${this.host}/countries.json`;
  }
}
