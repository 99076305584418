import Model, { attr } from '@ember-data/model';

export default class ProductModel extends Model {
  @attr('string') productNumber;
  @attr('string') name;
  @attr('string') description;
  @attr('string') provider;
  @attr('string') sku;
  @attr('string') billableSku;
  @attr('string') sku;
  @attr('string') sku;
  @attr('boolean', { defaultValue: false }) isBillable;
}
