import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class MainAgreementController extends Controller {
  @service layout;
  @service snackbar;
  @service store;
  @service intl;
  @service sessionData;
  @service router;

  queryParams = ['product', 'priceLevel'];
  @tracked product = null;
  @tracked priceLevel = null;

  @tracked agreementItemsFilter = 'MinimumQuantity ge 0';

  queryParams = ['product', 'priceLevel'];
  @tracked product = null;
  @tracked priceLevel = null;

  @tracked
  isEditingEInvoiceDescription = false;

  @tracked
  openMenuAgreements = false;

  @tracked
  agreementsAvailable = [];

  productFilters = A([
    {
      id: '0',
      text: this.intl.t('agreement.productListCard.filter_all'),
    },
    {
      id: '1',
      text: this.intl.t('agreement.productListCard.filter_minimumQuantity'),
    },
  ]);

  @tracked
  productFilterChoice = this.productFilters[1];

  get showProductPriceRates() {
    return this.product != null && this.priceLevel != null;
  }
  @action
  transitionToAgreement({ detail }) {
    const { id } = detail?.option;

    return this.router.transitionTo('accounts.agreements.view', id);
  }

  @action
  agreementItemsTabActivated({ detail }) {
    if (detail?.chipId === '0' && detail?.selected) this.agreementItemsFilter = null;
    else if (detail?.chipId === '1' && detail?.selected) this.agreementItemsFilter = 'MinimumQuantity ge 0';
    this.productFilterChoice = this.productFilters[detail?.chipId];
  }

  @action
  closedProductPriceRates() {
    this.set('product', null);
    this.set('priceLevel', null);
  }

  @task
  *editEInvoiceDescription(model) {
    try {
      yield model.save({ adapterOptions: { accountId: model.account.accountNumber } });
      this.snackbar.show({ message: 'Descrição da conta salva com sucesso!' });
    } catch (error) {
      model.rollbackAttributes();
    }
    this.isEditingEInvoiceDescription = false;
  }

  @action
  cancelEInvoiceDescriptionEdit(model) {
    model.rollbackAttributes();
    this.isEditingEInvoiceDescription = false;
  }

  @action
  toggleMenuAgreements(model) {
    this.openMenuAgreements = true;

    if (this.openMenuAgreements && !this.agreementsAvailable.length) this.getAgreementsAvailable.perform(model);
  }

  @task({ drop: true })
  *getAgreementsAvailable(model) {
    this.agreementsAvailable = yield this.store.query('agreement', {
      accountId: model.agreement.account.accountNumber,
    });
  }
}
