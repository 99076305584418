import BaseOdataSerializer from './base-odata';
import { isPresent } from '@ember/utils';

export default class PaymentMethodSerializer extends BaseOdataSerializer {
  serialize({ record }) {
    let json = super.serialize(...arguments);

    if (json.type === 'CreditCard') {
      json.details = {
        name: record?.card?.name,
      };
    }
    return json;
  }

  serializeIntoHash(hash, type, record, options) {
    // remove modelName from payload
    return Object.assign(hash, this.serialize(record, options));
  }

  serializeAttribute(snapshot, json, key, attributes) {

    // Remove empty attrs
    if (isPresent(snapshot?.record[key])) {
      super.serializeAttribute(...arguments);
    }
  }
}
