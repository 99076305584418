import { attr } from '@ember-data/model';
import { fragment, fragmentArray } from 'ember-data-model-fragments/attributes';
import Fragment from 'ember-data-model-fragments/fragment';

export default class UsageSummaryServicesModel extends Fragment {
  @attr('string') resource;
  @attr('string') provider;

  @fragmentArray('usage-summary/actions') actions;

  @fragment('usage-summary/totals') totals;
}
