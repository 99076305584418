define("ember-svg-jar/inlined/logo-instantpayment-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#EFEFEF\"/><path fill=\"#32BCAD\" d=\"M16.784 10.871l-1.208-1.207a.228.228 0 01-.085.017h-.55a1.09 1.09 0 00-.763.318L12.6 11.571a.757.757 0 01-1.07 0L9.95 9.99a1.087 1.087 0 00-.763-.318H8.51a.229.229 0 01-.081-.016L7.214 10.87a1.597 1.597 0 000 2.257l1.214 1.214a.234.234 0 01.082-.016h.676c.286-.002.56-.116.763-.318l1.582-1.58a.774.774 0 011.07 0l1.575 1.576c.202.202.477.316.763.317h.55a.21.21 0 01.085.018l1.208-1.208a1.595 1.595 0 000-2.257\"/><path fill=\"#32BCAD\" d=\"M9.186 9.21a1.533 1.533 0 011.091.452l1.582 1.582a.293.293 0 00.414 0l1.575-1.573a1.531 1.531 0 011.092-.452h.19l-2.002-2.004a1.597 1.597 0 00-2.257 0L8.876 9.21h.31zm5.754 5.574a1.534 1.534 0 01-1.092-.452l-1.575-1.576a.3.3 0 00-.414 0l-1.582 1.582a1.537 1.537 0 01-1.09.451h-.311l1.995 1.996a1.593 1.593 0 002.257 0l2.001-2.001h-.19z\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});