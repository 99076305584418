import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class MainPaymentController extends Controller {
  @service layout;

  // Collapse
  @tracked isDialogPaymentCheckoutVisible = false;
}
