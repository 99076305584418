import Model, { attr, belongsTo } from '@ember-data/model';
import { computed } from '@ember/object';
import WebhooksEnum from './enums/webhooks';
import { A } from '@ember/array';

export default class WebhookModel extends Model {
  @belongsTo webhook;

  @attr('string') uri;
  @attr('string') secret;
  @attr('string', { defaultValue: 'json' }) contentType;
  @attr('string', { defaultValue: 'Active' }) status;
  @attr('string', { defaultValue: 'v2' }) version;
  @attr('boolean', { defaultValue: false }) insecureSsl;

  @attr('date') createdOn;
  @attr('date') modifiedOn;

  @attr('array') filters;
  @attr() subscription;
  @attr() encryption;

  get webhookId() {
    return this.id;
  }

  @computed('contentType')
  get type() {
    const contentTypes = A([
      {
        value: 'json',
        text: 'application/json',
      },
      {
        value: 'form-urlencoded',
        text: 'application/x-www-form-urlencoded',
      },
      {
        value: 'json-web-encryption',
        text: 'application/jose',
      },
    ]);

    return contentTypes.find((type) => type.value === this.contentType);
  }

  @computed('status')
  get isActive() {
    return this.status === 'Active';
  }

  @computed('insecureSsl')
  get isSecureSsl() {
    return !this.insecureSsl;
  }

  @computed('filters')
  get eventsType() {
    return Object.keys(
      Object.groupBy(
        WebhooksEnum.events.filter((event) => this.filters.includes(event.id)),
        ({ type }) => type
      )
    );
  }

  // JWE getter and setter
  get client() {
    return this.encryption?.jweClientId;
  }

  set client(value) {
    this.encryption = { jweClientId: value };
  }
}
