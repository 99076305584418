import Component from 'ember-cli-mdc-base/component';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class MdcDropdownComponent extends Component {
  @tracked
  value = this.args.value;

  @tracked
  isOpen = false;

  @action
  open() {
    this.isOpen = true;
  }

  @action
  close() {
    this.isOpen = false;
  }

  @action
  toggleMenu() {
    this.isOpen = !this.isOpen;
  }

  //TODO: check why select is being called twice
  @action
  select(item, index) {
    const selected = this.options[index];

    this.value = selected;

    this.args.select(selected);

    this.toggleMenu();
  }

  get options() {
    return A(this.args.options);
  }

  get valueKey() {
    return this.args.valueKey || 'value';
  }

  get textKey() {
    return this.args.textKey || 'text';
  }

  get styleClassName() {
    const { style } = this.args;

    if (!style) return;

    return `mdc-dropdown--${style}`;
  }
}
