import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';

export default class SubscriptionViewRoute extends BaseAuthenticatedRoute {
  @service store;
  @service layout;

  async model({ accountId, subscriptionId }) {
    accountId = accountId ?? this.paramsFor('accounts.subscriptions').accountId;
    subscriptionId = subscriptionId ?? this.paramsFor('accounts.subscriptions.view').subscriptionId;
    return this.store.findRecord('subscription', subscriptionId, {
      adapterOptions: {
        accountId,
      },
    });
  }
}
