import RESTSerializer from '@ember-data/serializer/rest';

export default class UserSerializer extends RESTSerializer {
  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload) {
      payload = { user: payload };
      payload.user.id = payload.user.email;
    }

    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
