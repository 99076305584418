define("ember-svg-jar/inlined/logo-bankslip-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_2642_6045)\"><path fill=\"#EFEFEF\" d=\"M0-4h24v24H0z\"/><path fill=\"#103541\" fill-rule=\"evenodd\" d=\"M5.333 4H6v8h-.667V4zm1.334 0H8v8H6.667V4zm2.667 0H10v8h-.666V4zm1.333 0h2v8h-2V4zm2.667 0H14v8h-.666V4zm2 0H16v8h-.666V4zm1.333 0h.666v8h-.666V4zM18 4h.667v8H18V4z\" clip-rule=\"evenodd\"/></g><defs><clipPath id=\"clip0_2642_6045\"><path fill=\"#fff\" d=\"M0 2a2 2 0 012-2h20a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\"/></clipPath></defs>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 24 16"
    }
  };
});