import Helper from '@ember/component/helper';

export default class WindowOpenHelper extends Helper {
  compute([url]) {
    const fn = (maybeEvents) => {
      maybeEvents = !Array.isArray(maybeEvents) ? [maybeEvents] : maybeEvents;
      const maybeEvent = maybeEvents[0];

      if (!url && maybeEvent.type !== 'click') return;

      var myWindow = window.open(window.location.href + '?printerFriendly=true', new Date().getTime());
      myWindow.location.href = url;
      myWindow.focus();
    };

    return fn;
  }
}
