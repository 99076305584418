import Model, { attr } from '@ember-data/model';
import Enums from '../enums/accesskeys-info';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ApikeyGrantModel extends Model {
  @service store;
  @service snackbar;

  @attr('string') clientId;
  @attr('string') key;
  @attr('string') consumedTime;
  @attr('string') creationTime;
  @attr('string') data;
  @attr('string') description;
  @attr('string') expiration;
  @attr('string') sessionId;
  @attr('string') subjectId;
  @attr('string') type;

  getAccessKeyInfo() {
    return Enums.accessKeysInfo.filter((item) => {
      let value = this.description;
      return value === item.id;
    })[0];
  }

  @task({ drop: true })
  *setStatus(newStatus) {
    return yield this.store
      .adapterFor('apikey/grant')
      [newStatus](this)
      .then((data) => {
        this.data = data?.accounts?.status;
        this.snackbar.show({
          message: `Chave de acesso ${this.data == 'Active' ? 'ativada' : 'desativada'} com sucesso!`,
        });
        return data;
      });
  }
}
