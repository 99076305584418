import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';

export default class AgreementViewRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ accountId, agreementId }) {
    accountId = accountId ?? this.paramsFor('accounts.agreements').accountId;
    agreementId = agreementId ?? this.paramsFor('accounts.agreements.view').agreementId;
    return await this.store.findRecord('agreement', agreementId, { adapterOptions: { accountId } });
  }
}
