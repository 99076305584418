import Model, { attr } from '@ember-data/model';
import { fragmentArray } from 'ember-data-model-fragments/attributes';

export default class UsageSummaryModel extends Model {
  @attr('string') accountId;
  @attr('string') subscriptionId;
  @attr('date') lastUpdatedOn;
  @attr('date') usedOn;

  @fragmentArray('usage-summary/services') services;
}
