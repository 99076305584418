import Model, { attr } from '@ember-data/model';

export default class ExportModel extends Model {
  @attr('string') accountId;
  @attr('string') subscriptionId;
  @attr('date') lastUpdatedOn;
  @attr('date') usedOn;

  @attr('date') beginOn;
  @attr('date') createdOn;
  @attr('date') endOn;
  @attr('date') exportOn;
  @attr('date') modifiedOn;
  @attr('string') resource;
  @attr('string') status;
  @attr('string') stage;

  @attr('number') totalLines;

  @attr() fileBlob;
}
