import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';

export default class AgreementProductsRoute extends BaseAuthenticatedRoute {
  @service store;

  async model({ agreementId }) {
    const accountId = this.paramsFor('accounts').accountId;
    const agreement = await this.store.queryRecord('agreement', {
      accountId,
      agreementId,
    });
    const account = await this.store.findRecord('account', accountId);

    return { agreement, account };
  }
}
