define("ember-svg-jar/inlined/logo-unknown-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<g clip-path=\"url(#clip0_1470:12591)\"><path fill=\"#F4F4F4\" d=\"M0-6h36v36H0z\"/></g><defs><clipPath id=\"clip0_1470:12591\"><path d=\"M0 2a2 2 0 012-2h32a2 2 0 012 2v20a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\" fill=\"#fff\"/></clipPath></defs>",
    "attrs": {
      "width": "36",
      "height": "24",
      "viewBox": "0 0 36 24",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});