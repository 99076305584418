import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class LoadersSubscriptionsComponent extends Component {
  @service store;

  @arg accountId;
  @arg top;
  @arg skip;

  @tracked subscriptions = [];
  @tracked error = false;

  @task({ drop: true })
  *getSubscriptions() {
    try {
      this.subscriptions = yield this.store.query('subscription', {
        accountId: this.accountId,
      });
      return;
    } catch (error) {
      this.error = true;
    }
  }
}
