import AccountAdapter from './account';

export default class PaymentMethodAdapter extends AccountAdapter {
  _getAccountPath(accountId) {
    return super.urlForFindRecord(accountId, 'account');
  }

  query(store, type, query, recordArray, adapterOptions) {
    const buildURL = this.buildURL(type.modelName, null, null, 'query', query);
    const url = this.getODataFullUrl(buildURL, query);
    return this.ajax(url);
  }

  urlForQuery({ accountId }, modelName) {
    const accountPath = this._getAccountPath(accountId);
    let url = `${accountPath}/PaymentMethods`;
    return url;
  }

  urlForCreateRecord(modelName, snapshot) {
    const accountPath = this._getAccountPath(snapshot.record.accountId);
    return `${accountPath}/PaymentMethods`;
  }

  urlForFindRecord(id, modelName, snapshot) {
    const accountPath = this._getAccountPath(snapshot.accountId);
    return `${accountPath}/Payments('${id}))`;
  }

  urlForDeleteRecord(id, modelName, snapshot) {
    const accountPath = this._getAccountPath(snapshot.adapterOptions.accountId);
    return `${accountPath}/PaymentMethods('${id}')`;
  }

  async getPaymentMethods(accountId) {
    const accountPath = this._getAccountPath(accountId);

    let url = `${accountPath}/PaymentMethods`;

    const response = await this.ajax(url);
    return response.value;
  }

  async setCardPreference(accountId, paymentId, data) {
    const accountPath = this._getAccountPath(accountId);
    let url = `${accountPath}/PaymentMethods('${paymentId}')/Api.SetCardPreference`;
    return this.ajax(url, 'POST', { data });
  }
}
