define("ember-cli-mdc-layout-grid/components/mdc-layout-grid-cell", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/debug", "@ember/template-factory"], function (_exports, _component, _component2, _object, _debug, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer ? (Object.defineProperty(i, e, a), null) : a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mdc-layout-grid__cell
    {{if @alignment this.alignmentClassName}}
    {{if @order this.orderClassName}}
    {{if @columns this.columnsClassName}}
    {{if @phoneColumns this.phoneColumnsClassName}}
    {{if @tabletColumns this.tabletColumnsClassName}}
    {{if @desktopColumns this.desktopColumnsClassName}}" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "nfNv4x3V",
    "block": "[[[11,0],[16,0,[29,[\"mdc-layout-grid__cell\\n  \",[52,[30,1],[30,0,[\"alignmentClassName\"]]],\"\\n  \",[52,[30,2],[30,0,[\"orderClassName\"]]],\"\\n  \",[52,[30,3],[30,0,[\"columnsClassName\"]]],\"\\n  \",[52,[30,4],[30,0,[\"phoneColumnsClassName\"]]],\"\\n  \",[52,[30,5],[30,0,[\"tabletColumnsClassName\"]]],\"\\n  \",[52,[30,6],[30,0,[\"desktopColumnsClassName\"]]]]]],[17,7],[12],[1,\"\\n  \"],[18,8,null],[1,\"\\n\"],[13]],[\"@alignment\",\"@order\",\"@columns\",\"@phoneColumns\",\"@tabletColumns\",\"@desktopColumns\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-cli-mdc-layout-grid/components/mdc-layout-grid-cell.hbs",
    "isStrictMode": false
  });
  const ALIGNMENTS = ['top', 'middle', 'bottom'];
  function span(argument, device) {
    return (0, _object.computed)(`args.${argument}`, function () {
      let columns = this.args[argument];
      (true && !(columns >= 1 && columns <= 12) && (0, _debug.assert)(`The ${columns} must be between 1 and 12.`, columns >= 1 && columns <= 12));
      return `mdc-layout-grid__cell--span-${columns}${!!device ? `-${device}` : ''}`;
    });
  }
  let MdcLayoutGridCellComponent = _exports.default = (_dec = span('columns'), _dec2 = span('phoneColumns', 'phone'), _dec3 = span('tabletColumns', 'tablet'), _dec4 = span('desktopColumns', 'desktop'), _class = class MdcLayoutGridCellComponent extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "columnsClassName", _descriptor, this);
      _initializerDefineProperty(this, "phoneColumnsClassName", _descriptor2, this);
      _initializerDefineProperty(this, "tabletColumnsClassName", _descriptor3, this);
      _initializerDefineProperty(this, "desktopColumnsClassName", _descriptor4, this);
    }
    get alignmentClassName() {
      let {
        alignment
      } = this.args;
      (true && !(ALIGNMENTS.includes(alignment)) && (0, _debug.assert)(`The alignment must be one of the following values.`, ALIGNMENTS.includes(alignment)));
      return `mdc-layout-grid__cell--align-${alignment}`;
    }
    get orderClassName() {
      let {
        order
      } = this.args;
      (true && !(order >= 1 && order <= 12) && (0, _debug.assert)('The order must be between 1 and 12.', order >= 1 && order <= 12));
      return `mdc-layout-grid__cell--order-${order}`;
    }
  }, _descriptor = _applyDecoratedDescriptor(_class.prototype, "columnsClassName", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "phoneColumnsClassName", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "tabletColumnsClassName", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "desktopColumnsClassName", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, MdcLayoutGridCellComponent);
});