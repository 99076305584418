define("ember-svg-jar/inlined/logo-bankslip-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#EFEFEF\"/><path fill=\"#103541\" fill-rule=\"evenodd\" d=\"M5.333 8H6v8h-.667V8zm1.334 0H8v8H6.667V8zm2.667 0H10v8h-.666V8zm1.333 0h2v8h-2V8zm2.667 0H14v8h-.666V8zm2 0H16v8h-.666V8zm1.333 0h.666v8h-.666V8zM18 8h.667v8H18V8z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});