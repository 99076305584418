import BaseOdataAdapter from './base-odata';

export default class ProductPriceRateAdapter extends BaseOdataAdapter {
  // query(store, type, query, recordArray, adapterOptions) {
  //   const buildURL = this.buildURL(type.modelName, null, null, 'query', query);
  //   const url = this.getODataFullUrl(buildURL, query);
  //   return this.ajax(url);
  // }

  urlForQueryRecord({ priceLevelId, productId }, modelName) {
    const url = `${this.host}/PriceLevels('${priceLevelId}')/Api.GetProductPriceRates(productId=${productId})`;
    return url;
  }
}
