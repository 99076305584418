import Model, { attr } from '@ember-data/model';

export default class OrderActionDetailModel extends Model {
  @attr('string') accountId;
  @attr('string') orderId;
  @attr('string') purchaseOrderNumber;
  @attr('string') description;
  @attr('string') buyerName;
  @attr('string') buyerEmailAddress;
}
