import Service, { inject as service } from '@ember/service';

export default class LeadsService extends Service {
  @service store;

  async sendLeads(
    name,
    lastname,
    email,
    phone,
    company,
    como_podemos_te_ajudar,
    faixa_quantidade_mensal,
    cargo_do_contato,
    area_de_atuacao,
    segmento_empresa,
    outros_segmentos = ''
  ) {
    let options = {
      source: 'App Register',
      name,
      lastname,
      email,
      phone,
      company,
      analytics_source_data_3: 'Cadastro no account (account.nfe.io)',
      como_podemos_te_ajudar,
      faixa_quantidade_mensal,
      cargo_do_contato,
      area_de_atuacao,
      segmento_empresa,
      outros_segmentos
    }
    return this.store.adapterFor('leads').sendLeads(options);
  }
}
