import Helper from '@ember/component/helper';

export default class UrlParse extends Helper {
  compute([url]) {
  if (!url.startsWith('http')) {
    return `http://${url}`;
  }
  return url
  }
}
