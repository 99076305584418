import Model, { attr } from '@ember-data/model';
import validateCreditCard from 'accounts-app/validators/credit-card';

import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';

const validations = {
  name: validateCreditCard({ type: 'cardholderName' }),
};

export default class CardModel extends Model {
  changeset = null;

  constructor() {
    super(...arguments);
    this.changeset = new Changeset(this, lookupValidator(validations), validations);
  }

  @attr('string') number;
  @attr('string') expiration;
  @attr('string') securityCode;
  @attr('string') name;
}
