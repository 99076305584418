define("ember-cli-mdc-layout-grid/components/mdc-layout-grid-inner", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mdc-layout-grid__inner" ...attributes>
    {{yield}}
  </div>
  */
  {
    "id": "yvwp5g4D",
    "block": "[[[11,0],[24,0,\"mdc-layout-grid__inner\"],[17,1],[12],[1,\"\\n  \"],[18,2,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-cli-mdc-layout-grid/components/mdc-layout-grid-inner.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});