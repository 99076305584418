define("ember-svg-jar/inlined/logo-americanexpress-rounded", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"12\" cy=\"12\" r=\"12\" fill=\"#016FD0\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M6.887 14.5l-.294-.748H5.73l-.296.748H3l2.149-4.998h2.05l.374.871V9.5l2.563.01.41 1.144.403-1.152h5.937l.595.769.597-.769h2.758l-2.008 2.5 1.988 2.498h-2.8l-.559-.732-.545.732H6.887z\" clip-rule=\"evenodd\"/><path fill=\"#016FD0\" fill-rule=\"evenodd\" d=\"M13.537 10.132v3.733h3.06l.857-1.15.878 1.15h1.175L18.023 12l1.498-1.867h-1.13l-.907 1.168-.9-1.164v-.004h-3.046zm2.537.835H14.48v.639h1.586v.786H14.48v.663h1.61l.831-1.033-.847-1.055zM3.963 13.865l1.605-3.733h1.217l1.423 3.313v-3.313l1.485.006.864 2.406.844-2.412h1.473v3.733h-.933v-2.75l-.99 2.75h-.818l-.992-2.75v2.75h-1.82l-.295-.747H5.304l-.296.747H3.963zm2.724-1.558l-.525-1.306-.526 1.306h1.05z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "24",
      "fill": "none",
      "viewBox": "0 0 24 24"
    }
  };
});