import Model, { attr, belongsTo } from '@ember-data/model';
import { isPresent } from '@ember/utils';

export default class ContactModel extends Model {
  @attr('string') type;
  @attr('string') email;
  @attr('string') phone;

  @belongsTo('account') account;

  get isValid() {
    return isPresent(this.phone.replace('+55', '')) && isPresent(this.email);
  }
}
