define("ember-svg-jar/inlined/payment-refused", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<circle cx=\"52\" cy=\"52\" r=\"52\" fill=\"#FFECEC\"/><path d=\"M56.465 52l8.9-8.28a3.151 3.151 0 00.082-4.536l-.631-.632a3.152 3.152 0 00-4.536.082l-8.28 8.9-8.28-8.9a3.152 3.152 0 00-4.535-.082l-.632.632a3.151 3.151 0 00.082 4.535l8.9 8.28-8.9 8.28a3.152 3.152 0 00-.082 4.536l.632.631a3.151 3.151 0 004.535-.082l8.28-8.9 8.28 8.9a3.152 3.152 0 004.536.082l.631-.631a3.152 3.152 0 00-.082-4.536l-8.9-8.28z\" fill=\"#FF4C4C\"/>",
    "attrs": {
      "width": "104",
      "height": "104",
      "viewBox": "0 0 104 104",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});