import { attr } from '@ember-data/model';
import { computed } from '@ember/object';
import Fragment from 'ember-data-model-fragments/fragment';

export default class UsageSummaryTotalsModel extends Fragment {
  @attr('number', { defaultValue: 0 }) OK;
  @attr('number', { defaultValue: 0 }) BadRequest;
  @attr('number', { defaultValue: 0 }) Error;
  @attr('number', { defaultValue: 0 }) NotFound;
  @attr('number', { defaultValue: 0 }) TimedOut;
  @attr('number', { defaultValue: 0 }) Duplicated;
  @attr('number', { defaultValue: 0 }) Unavailable;

  @computed('BadRequest', 'Error', 'TimedOut', 'Duplicated', 'Unavailable')
  get Errors() {
    let errors = [this.BadRequest, this.Error, this.TimedOut, this.Duplicated, this.Unavailable];
    errors = errors.filter(Number);
    return errors.length > 0 ? errors.reduce((accumulator, currentValue) => accumulator + currentValue) : null;
  }

  @computed('OK', 'NotFound', 'Errors')
  get Total() {
    let totals = [this.OK, this.NotFound, this.Errors];
    totals = totals.filter(Number);
    return totals.length > 0 ? totals.reduce((accumulator, currentValue) => accumulator + currentValue) : null;
  }

  @computed('OK', 'NotFound')
  get TotalBillable() {
    let totals = [this.OK, this.NotFound];
    totals = totals.filter(Number);
    return totals.length > 0 ? totals.reduce((accumulator, currentValue) => accumulator + currentValue) : null;
  }
}
