import Route from '@ember/routing/route';
import { debug } from '@ember/debug';
import { service } from '@ember/service';
import { storageFor } from 'ember-local-storage';

export default class ApplicationRoute extends Route {
  @service metrics;
  @service router;
  @service user;
  @service session;
  @storageFor('settings') settings;
  @service sessionData;

  constructor() {
    super(...arguments);
    this.router.on('routeDidChange', (a) => {
      const page = this.router.currentURL;
      const title = this.router.currentRouteName || 'unknown';
      this.metrics.trackPage({ page, title });

      if(window.clarity) {
        const user = this.session?.data?.current_user;
        const account = this.sessionData?.account?.current;
        const subscription = this.sessionData?.subscription?.current;
        window.clarity("set", "userId", user?.sub ?? '');
        window.clarity("set", "account", [account?.accountNumber, subscription?.id]);
      }

      if(title !== 'login')
        this.settings.set('intentUrl', this.router.currentURL)
    });
  }

  async beforeModel() {
    super.beforeModel(...arguments);
    await this.session.setup();
    await this.user.manageLanguage();
    debug('application-setup-controller: session setup and user manage language');
  }
}
