import Model, { attr } from '@ember-data/model';

export default class UserModel extends Model {
  @attr('string') sub;
  @attr('string') name;
  @attr('string') locale;
  @attr('string') family_name;
  @attr('string') given_name;
  @attr('string') email;
  @attr('boolean') email_verified;
  @attr('string') phone_number;
  @attr('boolean') phone_number_verified;
  role;
  scope;
}
