import ENV from '../config/environment';
import Configuration from 'nfeio-ember-implicit-auth/configuration';

export function initialize(application) {
  const config = ENV['nfeio-ember-implicit-auth'] || {};
  Configuration.load(config);

  application.inject('route', 'implicitGrantAuthenticator', 'service:implicit-grant-authenticator');
}

export default {
  name: 'nfeio-ember-implicit-auth',
  initialize
};