define("ember-svg-jar/inlined/logo-santander-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#FF0A00\" d=\"M0 2a2 2 0 012-2h20a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\"/><path fill=\"#FF5A5A\" d=\"M6 2h12v12H6z\" opacity=\".01\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M10.96 5.07s-.296.264-.387.467a1.9 1.9 0 00.181 1.82c.403.596.742 1 1.043 1.432.545.782.762 1.615.117 2.28-.047-.783-.713-1.468-.713-1.468S9.97 8.168 9.905 7.666c-.696.107-1.4.455-2.202.957-.453.337-1.295.978-.695 2.075.6 1.096 2.847 1.766 3.914 1.792.41.01 1.04.069 1.747.025 1.13-.07 2.457-.33 3.391-.915 1.518-.952 1.435-2.235.252-3.06a5.073 5.073 0 00-1.417-.687 9.964 9.964 0 00-.8-.187s.172-.794-.392-1.461c-.563-.668-1.835-2.106-1.53-3.005-.333.081-.975 1.195-.123 2.518.853 1.324 1.24 1.442 1.381 2.132.141.69-.067 1.408-.408 1.52.133-.692-.314-1.105-.5-1.468-.184-.364-1.34-1.252-1.494-2.184a6.076 6.076 0 01-.069-.649z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 24 16"
    }
  };
});