import Controller from '@ember/controller';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class MgmtAccountsController extends Controller {
  @service layout;

  queryParams = ['search', 'top', 'skip'];

  @tracked search = '';
  @tracked top = 10;
  @tracked skip = 0;

  @action
  nextPage() {
    this.skip += this.top;
  }

  @action
  previousPage() {
    this.skip -= this.top;
  }

  @action
  changePerPage(value) {
    this.skip = 0;
    this.top = value;
  }
}
