import { isNone, isPresent } from '@ember/utils';

import Mark from 'mark.js';
import Modifier from 'ember-modifier';
import { registerDestructor } from '@ember/destroyable';

function cleanup(instance) {
  if (isPresent(instance)) {
    // instance.unmark();
  }
}

export default class MarkModifier extends Modifier {
  /// Instance of the Mark object.
  instance = null;

  modify(element, [event, handler]) {

    if (isNone(this.instance)) {
      this.instance = new Mark(element);
    }

    // We always unmark the current marking.
    this.instance.unmark();

    if (isPresent(event)) {
      this.instance.mark(event);
    }
    registerDestructor(this, cleanup);
  }

}
