import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class AgreementAdapter extends BaseOdataAdapter {
  urlForFindRecord(agreementId, modelName, snapshot) {
    const accountId = snapshot.adapterOptions.accountId;

    let url = `${this.host}/accounts('${accountId}')/agreements('${agreementId}')`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/${accountId}/agreements/${agreementId}`;
    }

    return url;
  }

  urlForUpdateRecord(agreementId, modelName, snapshot) {
    const accountId = snapshot.adapterOptions.accountId;

    let url = `${this.host}/accounts('${accountId}')/agreements('${agreementId}')`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/${accountId}/agreements/${agreementId}`;
    }

    return url;
  }

  query(store, type, query, recordArray, adapterOptions) {
    const buildURL = this.buildURL(type.modelName, null, null, 'query', query);
    const url = this.getODataFullUrl(buildURL, query);
    return this.ajax(url);
  }

  urlForQuery({ accountId }, modelName) {
    let url = `${this.host}/accounts('${accountId}')/agreements`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/agreements`;
    }

    return url;
  }
}
