import Model, { attr } from '@ember-data/model';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class ProviderModel extends Model {
  @service store;
  @service snackbar;
  @attr('string') namespace;
  @attr('string') status;

  get isActive() {
    return this.status == 'Active';
  }

  @task({ drop: true })
  *setStatus(newStatus, subscriptionId) {
    this.subscriptionId = subscriptionId;
    yield this.store
      .adapterFor('provider')
      [newStatus](this)
      .then((data) => {
        this.status = data?.provider?.status;
        this.snackbar.show({
          message: `Funcionalidade ${this.status == 'Active' ? 'ativada' : 'desativada'} com sucesso!`,
        });
        return data;
      });
  }
}
