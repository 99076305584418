define("ember-master-tab/consts", ["exports", "ember-uuid"], function (_exports, _emberUuid) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.tabIdKey = _exports.tabId = _exports.shouldInvalidateMasterTabKey = _exports.namespace = void 0;
  const namespace = _exports.namespace = 'ember-master-tab:';
  const tabIdKey = _exports.tabIdKey = `${namespace}tab-id`;
  const shouldInvalidateMasterTabKey = _exports.shouldInvalidateMasterTabKey = `${namespace}should-invalidate-master-tab`;
  const tabId = _exports.tabId = (0, _emberUuid.v4)();
});