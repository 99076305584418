import Controller from '@ember/controller';
import { service } from '@ember/service';
import { action } from '@ember/object';

export default class NotFoundController extends Controller {
  @service router;

  @action
  async goToAccount() {
    this.router.transitionTo('/');
  }
}
