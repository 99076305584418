import ENV from 'accounts-app/config/environment';
import Route from '@ember/routing/route';
import { service } from '@ember/service';

export default class BaseAuthenticatedRoute extends Route {
  @service session;
  @service user;

  async beforeModel(transition) {
    if(this.session.isAuthenticated) {
      await this.user.manageLanguage();
    }
    return this.session.requireAuthentication(transition, 'login');
  }
}
