import Model, { attr } from '@ember-data/model';

export default class CountryModel extends Model {
  @attr('string') bacenCode;
  @attr('string') dialCode;
  @attr('string') englishName;
  @attr('string') fifa;
  @attr('string') frenchName;
  @attr('string') isoCode2;
  @attr('string') m49Code;
  @attr('string') portugueseName;
  @attr('string') russianName;
  @attr('string') spanishName;
}
