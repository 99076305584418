define("ember-svg-jar/inlined/logo-itau-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#EC7600\" d=\"M0 2a2 2 0 012-2h20a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\"/><path fill=\"#1D3F89\" fill-rule=\"evenodd\" d=\"M7.828 2h8.344C17.182 2 18 2.818 18 3.828v8.344c0 1.01-.818 1.828-1.828 1.828H7.828A1.828 1.828 0 016 12.172V3.828C6 2.818 6.818 2 7.828 2z\" clip-rule=\"evenodd\"/><path fill=\"#F4E800\" fill-rule=\"evenodd\" d=\"M15.141 8.884h.667L16.573 8h-.833l-.599.884zm-7.14-.868H6.93v4.137H8V8.017zm4.055 1.621c-.71-.024-.965.216-.965.216l-.284-.557s.342-.198.522-.258.825-.214 1.42.011c.383.154.643.407.724.92.012.565 0 2.133 0 2.133h-.91v-.285s0 .043-.347.208c-.346.165-.915.134-1.2-.09-.284-.225-.534-.51-.374-1.069.159-.558.753-.725.946-.76.194-.034.947-.019.947-.019s-.022-.43-.479-.45zm.512 1.015v.495s-.49.589-.896.305c-.196-.137-.205-.335-.125-.496.087-.174.29-.3.427-.304.263-.008.594 0 .594 0zm4.484-1.586h-.999s.016 1.387 0 1.72c-.015.332-.097.58-.554.622-.454.041-.545-.342-.566-.433v-.001c-.03-.87-.02-1.908-.02-1.908h-.938s-.035 1.828.04 2.31c.076.483.631.707 1.22.727.59.02.92-.45.92-.45v.467h.896V9.066zM9.719 11.05V9.747l.59.007v-.71h-.582V8.022H8.79v1.03h-.458v.695h.454v.82c-.068.832.162 1.298.453 1.471.292.173 1.032.017 1.032.017v-.681c.003-.002 0-.004 0-.004v.004c-.008.004-.05.008-.237-.004-.273-.02-.316-.32-.316-.32z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 24 16"
    }
  };
});