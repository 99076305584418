import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class InvoiceAdapter extends BaseOdataAdapter {
  query(store, type, query, recordArray, adapterOptions) {
    query.$orderby = 'effectiveOn desc';

    const buildURL = this.buildURL(type.modelName, null, null, 'query', query);
    const url = this.getODataFullUrl(buildURL, query);
    return this.ajax(url);
  }

  urlForQuery({ accountId }, modelName) {
    let url = `${this.host}/accounts('${accountId}')/invoices`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/invoices`;
    }

    return url;
  }

  urlForQueryRecord({ accountId, invoiceId }, modelName) {
    let url = `${this.host}/accounts('${accountId}')/invoices(${invoiceId})?$expand=items,eInvoices`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/invoices/${invoiceId}`;
    }

    return url;
  }

  downloadInvoice(accountId, invoiceId) {
    const url = `${this.host}/accounts('${accountId}')/invoices(${invoiceId})/api.exportpdf`;
    return window.open(url);
  }

  async downloadEInvoice(accountId, invoiceId, type) {
    const url = `${this.host}/accounts('${accountId}')/einvoices`;
    const query = `?$filter=invoiceid eq ${invoiceId}`;

    const eInvoices = await this.ajax(url + query);
    const eInvoiceId = eInvoices.value[0].id;

    let eInvoiceUrl = '';

    if (type === 'pdf') {
      eInvoiceUrl = `${url}(${eInvoiceId})/Api.ExportPdf`;
    } else if (type === 'xml') {
      eInvoiceUrl = `${url}(${eInvoiceId})/Api.ExportXml`;
    }

    return window.open(eInvoiceUrl);
  }
}
