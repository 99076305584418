import { helper } from '@ember/component/helper';

/**
 * Replace a string with matches found from another string.
 *
 * @public
 * @function stringNormalize
 * @param {string} value The starting string.
 * @returns {string} The replaced string.
 */
export function stringNormalize([value = '']) {
  // ensure there is something to find
  if (!value) {
    return '';
  }

  // return updated string
  return value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

export default helper(stringNormalize);
