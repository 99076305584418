import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class NfeImpersonateCompanionComponent extends Component {
  @service sessionData;

  @action
  async impersonate({ accountId, subscriptionId }) {
    await this.sessionData.impersonate(accountId, subscriptionId);
  }
}
