import ApplicationAdapter from './application';
import ENV from 'accounts-app/config/environment';
import { service } from '@ember/service';

export default class WebhookAdapter extends ApplicationAdapter {
  @service sessionData;
  @service session;
  @service accessToken;

  host = ENV.APP.API_URL;
  namespace = 'webhooks';

  query(store, model, options) {
    let url = `${this.host}/${this.namespace}`;
    return super.ajax(url, 'GET', {
      headers: {
        'Accept-Version': options.version,
        Authorization: options.token,
      },
    });
  }

  queryRecord(store, model, options) {
    let url = `${this.host}/${this.namespace}/${options.webhookId}`;
    return super.ajax(url, 'GET', {
      headers: {
        'Accept-Version': options.version,
        Authorization: options.token,
      },
    });
  }

  updateRecord(store, model, { id, adapterOptions, __attributes }) {
    let url = `${this.host}/${this.namespace}/${id}`;
    return super.ajax(url, 'PUT', {
      headers: {
        Authorization: adapterOptions.token,
      },
      data: {
        webHook: __attributes,
      },
    });
  }

  createRecord(store, model, { id, adapterOptions, __attributes }) {
    let url = `${this.host}/${this.namespace}`;
    return super.ajax(url, 'POST', {
      headers: {
        Authorization: adapterOptions.token,
      },
      data: {
        webHook: __attributes,
      },
    });
  }

  deleteRecord(store, model, { id, adapterOptions }) {
    let url = `${this.host}/${this.namespace}/${id}`;
    return super.ajax(url, 'DELETE', {
      headers: {
        Authorization: adapterOptions.token,
      },
    });
  }
}
