import RESTSerializer from '@ember-data/serializer/rest';

export default class ApikeyClientSerializer extends RESTSerializer {
  primaryKey = 'value';

  normalizeResponse(store, primaryModelClass, payload, id, requestType) {
    if (payload) {
      payload['apikey/secret'] = payload;
    }
    return super.normalizeResponse(store, primaryModelClass, payload, id, requestType);
  }
}
