import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class ProductAdapter extends BaseOdataAdapter {
  urlForFindRecord(id, modelName, snapshot) {
    let url = `${this.host}/products(${id})`;

    if (ENV.environment === 'test') {
      url = `${this.host}/products/${id}`;
    }

    return url;
  }
}
