import Controller from '@ember/controller';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';

export default class WebhooksCreateController extends Controller {
  @service intl;
  @service snackbar;
  @service accessToken;
  @service router;

  @task({ drop: true })
  *cancel(model) {
    try {
      yield model.destroyRecord().then(() => {
        this.router.transitionTo('accounts.subscriptions.webhooks.index');
      });
    } catch (error) {
      console.error(error);
    }
  }

  @task({ drop: true })
  *save(model) {
    const token = yield this.accessToken.getByDescription('Nota Fiscal (api.nfe.io)', this.model?.subscription?.id);
    try {
      yield model.save({
        adapterOptions: {
          subscriptionId: model?.subscription?.id.replace('sub_', ''),
          token: token[0].key,
        },
      });
      this.router.transitionTo('accounts.subscriptions.webhooks.index');
      this.snackbar.show({ message: this.intl.t('webhooks.new.success') });
    } catch (error) {
      console.error(error);
      error.then((data) => {
        let err = data?.errors[0];
        let { status, message } = err;
        let errorMessage = this.intl.t('webhooks.new.error');

        //TODO: Transformar cadeia de if em um objeto [key/value] => [path/message]

        if (
          message &&
          message.includes('Please ensure that the WebHook URI is valid and that the endpoint is accessible')
        ) {
          errorMessage = this.intl.t('webhooks.error.validUrl');
        } else if (
          message &&
          message.includes("The WebHook URI must be absolute with a scheme of either 'http' or 'https'")
        ) {
          errorMessage = this.intl.t('webhooks.error.invalidSchema');
        } else if (message && message.includes('Invalid URI: The hostname could not be parsed.')) {
          errorMessage = this.intl.t('webhooks.error.invalidUri');
        } else if (message && message.includes('The Client ID is not valid.')) {
          errorMessage = this.intl.t('webhooks.error.invalidClientId');
        } else if (message && message.includes('The Client ID was not found at the authority server')) {
          errorMessage = this.intl.t('webhooks.error.clientIdNotFound');
        }

        if (status == '409') {
          errorMessage = this.intl.t('webhooks.error.alreadyExists');
        } else if (status == '504') {
          errorMessage = this.intl.t('webhooks.error.accessibleUrl');
        }

        this.snackbar.show({ message: errorMessage });
      });
    }
  }
}
