import BaseAuthenticatedRoute from '../../base-authenticated';
import { service } from '@ember/service';

export default class AccountsSubscriptionsIndexRoute extends BaseAuthenticatedRoute {
  @service router;
  @service store;

  async model({ accountId }) {
    accountId = accountId ?? this.paramsFor('accounts.subscriptions').accountId;
    return await this.store.findRecord('account', accountId);
  }
}
