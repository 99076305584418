import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class SubscriptionAdapter extends BaseOdataAdapter {
  // query(store, type, query, recordArray, adapterOptions) {
  //   const buildURL = this.buildURL(type.modelName, null, null, 'query', query);
  //   const url = this.getODataFullUrl(buildURL, query);
  //   return this.ajax(url);
  // }

  urlForQuery({ accountId }, modelName) {
    if (accountId) {
      let url = `${this.host}/accounts('${accountId}')/subscriptions`;
      if (ENV.environment === 'test') {
        url = `${this.host}/accounts/'${accountId}'/subscriptions`;
      }
      return url;
    }

    return `${this.host}/subscriptions`;
  }

  urlForQueryRecord({ accountId, subscriptionId }, modelName) {
    let url = `${this.host}/accounts('${accountId}')/subscriptions('${subscriptionId}')`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/subscriptions/${subscriptionId}`;
    }

    return url;
  }

  urlForFindRecord(subscriptionId, modelName, snapshot) {
    const accountId = snapshot?.adapterOptions?.accountId;

    let url = `${this.host}/accounts('${accountId}')/subscriptions('${subscriptionId}')`;

    if (ENV.environment === 'test') {
      url = `${this.host}/accounts/'${accountId}'/subscriptions/${subscriptionId}`;
    }

    return url;
  }
}
