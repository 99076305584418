import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AgreementProductsController extends Controller {
  @tracked
  isOpen = true;

  @tracked
  isSideSheetsOpen = false;

  @action
  selectRecurrence(value) {
    // console.log(value);
  }

  value = null;
  radiovalue = null;

  items = [
    { id: 1, init: 30, end: 50, unitValue: 0.98, surplusAmount: 0.5 },
    { id: 2, init: 30, end: 50, unitValue: 0.98, surplusAmount: 0.5 },
    { id: 3, init: 30, end: 50, unitValue: 0.98, surplusAmount: 0.5 },
    { id: 4, init: 30, end: 50, unitValue: 0.98, surplusAmount: 0.5 },
    { id: 5, init: 30, end: 50, unitValue: 0.98, surplusAmount: 0.5 },
  ];

  @action
  change(value) {
    // console.log(value);
  }
}
