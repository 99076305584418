define("ember-svg-jar/inlined/icon-pix", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path d=\"M26.935 13.42l-2.76-2.76a.522.522 0 01-.196.04h-1.257a2.483 2.483 0 00-1.743.727l-3.606 3.593a1.73 1.73 0 01-2.445 0l-3.615-3.616a2.484 2.484 0 00-1.743-.727H8.023a.521.521 0 01-.185-.037l-2.776 2.78a3.65 3.65 0 000 5.16l2.775 2.774a.539.539 0 01.186-.038h1.545a2.484 2.484 0 001.744-.726l3.616-3.61a1.77 1.77 0 012.445 0l3.601 3.6c.463.463 1.09.724 1.744.726h1.256c.068 0 .134.014.197.04l2.76-2.76a3.645 3.645 0 000-5.16\" fill=\"#B8C0C3\"/><path d=\"M9.568 9.624a3.504 3.504 0 012.495 1.033l3.614 3.614a.67.67 0 00.946 0l3.602-3.594a3.5 3.5 0 012.494-1.033h.436L18.58 5.062a3.65 3.65 0 00-5.16 0L8.86 9.624h.71zM22.72 22.362a3.507 3.507 0 01-2.495-1.033l-3.602-3.601a.684.684 0 00-.946 0l-3.614 3.614a3.51 3.51 0 01-2.495 1.033H8.86l4.56 4.56a3.645 3.645 0 005.16 0l4.573-4.573h-.433z\" fill=\"#B8C0C3\"/>",
    "attrs": {
      "width": "32",
      "height": "32",
      "viewBox": "0 0 32 32",
      "fill": "none",
      "xmlns": "http://www.w3.org/2000/svg"
    }
  };
});