import { Factory } from 'ember-cli-mirage';
// import faker from 'faker';

export default Factory.extend({
  id(i) {
    return `${i + 1}`;
  },

  number: 'PAY-2109-11793',
  effectiveOn: '2021-09-12T15:15:42Z',
  cancelledOn: null,
  createdOn: '2021-09-01T20:34:01Z',
  modifiedOn: '2021-09-26T22:58:35Z',
  status: 'Pending',
  paymentMethodTypes: ['CreditCard'],
  netAmount: 95,
  receivableAmount: 92.62,
  accountId: 'acc_5e5b1ba598ff49e8b817ee8d3f4fb051',
  refundAmount: 0,
  feeAmount: 2.38,
  taxWithheldAmount: 0,
  paidAmount: 95,
  discountAmount: 0,
  amount: 95,
  penaltyAmount: 0,
  dueOn: '2021-09-12T00:00:00Z',
  transactionCurrency: {
    name: 'Real',
    symbol: 'R$',
    isoCode: 'BRL',
  },
  invoices: [
    {
      id: '5eb82e42-600b-ec11-b6e5-00224835d788',
      invoiceNumber: 'INV-210909008-P9T7P7',
      status: 'Paid',
      discountAmount: 0,
      discountPercentage: 0,
      totalTax: 0,
      totalAmount: 95,
      totalItemsAmount: 95,
    },
  ],
  paymentMethod: {
    id: '003c6b0f-57f9-eb11-94ef-000d3ac19a4e',
    accountId: null,
    isGlobal: false,
    type: 'CreditCard',
    wireTransfer: null,
    bankSlip: null,
    instantPayment: null,
    details: {
      name: 'HUXLEY F DIAS',
      email: null,
      telephone: null,
      address: null,
    },
    card: {
      number: '************4327',
      securityCode: null,
      expirationMonth: 9,
      expirationYear: 2027,
      brand: 'Mastercard',
      funding: 'Credit',
      country: null,
      thumbprint: null,
      gatewayId: null,
      preference: 'None',
    },
  },
});
