import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class ExportController extends Controller {
  @service store;
  @service export;
  @service sessionData;

  @tracked
  refreshList = false;

  @task({ drop: true })
  *downloadExport(accountId, subscriptionId, exportId) {
    try {
      return yield this.store.queryRecord('export', {
        adapterOptions: {
          accountId: accountId,
          subscriptionId: subscriptionId,
          exportId,
        },
      });
    } catch (error) {
      return console.error('Não foi possível baixar o Relatório.');
    }
  }

  @action
  refresh() {
    this.send('updateList');
  }

  @action
  executeRefresh(data) {
    if (data && data.type === 'export-status') this.toggleProperty('refreshList');
  }

  @action
  refreshPage() {
    window.location.reload(true);
  }
}
