import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class AddressForm extends Component {
  @service address;

  constructor() {
    super(...arguments);
    this.postalCode = this.args.address.postalCode;

    if (this.args?.address?.stateOrProvince) {
      this.loadCities.perform(this.args?.address?.stateOrProvince);
    }
  }

  @tracked postalCode;
  @tracked countries;
  @tracked states;
  @tracked cities;

  @task
  *loadCountries() {
    if (isPresent(this.countries)) return this.countries;

    this.countries = yield this.address.getCountries();
  }

  @task
  *loadStates() {
    if (isPresent(this.states)) return this.states;

    this.states = yield this.address.getStates();
  }

  @task({ drop: true })
  *loadCities(state) {
    state = state ?? this.args.address.stateOrProvince;

    if (isPresent(state)) {
      this.cities = yield this.address.getCitiesByState(state);
    }
  }

  @task
  *autoFill(newPostalCode) {
    const { address, autoFillDisabled } = this.args;

    address.setProperties({ postalCode: newPostalCode });

    const postalCode = address.postalCode.replace(/\D/, '');

    if (autoFillDisabled || postalCode.length !== 8) return;

    try {
      const newAddress = yield this.address.getAddressByPostalCode(postalCode);

      if (!newAddress) return;

      address.setProperties({
        line1: newAddress.street,
        line2: newAddress.number || '',
        line3: newAddress.additionalInformation,
        districtOrCounty: newAddress.district,
        country: newAddress.country,
        cityCode: newAddress.city.code,
        city: newAddress.city.name,
        stateOrProvince: newAddress.state,
      });

      this.loadCities.perform(address.stateOrProvince);

    } catch (error) {
      return;
    }
  }

  @action
  setPostalCode({ detail }) {
    const model = this.args.address;
    this.postalCode = model.postalCode = detail?.value;

    this.autoFill.perform(detail?.value);
  }

  @action
  changeCountry({ detail }) {
    if (isPresent(detail.value)) {
      this.args.address.country = detail.value;
    }
  }

  @action
  changeState({ detail }) {
    if (isPresent(detail.value)) {
      const model = this.args.address;

      if (model.stateOrProvince !== detail.value) {
        model.city = null;
        model.stateOrProvince = detail.value;
      }

      this.loadCities.perform(detail.value);
    }
  }

  @action
  changeCity({ detail }) {
    if (isPresent(detail.value)) {
      const model = this.args.address;

      if (model.cityCode === detail.value) return;

      model.city = this.cities.find((city) => city.code == detail.value)?.name;
      model.cityCode = detail.value;
    }
  }

  get currentCity() {
    const model = this.args.address;

    return {
      name: model.city,
      code: model.cityCode,
    };
  }
}
