import Service, { service } from '@ember/service';

export default class AccessTokenService extends Service {
  @service session;
  @service store;
  @service router;

  async getByDescription(filter, subscriptionId) {
    try {
      const result = await this.store.query('apikey/grant', {
        adapterOptions: {
          subscriptionId,
        },
      });
      return result.filter((key) => key.description === filter);
    } catch (error) {
      console.error(error);
    }
  }
}
