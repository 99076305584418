define("ember-svg-jar/inlined/logo-bradesco-rectangle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    "content": "<path fill=\"#D20D2A\" d=\"M0 2a2 2 0 012-2h20a2 2 0 012 2v12a2 2 0 01-2 2H2a2 2 0 01-2-2V2z\"/><path fill=\"#fff\" fill-rule=\"evenodd\" d=\"M11.002 3.765c2.186-.812 4.358.693 4.358.693s-.715-1.093-2.077-1.533c-.544-.23-3.52-.596-4.721 1.783a4.17 4.17 0 00-.034.068c-1.053.351-1.622.78-1.622.78s.64-.173 1.438-.332c-.758 2.204.665 4.323 1.468 4.55-.22-.436-.593-1.458-.674-1.816-.223-.989-.15-2.073.34-2.923a6.58 6.58 0 011.144-.063c1.002.057 2.031.14 3.1.584 3.673 1.527.828 4.1.828 4.1s.361.127 1.6-.775c1.237-.902 1.152-3.65-2.866-4.378a10.07 10.07 0 00-3.35-.065 2.939 2.939 0 011.068-.673zm-.21 5.697h.755v3.782h-.756V9.462zm2.621-1.613h-1.21v5.395h1.21V7.849z\" clip-rule=\"evenodd\"/>",
    "attrs": {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "24",
      "height": "16",
      "fill": "none",
      "viewBox": "0 0 24 16"
    }
  };
});