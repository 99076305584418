define("ember-cli-mdc-layout/initializers/mdc-layout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'layout', 'service:layout');
    application.inject('controller', 'layout', 'service:layout');
  }
  var _default = _exports.default = {
    initialize
  };
});