import StorageObject from 'ember-local-storage/local/object';
import ENV from 'accounts-app/config/environment';

const Storage = StorageObject.extend();

Storage.reopenClass({
  initialState() {
    return {
      defaultLocale: ENV.intl.defaultLocale,
      intentUrl: null,
      drawer: {
        opened: true,
      },
    };
  },
});

export default Storage;
