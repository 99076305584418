import BaseOdataAdapter from './base-odata';
import ENV from 'accounts-app/config/environment';

export default class AccountAdapter extends BaseOdataAdapter {
  urlForCreateRecord(id, modelName, snapshot) {
    let url = `${this.host}/Accounts`;

    if (ENV.environment.includes('test')) {
      url = `${this.host}/accounts/${id}`;
    }
    return url;
  }

  urlForFindRecord(id, modelName, snapshot) {
    let url = `${this.host}/Accounts('${id}')`;

    if (ENV.environment.includes('test')) {
      url = `${this.host}/accounts/${id}`;
    }

    return url;
  }

  urlForUpdateRecord(id, modelName, snapshot) {
    let url = `${this.host}/Accounts('${id}')`;

    if (ENV.environment.includes('test')) {
      url = `${this.host}/accounts/${id}`;
    }

    return url;
  }

  downloadInvoicePdf(accountId, invoiceId) {
    const url = `${this.host}/Accounts('${accountId}')/invoices(${invoiceId})/api.exportpdf`;
    return window.open(url);
  }

  async downloadEInvoice(accountId, invoiceId, type) {
    const url = `${this.host}/Accounts('${accountId}')/EInvoices?$filter=invoiceid eq ${invoiceId}`;
    const eInvoices = await this.ajax(url);

    const eInvoiceId = eInvoices.value[0].id;

    let eInvoiceUrl = `${this.host}/Accounts('${accountId}')/EInvoices(${eInvoiceId})`;

    if (type === 'pdf') {
      eInvoiceUrl = `${eInvoiceUrl}/Api.ExportPdf`;
    }

    if (type === 'xml') {
      eInvoiceUrl = `${eInvoiceUrl}/Api.ExportXml`;
    }

    return window.open(eInvoiceUrl);
  }
}
