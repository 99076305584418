import Component from '@glimmer/component';
import arg from '../../../utils/decorators/arg';
import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';

export default class LoadersAgreementComponent extends Component {
  @service store;

  @arg agreementId;
  @arg accountId;

  @tracked agreement = [];
  @tracked error = false;

  @task({ drop: true })
  *getAgreement() {
    try {
      this.agreement = yield this.store.findRecord('agreement', this.agreementId, { adapterOptions: { accountId: this.accountId } });
      return;
    } catch (error) {
      this.error = true;
    }
  }
}
