import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class NfeCardGroupComponent extends Component {
  cardMinWidth = `${100 / this.args.column ?? 100}%`;

  @tracked
  selected = {};

  @action
  callback(prop) {
    this.args.action(prop);
    this.selected = prop;
  }
}
