import { attr } from '@ember-data/model';
import Fragment from 'ember-data-model-fragments/fragment';
import { t } from 'ember-intl';

import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import { validatePresence, validateFormat, validateLength } from 'ember-changeset-validations/validators';

const validations = {
  country: validatePresence({ presence: true, message: t('errorMessages.default.notEmpty') }),
  postalCode: [
    validatePresence({ presence: true, message: t('errorMessages.default.notEmpty') }),
    validateFormat({ regex: /^\d{5}\-?\d{3}$/, message: 'CEP inválido' }),
  ],
  line1: [
    validatePresence({ presence: true, message: t('errorMessages.default.notEmpty') }),
    validateLength({ min: 4, message: 'Minimo de 4 caracteres' }),
  ],
  line2: [
    validatePresence({ presence: true, message: t('errorMessages.default.notEmpty') }),
    validateLength({ max: 10, message: 'Máximo de 10 caracteres' }),
  ],
  districtOrCounty: [
    validatePresence({ presence: true, message: t('errorMessages.default.notEmpty') }),
    validateLength({ min: 3, message: 'Minimo de 3 caracteres' }),
  ],
  city: validatePresence({ presence: true, message: t('errorMessages.default.notEmpty') }),
  stateOrProvince: validatePresence({ presence: true, message: t('errorMessages.default.notEmpty') }),
};
export default class AddressModel extends Fragment {
  changeset = null;

  constructor() {
    super(...arguments);
    this.changeset = new Changeset(this, lookupValidator(validations), validations);
  }

  @attr('string') line1;
  @attr('string') line2;
  @attr('string') line3;
  @attr('string') districtOrCounty;
  @attr('string') city;
  @attr('string') stateOrProvince;
  @attr('string') postalCode;
  @attr('string') country;
  @attr('string') cityCode;
}
